import { Navigate, json } from "react-router-dom";
import {
  toastifyError,
  toastifySuccess,
} from "../../Component/Include/Common/AlertMsg";
import { changeArrayFormat, changeArrayFormatDrop, changeThreeArrayFormat } from "../../Component/Include/Common/ArrayFormat";
import {
  EncryptedList,
} from "../../Component/Include/Common/Utility";
import {
  Get_Master_Table_Data,
  Get_Master_Table_Filter_Data,
  Get_Menu,
  Get_Single_Master_Table_Data,
  MasterTable_Modal_Status,
  MasterTable_Page_Loader,
  Get_Error_Status,
  Get_Single_Company_Data,
  Search_challan_Data,
  Get_Single_Party_Data,
  Get_Party_List_Data,
  Party_State,
  Party_City,
  Contact_city,
  Get_Product_Dropdown,
  Get_Unit_DropDown,
  Get_Product_Data,
  Get_Single_Product_Data,
  Get_CostHead_Dropdown,
  Get_EquipmentVehicle_Data,
  Get_Single_Vechicle_Data,
  Get_EquipmentInsurance_Data,
  Get_Single_Insurance_Data,
  Get_EquipmentRto_Data,
  Get_Single_Rto_Data,
  Get_EquipmentFitness_Data,
  Get_Single_Fitness_Data,
  Get_EquipmentPuc_Data,
  Get_Single_Puc_Data,
  Get_Service_Dropdown,
  Get_PlantService_Data,
  Get_Single_PlantService_Data,
  Get_EquipmentVehicle_DropDown,
  Get_Nature_Dropdown,
  Get_Center_Dropdown,
  Get_CostSubHead_Dropdown,
  Get_CostHead_Data,
  Page_Status,
  Get_Single_CostHead_Data,
  Get_Department_Dropdown,
  Get_Group_Dropdown,
  Get_AssignGroup_Data,
  Get_Consumption_Dropdown,
  Get_ServiceConfiguration_Dropdown,

  // ------------------------------------> | AdminSetting -> Opertor/Employee | <-------------------------------------
  Update_Status,
  Unit_Dropdown,
  Get_Designation_Dropdown,
  Get_Employee_Data,
  Get_Single_Employee_Data,
  Get_BloodGroup_Dropdown,
  Get_Martial_Dropdown,
  Get_Religion_Dropdown,
  Get_Gender_Dropdown,
  Get_PermanentDistrict_Data,
  Get_State_Data,
  Get_LocalDistrict_Data,
  Get_Relation_Dropdown,
  Sidebar_Status,
  Status,
  Employee_ID,
  Edit_Update_Count,
  Get_Menu_Permission_Data,
  Get_Vechicle_ID,
  Get_Status_DropDown,
  Get_EmpStatus_Data,
  Get_Single_EmpStatus_Data,
  Get_Employee_Filter_Data,
  Get_EmpLeave_Data,
  Get_StatusLeave_Drop,
  Get_TypeLeave_Drop,
  Get_Single_EmpLeave_Data,
  Get_TaskType_Dropdown,
  Get_Emp_Task_Data,
  Get_Emp_Task_Detail_Data,
  Get_Single_Emp_Task_Data,
  Replay_Status,
  Get_Emp_Attendance,
  Get_Attendance,
  Get_PitBlock_Dropdown,
  Get_BenchNo_Dropdown,
  Get_PitBlock_Data,
  Get_Single_PitBlock_Data,
  Get_Site_Direction_Dropdown,
  Get_Blast_Entry_Data,
  Get_ExplosiveType_Dropdown,
  Get_Explosive_Data,
  Get_Single_Explosive_Data,
  Get_Machine_Name_Dropdown,
  Get_Drilling_Data,
  Get_Single_Drilling_Data,
  Get_Single_Blast__Data,
  Page_Loader,
  Get_Drilling_Filter_Data,
  Get_PitBlock_Filter_Data,
  Get_Explosive_Filter_Data,
  Get_Blast_Filter_Data,
  Modal_Status,
  Get_MeterReading_Data,
  Get_Single_MeterReading_Data,
  Get_Meter_Filter_Data,
  Get_Consumption_Power_Data,
  Get_Single_Consumption_Power_Data,
  Get_Consumption_Power_Filter_Data,
  Get_EmpLeave_Filter_Data,
  Get_EmpStatus_Filter_Data,
  Get_Chart_Data,
  Get_Task_Data,
  Get_RequestBy_DropDown,
  Get_AddRequest_Data,
  Get_Make_DropDown,
  Get_RequestList_Data,
  Get_PurchaseChart_Data,
  Get_Single_Request_Data,
  Get_Single_RequestList_Data,
  Get_Approve_Request,
  Get_Quotes_Data,
  Get_Single_Quotes_Data,
  Get_Receivedby_DropData,
  Get_Supplier_DropData,
  Get_MaterialGroup_DropData,
  Get_MaterialType_DropData,
  Get_Inventory_Material_Data,
  Get_Single_Inventory_Data,
  Get_Inventory_Material_Filter_Data,
  Get_Approve_Quotes,
  Get_DiscountType_DropData,
  Get_UnApprove_Request,
  Get_DeApprove_Request,
  Get_Gst_DropData,
  Get_SubMenu_Data,
  Get_Invoice_Type_DropData,
  Get_PurchaseInvoice_Data,
  Get_Single_PurchaseInvoice_Data,
  Get_AddItem_Data,
  Get_Single_AddItem_Data,
  Get_Invoice_ID,
  Get_Invoice_Filter_Data,
  Get_Req_Id,
  Check_Page,
  Get_PhoneSms_Data,
  Get_Single_PhoneSms_Data,
  Get_EmpCount_Task_Data,
  Get_SingleCount_Data,
  Get_Filter_PhoneSms_Data,
  Get_EmpStatus_TaskType_Dropdown,
  Get_ServiceConfiguration_Data,
  Get_Filter_ServiceConfiguration_Data,
  Get_Single_ServiceConfiguration_Data,
  Get_Single_Mantinance_Data,
  Get_Mantinance_Data,
  Get_Mantinance_Dropdown,
  Get_Filter_Mantinance_Data,
  Get_SalebyProduct_Chart_Data,
  Get_SalebybyCash_Chart_Data,
  Get_SalebyUdhar_Chart_Data,
} from "../actionTypes";
import * as api from "../api";

export const Decrypt_Fetch_Post_Data = (res) => {
  const decr = res?.data?.menuclass;
  //console.log(decr, "UYT")
  //console.log(res, 'ABC')
  return decr;
  // const decr = DecryptedList(res.data);
  // const TextData = JSON.parse(decr);
  // return TextData.menuclass;       
};

export const changeDropDownArray = (data, Id, Code, Name) => {
  const result = data?.map((sponsor) => ({
    value: sponsor[Id],
    label: sponsor[Code],
    name: Name,
  }));
  return result;
};

export const Decrypt_Fetch_Post_Data_Teble1 = (res) => {
  const decr = res?.data?.Table1;
  // console.log(decr, "WER")
  return decr;
  // const decr = DecryptedList(res.data);
  // const TextData = JSON.parse(decr);
  // return TextData.Table1;

};

export const Decrypt_Fetch_Post_Data_Teble = (res) => {
  const decr = res?.data?.Table;
  //console.log(decr, "DEC")
  // console.log(res, 'tyr')
  return decr;
  // const decr = DecryptedList(res.data);
  // console.log(decr,'rgrtg')
  // const TextData = JSON.parse(decr);
  // return TextData.Table;
};

export const Decrypt_Chart_Data_Teble = (res) => {
  const decr = JSON.parse(res?.data);
  return decr?.Table;
};

export const Decrypt_DropDown_Data = (res, id, label, name) => {
  const decr = res?.data?.Table;
  //console.log(decr, "DEC")
  const result = changeDropDownArray(id, label, name);
  //console.log(result, "fgc")
  return decr;
  // const decr = DecryptedList(res.data);
  // const TextData = JSON.parse(decr); 
  // return result;
};

export const get_Menu = (Userid) => async (dispatch) => {
  const values = { Userid: Userid };
  // const values = EncryptedList(JSON.stringify({ Userid: Userid }));
  // const val = { Data: values };
  try {
    const { data } = await api.get_Menu(values);
    // console.log("🚀 ~ constget_Menu= ~ data:", data);
    dispatch({
      type: Get_Menu,
      payload: Decrypt_Fetch_Post_Data(data),
    });
  } catch (error) {
    dispatch({ type: Get_Menu, payload: [] });
  }
};



export const get_SubMenu_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_SubMenu_Data(val);
    dispatch({
      type: Get_SubMenu_Data,
      payload: Decrypt_Fetch_Post_Data(data?.data),
    });
  } catch (error) {
    dispatch({ type: Get_SubMenu_Data, payload: [] });
  }
};

export const save_Menu_Data = (formData, userId, setValue) => async (dispatch) => {
  // const values = EncryptedList( JSON.stringify({ MenuName: formData?.MenuName, ID: formData?.ID }) );
  const val = { MenuName: formData?.MenuName, ID: formData?.ID };
  try {
    const { data } = await api.save_Menu_Data(val);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch(get_Menu(userId));
      setValue(pre => { return { ...pre, ['MenuName']: '', } });
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const update_Menu_Data =
  (formData, userId, setEditType) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify({ MenuName: formData?.MenuName, ID: formData?.ID }) );
    const val = { MenuName: formData?.MenuName, ID: formData?.ID };
    try {
      const { data } = await api.update_Menu_Data(val);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(get_Menu(userId));
        setEditType("");
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };

export const delete_Menu_Data = (Id, userId) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ Id: Id }));
  const val = { Id: Id };
  try {
    const { data } = await api.delete_Menu_Data(val);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch(get_Menu(userId));
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};


// ------------------------------ Master Table -----------------

export const get_Master_Table_Drop = (url, actionType, ID, Description) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Master_Table_Drop(val, url);
    dispatch({
      type: actionType,
      payload: Decrypt_DropDown_Data(data, ID, Description),
    });
  } catch (error) {
    dispatch({ type: actionType, payload: [] });
  }
};

export const save_Master_Table = (formData, Url, pageStatus, getUrl, showCol1, showCol2, idName, masterTableData, dropUrl, actionType) => async (dispatch) => {
  dispatch({ type: Get_Error_Status, payload: false });
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  if (showCol1) {
    var result = masterTableData?.find((item) => {
      if (item.Name) {
        if (item.Name.toString().toLowerCase() === formData[showCol1].toLowerCase()) {
          return true;
        } else return false;
      }
    });
  }
  if (showCol2) {
    var result1 = masterTableData?.find((item) => {
      if (item.Code) {
        if (
          item.Code.toString().toLowerCase() ===
          formData[showCol2].toLowerCase()
        ) {
          return true;
        } else return false;
      }
    });
  }
  if (result || result1) {
    if (result) {
      toastifyError(showCol1 + "Already Exists");
      dispatch({ type: Get_Error_Status, payload: false });
    }
    if (result1) {
      toastifyError(showCol2 + "Already Exists");
      dispatch({ type: Get_Error_Status, payload: false });
    }
  } else {
    try {
      const { data } = await api.save_Master_Table(formData, Url);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(
          get_Master_Table(pageStatus, getUrl, showCol1, showCol2, idName)
        );
        actionType &&
          dispatch(
            get_Master_Table_Drop(dropUrl, actionType, idName, showCol1)
          );
        dispatch({ type: MasterTable_Modal_Status, payload: false });
        dispatch({ type: Get_Error_Status, payload: false });
        dispatch({ type: Sidebar_Status, payload: false });
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  }
};

export const update_Master_Table = (formData, Url, pageStatus, getUrl, showCol1, showCol2, idName, masterTableData, dropUrl, actionType) => async (dispatch) => {
  dispatch({ type: Get_Error_Status, payload: false });
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  var result = masterTableData?.find((item) => {
    if (item.ID != formData[idName]) {
      if (item.Name) {
        if (
          item.Name.toString().toLowerCase() ===
          formData[showCol1].toLowerCase()
        ) {
          return true;
        } else return false;
      }
    }
  });
  var result1 = masterTableData?.find((item) => {
    if (item.ID != formData[idName]) {
      if (item.Code) {
        if (
          item.Code.toString().toLowerCase() ===
          formData[showCol2].toLowerCase()
        ) {
          return true;
        } else return false;
      }
    }
  });
  if (result || result1) {
    if (result) {
      toastifyError(showCol1 + "Already Exists");
      dispatch({ type: Get_Error_Status, payload: false });
    }
    if (result1) {
      toastifyError(showCol1 + "Already Exists");
      dispatch({ type: Get_Error_Status, payload: false });
    }
  } else {
    try {
      const { data } = await api.update_Master_Table(formData, Url);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(
          get_Master_Table(pageStatus, getUrl, showCol1, showCol2, idName)
        );
        actionType &&
          dispatch(
            get_Master_Table_Drop(dropUrl, actionType, idName, showCol1)
          );
        dispatch({ type: MasterTable_Modal_Status, payload: false });
        dispatch({ type: Get_Error_Status, payload: false });
        dispatch({ type: Sidebar_Status, payload: false });
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  }
};

export const delete_Master_Table = (ID, IsActive, url, pageStatus, getUrl, showCol1, showCol2, idName, userid) => async (dispatch) => {
  // const values = EncryptedList( JSON.stringify({ IsActive: IsActive, ID: ID, DeletedByid: userid }) );
  const val = { IsActive: IsActive, ID: ID, DeletedByid: userid };
  try {
    const { data } = await api.delete_Master_Table(val, url);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch(
        get_Master_Table(pageStatus, getUrl, showCol1, showCol2, idName)
      );
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};


export const get_Single_Master_Table = (ID, Url) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Master_Table(val, Url);
    dispatch({
      type: Get_Single_Master_Table_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_Master_Table_Data, payload: [] });
  }
};

export const get_Master_Table = (IsActive, url, showCol1, showCol2, idName) => async (dispatch) => {
  dispatch({ type: MasterTable_Page_Loader, payload: false });
  // const values = EncryptedList(JSON.stringify({ IsActive: IsActive }));
  const val = { IsActive: IsActive };
  try {
    const { data } = await api.get_Master_Table(url, val);
    dispatch({
      type: Get_Master_Table_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({
      type: Get_Master_Table_Filter_Data,
      payload: changeArrayFormat(
        Decrypt_Fetch_Post_Data_Teble(data),
        showCol1,
        showCol2,
        idName
      ),
    });
    dispatch({ type: MasterTable_Page_Loader, payload: true });
  } catch (error) {
    dispatch({ type: Get_Master_Table_Data, payload: [] });
    dispatch({ type: Get_Master_Table_Filter_Data, payload: [] });
    dispatch({ type: MasterTable_Page_Loader, payload: true });
  }
};

// -------------------------------- Admin Setting ---------------------------
export const get_Single_Company_Data = (CompanyID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ CompanyID: CompanyID }));
  const val = { CompanyID: CompanyID };
  try {
    const { data } = await api.get_Single_Company_Data(val);
    dispatch({
      type: Get_Single_Company_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_Company_Data, payload: [] });
  }
};

// ------------------------------ Company Profile -------------------------------
export const update_Company_Profile_Data = (formData, ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_Company_Profile_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      // dispatch({ type: Get_Error_Status, payload: false });

      dispatch(get_Single_Company_Data(ID));
    } else {
      toastifyError(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: Get_Error_Status, payload: false });
  }
};

export const update_Company_Invoice_Data = (formData, ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_Company_Invoice_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      // dispatch({ type: Get_Error_Status, payload: false });
      dispatch(get_Single_Company_Data(ID));
    } else {
      toastifyError(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: Get_Error_Status, payload: false });
  }
};

export const update_Company_Gst_Data = (formData, ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_Company_Gst_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      // dispatch({ type: Get_Error_Status, payload: false });
      // dispatch(get_Employee_Data());
      dispatch(get_Single_Company_Data(ID));
    } else {
      toastifyError(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: Get_Error_Status, payload: false });
  }
};

// ----------------- Search Challan --------------------
export const Get_challan_Data = (formData) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.Get_challan_Data(formData);
    dispatch({
      type: Search_challan_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Search_challan_Data, payload: false });
  }
};

// -------------------------Party Sale -------------------
export const save_Party_Sale = ({ ...formData }) => async (dispatch) => {
  const { value, NavigateUrl } = formData;
  const values = EncryptedList(JSON.stringify(value));
  const val = { Data: values };
  try {
    const { data } = await api.save_Party_Sale(val);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
      dispatch(get_Party_Sale_Data());
      dispatch(get_Single_Party_Data(data?.Id));
      dispatch({ type: Get_Single_Party_Data, payload: data?.Id });
      dispatch({ type: Page_Status, payload: true });
      NavigateUrl();
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const upadate_Party_Sale = ({ ...formData }) => async (dispatch) => {
  const { value, NavigateUrl } = formData;
  const values = EncryptedList(JSON.stringify(value));
  const val = { Data: values };
  try {
    const { data } = await api.upadate_Party_Sale(val);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
      dispatch(get_Party_Sale_Data());
      NavigateUrl();
    }
  } catch (error) {
    dispatch({ type: Get_Error_Status, payload: false });
  }
};

export const get_Single_Party_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Party_Data(val);
    dispatch({
      type: Get_Single_Party_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_Party_Data, payload: [] });
  }
};

export const get_Party_Sale_Data = (formData) => async (dispatch) => {
  dispatch({ type: MasterTable_Page_Loader, payload: false });
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.get_Party_Sale_Data(formData);
    dispatch({
      type: Get_Party_List_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({
      type: Get_Master_Table_Filter_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({ type: MasterTable_Page_Loader, payload: true });
  } catch (error) {
    dispatch({ type: Get_Party_List_Data, payload: [] });
    dispatch({ type: MasterTable_Page_Loader, payload: [] });
    dispatch({ type: Get_Master_Table_Filter_Data, payload: [] });
  }
};

// export const get_State = (IsActive) => async (dispatch) => {
//   // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
//   const val = { IsActive: "1" };
//   try {
//     const { data } = await api.get_State(val);
//     console.log(data,'data')
//     dispatch({
//       type: Party_State,
//       payload: Decrypt_DropDown_Data(data, "ID", "StateName", "State"),
//     });
//   } catch (error) {
//     dispatch({ type: Party_State, payload: [] });
//   }
// };

export const get_State = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_State(val);
    console.log(data, 'data')
    dispatch({
      type: Get_State_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "id", "StateName", "State"),
    });
  } catch (error) {
    dispatch({ type: Get_BloodGroup_Dropdown, payload: [] });
  }
};

export const get_City = (formData, type) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ StateID: formData }));
  const val = { StateID: formData };
  try {
    const { data } = await api.get_City(val);
    if (type === "Firm") {
      dispatch({
        type: Party_City,
        payload: Decrypt_DropDown_Data(data, "ID", "DistrictName", "City"),
      });
    }
    if (type === "Contact") {
      dispatch({
        type: Contact_city,
        payload: Decrypt_DropDown_Data(data, "ID", "DistrictName", "City"),
      });
    }
    if (type === "Local") {
      dispatch({
        type: Get_LocalDistrict_Data,
        payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "DistrictName", "City"),
      });
    }
    if (type === "Permanent") {
      dispatch({
        type: Get_PermanentDistrict_Data,
        payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "DistrictName", "City"),
      });
    }
  } catch (error) {
    dispatch({ type: Party_City, payload: [] });
    dispatch({ type: Get_PermanentDistrict_Data, payload: [] });
    dispatch({ type: Contact_city, payload: [] });
    dispatch({ type: Get_LocalDistrict_Data, payload: [] });
  }
};

export const delete_Sale_Data = (ID, IsActive, userId, pageStatus) => async (dispatch) => {
  // const values = EncryptedList( JSON.stringify({ IsActive: IsActive, ID: ID,DeletedByid: userId,}));
  const val = { IsActive: IsActive, ID: ID, DeletedByid: userId, };
  try {
    const { data } = await api.delete_Sale_Data(val);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch(get_Party_Sale_Data(pageStatus));
    } else {
      toastifyError("delete Successfully");
    }
  } catch (error) {
    console.log(error);
  }
};

//  -------------------Product data -----------------------
export const get_Product_Dropdown = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Product_Dropdown(val);
    dispatch({
      type: Get_Product_Dropdown,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "Name"),
    });
  } catch (error) {
    dispatch({ type: Get_Product_Dropdown, payload: [] });
  }
};

export const get_Unit_Dropdown = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Unit_Dropdown(val);
    dispatch({
      type: Get_Unit_DropDown,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "UnitType"),
    });
  } catch (error) {
    dispatch({ type: Get_Unit_DropDown, payload: [] });
  }
};

export const get_Product_Data = (IsActive, showCol2, showCol1) => async (dispatch) => {
  dispatch({ type: MasterTable_Page_Loader, payload: false });
  // const values = EncryptedList(JSON.stringify({ IsActive: IsActive }));
  const val = { IsActive: IsActive };
  try {
    const { data } = await api.get_Product_Data(val);
    dispatch({
      type: Get_Product_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({
      type: Get_Master_Table_Filter_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({ type: MasterTable_Page_Loader, payload: true });
  } catch (error) {
    dispatch({ type: Get_Product_Data, payload: [] });
    dispatch({ type: MasterTable_Page_Loader, payload: true });
    dispatch({ type: Get_Master_Table_Filter_Data, payload: [] });
  }
};

export const save_Product_Data = (formData, pageStatus) => async (dispatch) => {
  const values = EncryptedList(JSON.stringify(formData));
  const val = { Data: values };
  try {
    const { data } = await api.save_Product_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Get_Error_Status, payload: true });
      dispatch({ type: Get_Error_Status, payload: false });
      dispatch(get_Product_Data(pageStatus));
      dispatch({ type: MasterTable_Modal_Status, payload: false });
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const update_Product_Data = (formData, pageStatus) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_Product_Data(formData);
    dispatch({ type: Get_Error_Status, payload: true });
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch(get_Product_Data(pageStatus));
      dispatch({ type: MasterTable_Modal_Status, payload: false });
    }
  } catch (error) {
    dispatch({ type: Get_Error_Status, payload: false });
  }
};

export const get_Single_Product_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Product_Data(val);
    dispatch({
      type: Get_Single_Product_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_Product_Data, payload: [] });
  }
};

export const delete_Product_Data = (ID, IsActive, userid, pageStatus) => async (dispatch) => {
  // const values = EncryptedList( JSON.stringify({ IsActive: IsActive,ID: ID, DeletedByid: userid,  }) );
  const val = { IsActive: IsActive, ID: ID, DeletedByid: userid, };
  try {
    const { data } = await api.delete_Product_Data(val);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch(get_Product_Data(pageStatus));
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};

//  -------------------Vechile data -----------------------

export const get_EquipmentVehicle_Data = (IsActive) => async (dispatch) => {
  dispatch({ type: MasterTable_Page_Loader, payload: false });
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_EquipmentVehicle_Data(val);
    dispatch({
      type: Get_EquipmentVehicle_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({
      type: Get_Master_Table_Filter_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({ type: MasterTable_Page_Loader, payload: true });
  } catch (error) {
    dispatch({ type: Get_EquipmentVehicle_Data, payload: [] });
    dispatch({ type: MasterTable_Page_Loader, payload: true });
    dispatch({ type: Get_Master_Table_Filter_Data, payload: [] });
  }
};

export const save_Equipment_Vechicle_Data = (formData) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.save_Equipment_Vechicle_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Update_Status, payload: true });
      dispatch({ type: Get_Error_Status, payload: false });
      dispatch(get_EquipmentVehicle_Data());
      dispatch(get_Single_EquipmentVehicle_Data(data?.ID));
      dispatch({ type: Get_Vechicle_ID, payload: data?.ID });
      dispatch({ type: Page_Status, payload: true });
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const get_Single_EquipmentVehicle_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_EquipmentVehicle_Data(val);
    dispatch({
      type: Get_Single_Vechicle_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_Vechicle_Data, payload: [] });
  }
};

export const update_Equipment_Vechicle_Data = (formData) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_Equipment_Vechicle_Data(formData);
    dispatch({ type: Get_Error_Status, payload: false });
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch(get_EquipmentVehicle_Data());
    }
  } catch (error) {
    dispatch({ type: Get_Error_Status, payload: false });
  }
};

export const get_EquipmentVehicle_Dropdown = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_EquipmentVehicle_Dropdown(val);
    dispatch({
      type: Get_EquipmentVehicle_DropDown,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "VehicleType"),
    });
  } catch (error) {
    dispatch({ type: Get_EquipmentVehicle_DropDown, payload: [] });
  }
};

export const delete_Equipment_Vechicle_Data = (ID, IsActive, userId, pageStatus) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID, IsActive: IsActive, DeletedByid: userId }));
  const val = { IsActive: IsActive, ID: ID, DeletedByid: userId, }
  try {
    const { data } = await api.delete_Equipment_Vechicle_Data(val);
    if (data?.success) {
      // return data?.success
      toastifySuccess(data?.Message);
      dispatch(get_EquipmentVehicle_Data());
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log("error", error);
  }
};

// -----------------------Insurance Details-------------
export const get_EquipmentInsurance_Data = (EquipmentVehID) => async (dispatch) => {
  dispatch({ type: MasterTable_Page_Loader, payload: false });
  // const values = EncryptedList(JSON.stringify({IsActive: "1",EquipmentVehID: EquipmentVehID,}));
  const val = { IsActive: "1", EquipmentVehID: EquipmentVehID, };
  try {
    const { data } = await api.get_EquipmentInsurance_Data(val);
    dispatch({
      type: Get_EquipmentInsurance_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    // dispatch({
    //   type: Get_Master_Table_Filter_Data,
    //   payload:
    //     Decrypt_Fetch_Post_Data_Teble(data),
    // });
    dispatch({ type: MasterTable_Page_Loader, payload: true });
  } catch (error) {
    dispatch({ type: Get_EquipmentInsurance_Data, payload: [] });
    dispatch({ type: MasterTable_Page_Loader, payload: true });
    // dispatch({ type: Get_Master_Table_Filter_Data, payload: [] });
  }
};

export const save_Equipment_Insurance_Data = (formData, Id) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.save_Equipment_Insurance_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Get_Error_Status, payload: true });
      dispatch({ type: Get_Error_Status, payload: false });
      dispatch(get_EquipmentInsurance_Data(Id));
      dispatch({ type: MasterTable_Modal_Status, payload: false });
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const get_Single_Insurance_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Insurance_Data(val);
    dispatch({
      type: Get_Single_Insurance_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_Insurance_Data, payload: [] });
  }
};

export const update_Equipment_Insurance_Data = (formData, Id) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_Equipment_Insurance_Data(formData);
    dispatch({ type: Get_Error_Status, payload: true });
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch(get_EquipmentInsurance_Data(Id));
      dispatch({ type: MasterTable_Modal_Status, payload: false });
    }
  } catch (error) {
    dispatch({ type: Get_Error_Status, payload: false });
  }
};

export const delete_Equipment_Insurance_Data = (ID, IsActive, userid, pageStatus, vechicleID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: IsActive, ID: ID, DeletedByid: userid }));
  const val = { IsActive: IsActive, ID: ID, DeletedByid: userid };
  try {
    const { data } = await api.delete_Equipment_Insurance_Data(val);
    if (data?.success) {
      toastifySuccess("delete successfully");
      dispatch(get_EquipmentInsurance_Data(vechicleID, pageStatus));
    } else {
      toastifyError("delete successfully");
    }
  } catch (error) {
    console.log(error);
  }
};

// -----------------------Rto Details-------------
export const get_EquipmentRto_Data = (EquipmentVehID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({IsActive: "1",EquipmentVehID: EquipmentVehID,}) );
  const val = { IsActive: "1", EquipmentVehID: EquipmentVehID, };
  try {
    const { data } = await api.get_EquipmentRto_Data(val);
    dispatch({
      type: Get_EquipmentRto_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    // dispatch({
    //   type: Get_Master_Table_Filter_Data,
    //   payload:
    //     Decrypt_Fetch_Post_Data_Teble(data),
    // });
    dispatch({ type: MasterTable_Page_Loader, payload: true });
  } catch (error) {
    dispatch({ type: Get_EquipmentRto_Data, payload: [] });
    dispatch({ type: MasterTable_Page_Loader, payload: true });
    // dispatch({ type: Get_Master_Table_Filter_Data, payload: [] });
  }
};
export const save_Equipment_Rto_Data = (formData, Id) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.save_Equipment_Rto_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Get_Error_Status, payload: true });
      dispatch({ type: Get_Error_Status, payload: false });
      dispatch(get_EquipmentRto_Data(Id));
      dispatch({ type: MasterTable_Modal_Status, payload: false });
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};
export const get_Single_Rto_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Rto_Data(val);
    dispatch({
      type: Get_Single_Rto_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_Rto_Data, payload: [] });
  }
};
export const update_Equipment_Rto_Data = (formData, Id) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_Equipment_Rto_Data(formData);
    dispatch({ type: Get_Error_Status, payload: true });
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch(get_EquipmentRto_Data(Id));
      dispatch({ type: MasterTable_Modal_Status, payload: false });
    }
  } catch (error) {
    dispatch({ type: Get_Error_Status, payload: false });
  }
};
export const delete_Equipment_Rto_Data =
  (ID, IsActive, userid, pageStatus, vechicleID) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify({ IsActive: IsActive, ID: ID, DeletedByid: userid }));
    const val = { IsActive: IsActive, ID: ID, DeletedByid: userid };
    try {
      const { data } = await api.delete_Equipment_Rto_Data(val);
      if (data?.success) {
        toastifySuccess("delete successfully");
        dispatch(get_EquipmentRto_Data(vechicleID, pageStatus));
      } else {
        toastifyError("delete successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };
// ------------------------Fitness Details-----------------
export const get_EquipmentFitness_Data =
  (EquipmentVehID) => async (dispatch) => {
    dispatch({ type: MasterTable_Page_Loader, payload: false });
    // const values = EncryptedList(JSON.stringify({IsActive: "1",EquipmentVehID: EquipmentVehID,}));
    const val = { IsActive: "1", EquipmentVehID: EquipmentVehID, };
    try {
      const { data } = await api.get_EquipmentFitness_Data(val);
      dispatch({
        type: Get_EquipmentFitness_Data,
        payload: Decrypt_Fetch_Post_Data_Teble(data),
      });
      // dispatch({
      //   type: Get_Master_Table_Filter_Data,
      //   payload:
      //     Decrypt_Fetch_Post_Data_Teble(data),
      // });
      dispatch({ type: MasterTable_Page_Loader, payload: true });
    } catch (error) {
      dispatch({ type: Get_EquipmentFitness_Data, payload: [] });
      dispatch({ type: MasterTable_Page_Loader, payload: true });
      // dispatch({ type: Get_Master_Table_Filter_Data, payload: [] });
    }
  };
export const save_Equipment_Fitness_Data =
  (formData, Id) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.save_Equipment_Fitness_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Get_Error_Status, payload: true });
        dispatch({ type: Get_Error_Status, payload: false });
        dispatch(get_EquipmentFitness_Data(Id));
        dispatch({ type: MasterTable_Modal_Status, payload: false });
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };
export const get_Single_Fitness_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Fitness_Data(val);
    dispatch({
      type: Get_Single_Fitness_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_Fitness_Data, payload: [] });
  }
};
export const update_Equipment_Fitness_Data =
  (formData, Id) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.update_Equipment_Fitness_Data(formData);
      dispatch({ type: Get_Error_Status, payload: true });
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(get_EquipmentFitness_Data(Id));
        dispatch({ type: MasterTable_Modal_Status, payload: false });
      }
    } catch (error) {
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };
export const delete_Equipment_Fitness_Data =
  (ID, IsActive, userid, pageStatus, vechicleID) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify({ IsActive: IsActive, ID: ID, DeletedByid: userid }) );
    const val = { IsActive: IsActive, ID: ID, DeletedByid: userid };
    try {
      const { data } = await api.delete_Equipment_Fitness_Data(val);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(get_EquipmentFitness_Data(vechicleID, pageStatus));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };
// ----------------------------Puc Details-------------------------
export const get_EquipmentPuc_Data = (EquipmentVehID) => async (dispatch) => {
  dispatch({ type: MasterTable_Page_Loader, payload: false });
  // const values = EncryptedList(JSON.stringify({ IsActive: "1", EquipmentVehID: EquipmentVehID }));
  const val = { IsActive: "1", EquipmentVehID: EquipmentVehID };
  try {
    const { data } = await api.get_EquipmentPuc_Data(val);
    dispatch({
      type: Get_EquipmentPuc_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    // dispatch({
    //   type: Get_Master_Table_Filter_Data,
    //   payload:
    //     Decrypt_Fetch_Post_Data_Teble(data),
    // });
    dispatch({ type: MasterTable_Page_Loader, payload: true });
  } catch (error) {
    dispatch({ type: Get_EquipmentPuc_Data, payload: [] });
    dispatch({ type: MasterTable_Page_Loader, payload: true });
    // dispatch({ type: Get_Master_Table_Filter_Data, payload: [] });
  }
};
export const save_Equipment_Puc_Data = (formData, Id) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.save_Equipment_Puc_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Get_Error_Status, payload: true });
      dispatch({ type: Get_Error_Status, payload: false });
      dispatch(get_EquipmentPuc_Data(Id));
      dispatch({ type: MasterTable_Modal_Status, payload: false });
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};
export const get_Single_Puc_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Puc_Data(val);
    dispatch({
      type: Get_Single_Puc_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_Puc_Data, payload: [] });
  }
};
export const update_Equipment_Puc_Data = (formData, Id) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_Equipment_Puc_Data(formData);
    dispatch({ type: Get_Error_Status, payload: true });
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch(get_EquipmentPuc_Data(Id));
      dispatch({ type: MasterTable_Modal_Status, payload: false });
    }
  } catch (error) {
    dispatch({ type: Get_Error_Status, payload: false });
  }
};

export const delete_Equipment_Puc_Data =
  (ID, IsActive, userid, pageStatus, vechicleID) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify({ IsActive: IsActive, ID: ID, DeletedByid: userid }) );
    const val = { IsActive: IsActive, ID: ID, DeletedByid: userid };
    try {
      const { data } = await api.delete_Equipment_Puc_Data(val);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(get_EquipmentPuc_Data(vechicleID, pageStatus));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };

// ----------------------------Plant Service Data---------------------
export const get_Service_Dropdown = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Service_Dropdown(val);
    dispatch({
      type: Get_Service_Dropdown,
      payload: Decrypt_DropDown_Data(data, "ID", "ServiceType"),
    });
  } catch (error) {
    dispatch({ type: Get_Service_Dropdown, payload: [] });
  }
};
export const get_PlantService_Data =
  (IsActive, showCol2, showCol1) => async (dispatch) => {
    dispatch({ type: MasterTable_Page_Loader, payload: false });
    // const values = EncryptedList(JSON.stringify({ IsActive: IsActive }));
    const val = { IsActive: "IsActive" };
    try {
      const { data } = await api.get_PlantService_Data(val);
      dispatch({
        type: Get_PlantService_Data,
        payload: Decrypt_Fetch_Post_Data_Teble(data),
      });
      dispatch({
        type: Get_Master_Table_Filter_Data,
        payload: Decrypt_Fetch_Post_Data_Teble(data),
      });
      dispatch({ type: MasterTable_Page_Loader, payload: true });
    } catch (error) {
      dispatch({ type: Get_PlantService_Data, payload: [] });
      dispatch({ type: MasterTable_Page_Loader, payload: true });
      dispatch({ type: Get_Master_Table_Filter_Data, payload: [] });
    }
  };

export const save_PlantService_Data =
  (formData, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.save_PlantService_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Get_Error_Status, payload: true });
        dispatch({ type: Get_Error_Status, payload: false });
        dispatch(get_PlantService_Data(pageStatus));
        dispatch({ type: MasterTable_Modal_Status, payload: false });
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };

export const update_PlantService_Data =
  (formData, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.update_PlantService_Data(formData);
      dispatch({ type: Get_Error_Status, payload: true });
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(get_PlantService_Data(pageStatus));
        dispatch({ type: MasterTable_Modal_Status, payload: false });
      }
    } catch (error) {
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };
export const get_Single_PlantService_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_PlantService_Data(val);
    dispatch({
      type: Get_Single_PlantService_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_PlantService_Data, payload: [] });
  }
};
export const delete_PlantService_Data =
  (ID, IsActive, userid, pageStatus) => async (dispatch) => {
    // const values = EncryptedList( JSON.stringify({IsActive: IsActive,  ID: ID,DeletedByid: userid }) );
    const val = { IsActive: IsActive, ID: ID, DeletedByid: userid };
    try {
      const { data } = await api.delete_PlantService_Data(val);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(get_PlantService_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };

// ----------------Cost Head Data----------------
export const get_Nature_Dropdown = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Nature_Dropdown(val);
    dispatch({
      type: Get_Nature_Dropdown,
      payload: Decrypt_DropDown_Data(data, "ID", "Nature"),
    });
  } catch (error) {
    dispatch({ type: Get_Nature_Dropdown, payload: [] });
  }
};

export const get_CostHead_Dropdown = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_CostHead_Dropdown(val);
    dispatch({
      type: Get_CostHead_Dropdown,
      payload: Decrypt_DropDown_Data(data, "ID", "Head"),
    });
  } catch (error) {
    dispatch({ type: Get_CostHead_Dropdown, payload: [] });
  }
};
export const get_CostSubhead_Dropdown = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_CostSubhead_Dropdown(val);
    dispatch({
      type: Get_CostSubHead_Dropdown,
      payload: Decrypt_DropDown_Data(data, "ID", "SubHead"),
    });
  } catch (error) {
    dispatch({ type: Get_CostSubHead_Dropdown, payload: [] });
  }
};
export const get_Center_Dropdown = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Center_Dropdown(val);
    dispatch({
      type: Get_Center_Dropdown,
      payload: Decrypt_DropDown_Data(data, "ID", "CostCenter"),
    });
  } catch (error) {
    dispatch({ type: Get_Center_Dropdown, payload: [] });
  }
};
export const save_MaterialCost_Data =
  (formData, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.save_MaterialCost_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Get_Error_Status, payload: true });
        dispatch({ type: Get_Error_Status, payload: false });
        dispatch(get_CostHead_Data(pageStatus));
        dispatch({ type: MasterTable_Modal_Status, payload: false });
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };
export const update_MaterialCost_Data =
  (formData, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.update_MaterialCost_Data(formData);
      dispatch({ type: Get_Error_Status, payload: true });
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(get_CostHead_Data(pageStatus));
        dispatch({ type: MasterTable_Modal_Status, payload: false });
      }
    } catch (error) {
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };
export const get_CostHead_Data =
  (IsActive, showCol2, showCol1) => async (dispatch) => {
    dispatch({ type: MasterTable_Page_Loader, payload: false });
    // const values = EncryptedList(JSON.stringify({ IsActive: IsActive }));
    const val = { IsActive: IsActive };
    try {
      const { data } = await api.get_CostHead_Data(val);
      dispatch({
        type: Get_CostHead_Data,
        payload: Decrypt_Fetch_Post_Data_Teble(data),
      });
      dispatch({
        type: Get_Master_Table_Filter_Data,
        payload: Decrypt_Fetch_Post_Data_Teble(data),
      });
      dispatch({ type: MasterTable_Page_Loader, payload: true });
    } catch (error) {
      dispatch({ type: Get_CostHead_Data, payload: [] });
      dispatch({ type: MasterTable_Page_Loader, payload: true });
      dispatch({ type: Get_Master_Table_Filter_Data, payload: [] });
    }
  };
export const get_Single_CostHead_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Costhead_Data(val);
    dispatch({
      type: Get_Single_CostHead_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_CostHead_Data, payload: [] });
  }
};

export const get_Consumption_Drop_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Consumption_Drop_Data(val);
    dispatch({
      type: Get_Consumption_Dropdown,
      payload: Decrypt_DropDown_Data(data, "ID", "Type"),
    });
  } catch (error) {
    dispatch({ type: Get_Consumption_Dropdown, payload: [] });
  }
};
export const get_Group_Drop_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Group_Drop_Data(val);
    dispatch({
      type: Get_Group_Dropdown,
      payload: Decrypt_DropDown_Data(data, "ID", "GroupName"),
    });
  } catch (error) {
    dispatch({ type: Get_Group_Dropdown, payload: [] });
  }
};
export const get_Material_Group_Data = (formData) => async (dispatch) => {
  // const values = EncryptedList( JSON.stringify({ IsActive: "1", MaterialConsumptionTypeID: formData?.MaterialConsumptionTypeID,  MaterialConsumptionGroupID: formData?.MaterialConsumptionGroupID, }));
  const val = { IsActive: "1", MaterialConsumptionTypeID: formData?.MaterialConsumptionTypeID, MaterialConsumptionGroupID: formData?.MaterialConsumptionGroupID, };
  try {
    const { data } = await api.get_Material_Group_Data(val);
    dispatch({
      type: Get_AssignGroup_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_AssignGroup_Data, payload: [] });
  }
};
export const save_Assign_Group_Data = (formData, value) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.save_Assign_Group_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Get_Error_Status, payload: true });
      dispatch({ type: Get_Error_Status, payload: false });
      dispatch(get_Material_Group_Data(value));
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};
export const delete_Assign_Group_Data =
  (ID, IsActive, userId, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(  JSON.stringify({ ID: ID, IsActive: IsActive, DeletedByid: userId }) );
    const val = { ID: ID, IsActive: IsActive, DeletedByid: userId };
    try {
      const { data } = await api.delete_Consumption_Power_Data(val);
      if (data?.success) {
        // return data?.success
        toastifySuccess("delete Succesfully");
        // dispatch(get_Material_Group_Data(value));
      } else {
        toastifyError("delete Succesfully");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

// --------------------------------Hr---------------------------
export const get_Status_Drop_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Status_Drop_Data(val);
    // console.log(Decrypt_DropDown_Data(data, "ID", "EmpStatus"));
    //console.log(data,'asdasdasd')
    dispatch({
      type: Get_Status_DropDown,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "EmpStatus"),
    });
  } catch (error) {
    dispatch({ type: Get_Status_DropDown, payload: [] });
  }
};

export const save_EmpStatus =
  (formData, EmpID, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.save_EmpStatus(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Get_Error_Status, payload: true });
        dispatch({ type: Get_Error_Status, payload: false });
        dispatch(get_Emp_Status_Data(EmpID, pageStatus));
        dispatch({ type: MasterTable_Modal_Status, payload: false });
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };
export const update_EmpStatus =
  (formData, EmpID, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.update_EmpStatus(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
        dispatch(get_Emp_Status_Data(EmpID, pageStatus));
        dispatch({ type: MasterTable_Modal_Status, payload: false });
      } else {
        toastifyError(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };
export const get_Emp_Status_Data = (EmpID, IsActive) => async (dispatch) => {
  dispatch({ type: Page_Loader, payload: false });
  // const values = EncryptedList(JSON.stringify({ IsActive: IsActive, EmpID: EmpID }));
  const val = { IsActive: IsActive, EmpID: EmpID };
  try {
    const { data } = await api.get_Emp_Status_Data(val);
    dispatch({
      type: Get_EmpStatus_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({ type: Page_Loader, payload: true });
    dispatch({
      type: Get_EmpStatus_Filter_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_EmpStatus_Data, payload: [] });
    dispatch({ type: Page_Loader, payload: true });
    dispatch({ type: Get_EmpStatus_Filter_Data, payload: [] });
  }
};
export const get_Single_EmpStatus_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_EmpStatus_Data(val);
    dispatch({
      type: Get_Single_EmpStatus_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_EmpStatus_Data, payload: [] });
  }
};
export const delete_EmpStatus =
  (ID, IsActive, userid, pageStatus, EmpID) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify({ IsActive: IsActive, ID: ID, DeletedByid: userid }) );
    const val = { IsActive: IsActive, ID: ID, DeletedByid: userid };
    try {
      const { data } = await api.delete_EmpStatus(val);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(get_Emp_Status_Data(EmpID, pageStatus));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };

// Emp leave
export const get_Emp_Leave_Data = (EmpID, IsActive) => async (dispatch) => {
  dispatch({ type: Page_Loader, payload: false });
  // const values = EncryptedList( JSON.stringify({ IsActive: IsActive, EmpID: EmpID }));
  const val = { IsActive: IsActive, EmpID: EmpID };
  try {
    const { data } = await api.get_Emp_Leave_Data(val);
    dispatch({
      type: Get_EmpLeave_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({ type: Page_Loader, payload: true });
    dispatch({
      type: Get_EmpLeave_Filter_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_EmpLeave_Data, payload: [] });
    dispatch({ type: Page_Loader, payload: true });
    dispatch({ type: Get_EmpLeave_Filter_Data, payload: [] });
  }
};

export const get_Leave_Status_Drop = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Leave_Status_Drop(val);
    dispatch({
      type: Get_StatusLeave_Drop,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "Code"),
    });

  } catch (error) {
    dispatch({ type: Get_StatusLeave_Drop, payload: [] });
  }
};


export const get_Leave_Type_Drop = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Leave_Type_Drop(val);
    dispatch({
      type: Get_TypeLeave_Drop,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "Code"),
    });
  } catch (error) {
    dispatch({ type: Get_TypeLeave_Drop, payload: [] });
  }
};

export const save_EmpLeave = (formData, pageStatus) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.save_EmpLeave(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Get_Error_Status, payload: true });
      dispatch({ type: Get_Error_Status, payload: false });
      dispatch(get_Emp_Leave_Data(formData.EmpID, pageStatus));
      dispatch({ type: Modal_Status, payload: false });
    } else {
      toastifyError(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: Get_Error_Status, payload: false });
  }
};
export const update_EmpLeave = (formData, pageStatus) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_EmpLeave(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
      dispatch(get_Emp_Leave_Data(formData.EmpID, pageStatus));
      dispatch({ type: Modal_Status, payload: false });
    } else {
      toastifyError(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: Get_Error_Status, payload: false });
  }
};
export const get_Single_EmpLeave_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_EmpLeave_Data(val);
    dispatch({
      type: Get_Single_EmpLeave_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_EmpLeave_Data, payload: [] });
  }
};
export const delete_EmpLeave =
  (ID, IsActive, userid, pageStatus, EmpID) => async (dispatch) => {
    // const values = EncryptedList( JSON.stringify({ IsActive: IsActive, ID: ID, DeletedByid: userid }));
    const val = { IsActive: IsActive, ID: ID, DeletedByid: userid };
    try {
      const { data } = await api.delete_EmpLeave(val);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(get_Emp_Leave_Data(EmpID, pageStatus));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };
// ------------------------------------> | AdminSetting -> Opertor/Employee | <-------------------------------------
export const get_Department_Drop_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Department_Drop_Data(val);

    dispatch({
      type: Get_Department_Dropdown,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "Department"),
    });
  } catch (error) {
    dispatch({ type: Get_Department_Dropdown, payload: [] });
  }
};

export const get_Unit_Drop_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Unit_Drop_Data(val);
    dispatch({
      type: Unit_Dropdown,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "Unit"),
    });
  } catch (error) {
    dispatch({ type: Unit_Dropdown, payload: [] });
  }
};

export const get_Designation_Drop_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Designation_Drop_Data(val);
    dispatch({
      type: Get_Designation_Dropdown,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "Designation"),
    });
  } catch (error) {
    dispatch({ type: Get_Designation_Dropdown, payload: [] });
  }
};

export const get_BloodGroup_Drop_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_BloodGroup_Drop_Data(val);
    // console.log(data,'data')
    dispatch({
      type: Get_BloodGroup_Dropdown,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "BloodGroup"),
    });
  } catch (error) {
    dispatch({ type: Get_BloodGroup_Dropdown, payload: [] });
  }
};

export const get_Martial_Drop_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Martial_Drop_Data(val);
    dispatch({
      type: Get_Martial_Dropdown,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "Description"),
    });
  } catch (error) {
    dispatch({ type: Get_Martial_Dropdown, payload: [] });
  }
};
export const get_Religion_Drop_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Religion_Drop_Data(val);
    dispatch({
      type: Get_Religion_Dropdown,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "Description"),
    });
  } catch (error) {
    dispatch({ type: Get_Religion_Dropdown, payload: [] });
  }
};
export const get_Gender_Drop_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Gender_Drop_Data(val);
    dispatch({
      type: Get_Gender_Dropdown,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "Description"),
    });
  } catch (error) {
    dispatch({ type: Get_Gender_Dropdown, payload: [] });
  }
};
export const get_Relation_Drop_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Relation_Drop_Data(val);
    dispatch({
      type: Get_Relation_Dropdown,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "Description"),
    });
  } catch (error) {
    dispatch({ type: Get_Relation_Dropdown, payload: [] });
  }
};
export const save_Employee = (formData, employeeData) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  var result = employeeData?.find((item) => {
    if (item.EmpName === formData.EmpName) {
      return true;
    } else return false;
  });
  var result1 = employeeData?.find((item) => {
    if (item.EmpCode === formData.Empcode) {
      return true;
    } else return false;
  });
  if (result || result1) {
    if (result) {
      toastifyError("EmpName Already Exists");
      dispatch({ type: Get_Error_Status, payload: false });
    }
    if (result1) {
      toastifyError("EmpCode Already Exists");
      dispatch({ type: Get_Error_Status, payload: false });
    }
  } else {
    try {
      const { data } = await api.save_Employee(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
        dispatch(get_Employee_Data());
        dispatch(get_Single_Employee_Data(data?.ID));
        dispatch({ type: Status, payload: true });
        dispatch({ type: Employee_ID, payload: data?.ID });
      } else {
        toastifyError(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  }
};
export const update_Employee =
  (formData, ID, employeeData) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    var result = employeeData?.find((item) => {
      if (item.ID !== ID) {
        if (item.EmpName?.toLowerCase() === formData.EmpName?.toLowerCase()) {
          return true;
        } else return false;
      }
    });
    var result1 = employeeData?.find((item) => {
      if (item.ID !== ID) {
        if (item.EmpCode?.toLowerCase() === formData.Empcode?.toLowerCase()) {
          return true;
        } else return false;
      }
    });
    if (result || result1) {
      if (result) {
        toastifyError("EmpName Already Exists");
        dispatch({ type: Get_Error_Status, payload: false });
      }
      if (result1) {
        toastifyError("EmpCode Already Exists");
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } else {
      try {
        const { data } = await api.update_Employee(formData);
        if (data?.success) {
          toastifySuccess(data?.Message);
          dispatch({ type: Get_Error_Status, payload: false });
          dispatch(get_Employee_Data());
          dispatch(get_Single_Employee_Data(ID));
        } else {
          toastifyError(data?.Message);
          dispatch({ type: Get_Error_Status, payload: false });
        }
      } catch (error) {
        console.log(error);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    }
  };
export const delete_Employee_Personal =
  (ID, IsActive, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify({ IsActive: IsActive, ID: ID }));
    const val = { IsActive: IsActive, ID: ID };
    try {
      const { data } = await api.delete_Employee_Personal(val);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(get_Employee_Data());
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };
export const update_Employee_Personal = (formData, ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_Employee_Personal(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
      dispatch(get_Employee_Data());
      dispatch(get_Single_Employee_Data(ID));
    } else {
      toastifyError(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: Get_Error_Status, payload: false });
  }
};
export const update_Employee_Address = (formData, ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_Employee_Address(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
      dispatch(get_Employee_Data());
      dispatch(get_Single_Employee_Data(ID));
    } else {
      toastifyError(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: Get_Error_Status, payload: false });
  }
};
export const update_Employee_Bank = (formData, ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_Employee_Bank(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
      dispatch(get_Employee_Data());
      dispatch(get_Single_Employee_Data(ID));
    } else {
      toastifyError(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: Get_Error_Status, payload: false });
  }
};
export const update_Employee_Family = (formData, ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_Employee_Family(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
      dispatch(get_Employee_Data());
      dispatch(get_Single_Employee_Data(ID));
    } else {
      toastifyError(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: Get_Error_Status, payload: false });
  }
};
export const update_Employee_Education = (formData, ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_Employee_Education(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
      dispatch(get_Employee_Data());
      dispatch(get_Single_Employee_Data(ID));
    } else {
      toastifyError(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: Get_Error_Status, payload: false });
  }
};
export const update_Employee_Eorganization =
  (formData, ID) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.update_Employee_Eorganization(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
        dispatch(get_Employee_Data());
        dispatch(get_Single_Employee_Data(ID));
      } else {
        toastifyError(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };

export const get_Employee_Data = (pageStatus) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: pageStatus }));
  const val = { IsActive: pageStatus };
  try {
    const { data } = await api.get_Employee_Data(val);
    dispatch({
      type: Get_Employee_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({
      type: Get_Employee_Filter_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Employee_Data, payload: [] });
    dispatch({ type: Get_Employee_Filter_Data, payload: [] });
  }
};

export const delete_Employee =
  (employeeId, IsActive, pageStatus, userId) => async (dispatch) => {
    // const values = EncryptedList(  JSON.stringify({IsActive: IsActive,ID: employeeId, DeletedByid: userId, }) );
    const val = { IsActive: IsActive, ID: employeeId, DeletedByid: userId, };
    try {
      const { data } = await api.delete_Employee(val);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(get_Employee_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };
export const get_Single_Employee_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Employee_Data(val);
    dispatch({
      type: Get_Single_Employee_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_Employee_Data, payload: [] });
  }
};

// ------------------------------------> | AdminSetting -> Permission | <-------------------------------------

export const get_MenuPermission_Data = (Userid) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1", Userid: Userid }));
  const val = { IsActive: "1", Userid: Userid };
  try {
    const { data } = await api.get_MenuPermission_Data(val);
    dispatch({
      type: Get_Menu_Permission_Data,
      payload: Decrypt_Fetch_Post_Data(data),
    });
  } catch (error) {
    dispatch({ type: Get_Menu_Permission_Data, payload: [] });
  }
};

export const update_MenuPermission_Data =
  (IsVisible, ID, userId) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify({ IsVisible: IsVisible, ID: ID, userId: userId }));
    const val = { IsVisible: IsVisible, ID: ID, userId: userId };
    try {
      const { data } = await api.update_MenuPermission_Data(val);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(get_MenuPermission_Data(userId));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };

// ------------------------------------> | Emp -> Task | <-------------------------------------
export const get_Task_Drop_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Task_Drop_Data(val);
    dispatch({
      type: Get_TaskType_Dropdown,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "Description"),
    });
  } catch (error) {
    dispatch({ type: Get_TaskType_Dropdown, payload: [] });
  }
};
export const get_EmpStatus_TaskData = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_EmpStatus_TaskData(val);
    console.log(data, 'data')
    dispatch({
      type: Get_EmpStatus_TaskType_Dropdown,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "TaskStatus"),
    });
  } catch (error) {
    dispatch({ type: Get_EmpStatus_TaskType_Dropdown, payload: [] });
  }
};

export const save_Employee_Task_Data = (formData, NavigateUrl) => async (dispatch) => {
  dispatch({ type: Get_Error_Status, payload: false });
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.save_Employee_Task_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
      NavigateUrl()
    }
    else {
      toastifyError(data?.Message);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: Get_Error_Status, payload: false });
  }
};
export const update_Employee_Task_Data = (formData) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_Employee_Task_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: Get_Error_Status, payload: false });
  }
};

export const get_Employee_Task_Data = (AssignEmpID, StatusID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ AssignEmpID: AssignEmpID, StatusID: StatusID, IsActive: "1", }));
  const val = { AssignEmpID: AssignEmpID, StatusID: StatusID, IsActive: "1", };
  try {
    const { data } = await api.get_Employee_Task_Data(val);
    dispatch({
      type: Get_Emp_Task_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Emp_Task_Data, payload: [] });
  }
};
export const get_EmpCount_Task_Data = (IsActive, AssignEmpID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_EmpCount_Task_Data(val);
    dispatch({
      type: Get_EmpCount_Task_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Emp_Task_Data, payload: [] });
  }
};

export const get_Employee_Task_Detail_Data = (TaskID) => async (dispatch) => {
  // const values = EncryptedList( JSON.stringify({ IsActive: "1", TaskID: TaskID }));
  const val = { IsActive: "1", TaskID: TaskID };
  try {
    const { data } = await api.get_Employee_Task_Detail_Data(val);
    dispatch({
      type: Get_Emp_Task_Detail_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Emp_Task_Detail_Data, payload: [] });
  }
};

export const save_Employee_Task_Detail_Data =
  (Task, TaskID, userId) => async (dispatch) => {
    dispatch({ type: Get_Error_Status, payload: false });
    // const values = EncryptedList(JSON.stringify({ TaskID: TaskID, Task: Task, Createdbyid: userId }));
    const val = { TaskID: TaskID, Task: Task, Createdbyid: userId };
    try {
      const { data } = await api.save_Employee_Task_Detail_Data(val);
      dispatch({ type: Get_Error_Status, payload: false });
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(get_Employee_Task_Detail_Data(TaskID));
        dispatch({ type: Replay_Status, payload: false });
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      dispatch({ type: Get_Error_Status, payload: false });
      console.log(error);
    }
  };

export const get_Single_Employee_Task_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Employee_Task_Data(val);
    dispatch({
      type: Get_Single_Emp_Task_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_Emp_Task_Data, payload: [] });
  }
};

export const get_Single_Count_Task_Data = (EmpID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ EmpID: EmpID,StatusID: StatusID }));
  const val = { EmpID: EmpID };
  try {
    const { data } = await api.get_Single_Count_Task_Data(val);
    dispatch({
      type: Get_Emp_Task_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Emp_Task_Data, payload: [] });
  }
};

export const delete_EmpTask_Data = (ID, IsActive, userId, StatusID, empID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID, IsActive: IsActive, DeletedByid: userId }));
  const val = { ID: ID, IsActive: IsActive, DeletedByid: userId };
  try {
    const { data } = await api.delete_EmpTask_Data(val);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch(get_Employee_Task_Data(empID, StatusID));
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log("error", error);
  }
};
// ------------------------------------> | Emp -> Attendance Report | <-------------------------------------

export const get_Employee_Attendance_Data =
  (month, Year) => async (dispatch) => {
    // const values = EncryptedList( JSON.stringify({ month: month, Year: Year, IsActive: "1" }) );
    const val = { month: month, Year: Year, IsActive: "1" };
    try {
      const { data } = await api.get_Employee_Attendance_Data(val);
      dispatch({
        type: Get_Emp_Attendance,
        payload: Decrypt_Fetch_Post_Data_Teble1(data),
      });
    } catch (error) {
      dispatch({ type: Get_Emp_Attendance, payload: [] });
    }
  };

export const get_Attendance_Data =
  (UserId, AttendanceDate) => async (dispatch) => {
    // const values = EncryptedList( JSON.stringify({AttendanceDate: AttendanceDate,  UserId: "0",  IsActive: "1", });
    const val = { AttendanceDate: AttendanceDate, UserId: "0", IsActive: "1", };
    try {
      const { data } = await api.get_Attendance_Data(val);
      dispatch({
        type: Get_Attendance,
        payload: Decrypt_Fetch_Post_Data_Teble(data),
      });
    } catch (error) {
      dispatch({ type: Get_Attendance, payload: [] });
    }
  };

// ------------------------------------> | Mining -> Pit Block | <-------------------------------------
export const get_Pit_Drop_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Pit_Drop_Data(val);
    dispatch({
      type: Get_PitBlock_Dropdown,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "Description"),
    });
  } catch (error) {
    dispatch({ type: Get_PitBlock_Dropdown, payload: [] });
  }
};

export const get_Pit_Block_Data = (IsActive) => async (dispatch) => {
  dispatch({ type: Page_Loader, payload: false });
  // const values = EncryptedList(JSON.stringify({ IsActive: IsActive }));
  const val = { IsActive: IsActive };
  try {
    const { data } = await api.get_Pit_Block_Data(val);
    Decrypt_Fetch_Post_Data_Teble(data);
    dispatch({
      type: Get_PitBlock_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({ type: Page_Loader, payload: true });
    // dispatch({
    //   type: Get_Site_Direction_Dropdown,
    //   payload: Decrypt_DropDown_Data(data, "ID", "SiteDirection"),
    // });
    dispatch({
      type: Get_PitBlock_Filter_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_PitBlock_Data, payload: [] });
    dispatch({ type: Page_Loader, payload: true });
    dispatch({ type: Get_PitBlock_Filter_Data, payload: [] });
  }
};

export const save_Pit_Block_Data =
  (formData, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.save_Pit_Block_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Get_Error_Status, payload: true });
        dispatch({ type: Get_Error_Status, payload: false });
        dispatch(get_Pit_Block_Data(pageStatus));
        dispatch({ type: Modal_Status, payload: false });
      } else {
        toastifyError(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };
export const update_Pit_Block_Data =
  (formData, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.update_Pit_Block_Data(formData);
      dispatch({ type: Get_Error_Status, payload: true });
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(get_Pit_Block_Data(pageStatus));
        dispatch({ type: Modal_Status, payload: false });
      }
    } catch (error) {
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };

export const get_Single_Pit_Block_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Pit_Block_Data(val);
    dispatch({
      type: Get_Single_PitBlock_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_PitBlock_Data, payload: [] });
  }
};


export const delete_PitBlock_Data =
  (ID, IsActive, pageStatus, userId) => async (dispatch) => {
    // const values = EncryptedList( JSON.stringify({ IsActive: IsActive, ID: ID, DeletedByid: userId }));
    const val = { IsActive: IsActive, ID: ID, DeletedByid: userId };
    try {
      const { data } = await api.delete_PitBlock_Data(val);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(get_Pit_Block_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };

export const get_ExlosiveType_Drop_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_ExlosiveType_Drop_Data(val);
    dispatch({
      type: Get_ExplosiveType_Dropdown,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "Description"),
    });
  } catch (error) {
    dispatch({ type: Get_ExplosiveType_Dropdown, payload: [] });
  }
};

export const save_Explosive_Data =
  (formData, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.save_Explosive_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Get_Error_Status, payload: true });
        dispatch({ type: Get_Error_Status, payload: false });
        dispatch(get_Explosive_Data(pageStatus));
        dispatch({ type: Modal_Status, payload: false });
      } else {
        toastifyError(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };
export const get_Explosive_Data = (IsActive) => async (dispatch) => {
  dispatch({ type: Page_Loader, payload: false });
  // const values = EncryptedList(JSON.stringify({ IsActive: IsActive }));
  const val = { IsActive: IsActive };
  try {
    const { data } = await api.get_Explosive_Data(val);
    dispatch({
      type: Get_Explosive_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({ type: Page_Loader, payload: true });
    dispatch({
      type: Get_Explosive_Filter_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Explosive_Data, payload: [] });
    dispatch({ type: Page_Loader, payload: true });
    dispatch({ type: Get_Explosive_Filter_Data, payload: [] });
  }
};

export const update_Explosive_Data =
  (formData, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.update_Explosive_Data(formData);
      dispatch({ type: Get_Error_Status, payload: true });
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(get_Explosive_Data(pageStatus));
        dispatch({ type: Modal_Status, payload: false });
      }
    } catch (error) {
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };

export const get_Single_Explosive_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Explosive_Data(val);
    dispatch({
      type: Get_Single_Explosive_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_Explosive_Data, payload: [] });
  }
};

export const delete_Explosive_Data =
  (ID, IsActive, pageStatus, userId) => async (dispatch) => {
    // const values = EncryptedList( JSON.stringify({ ID: ID, IsActive: IsActive, DeletedByid: userId }));
    const val = { ID: ID, IsActive: IsActive, DeletedByid: userId };
    try {
      const { data } = await api.delete_Explosive_Data(val);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(get_Explosive_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };

// -----------------------> Mining ->Explosive  <------------------------------
export const get_MachineName_Drop_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_MachineName_Drop_Data(val);
    dispatch({
      type: Get_Machine_Name_Dropdown,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "Description"),
    });
  } catch (error) {
    dispatch({ type: Get_Machine_Name_Dropdown, payload: [] });
  }
};
export const save_Mining_Drilling_Data =
  (formData, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.save_Mining_Drilling_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Modal_Status, payload: false });
        dispatch(get_Mining_Drilling_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };

export const get_Mining_Drilling_Data = (IsActive) => async (dispatch) => {
  dispatch({ type: Page_Loader, payload: false });
  // const values = EncryptedList(JSON.stringify({ IsActive: IsActive }));
  const val = { IsActive: IsActive };
  try {
    const { data } = await api.get_Mining_Drilling_Data(val);
    dispatch({
      type: Get_Drilling_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({ type: Page_Loader, payload: true });
    dispatch({
      type: Get_Drilling_Filter_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Drilling_Data, payload: [] });
    dispatch({ type: Page_Loader, payload: true });
    dispatch({ type: Get_Drilling_Filter_Data, payload: [] });
  }
};
export const get_Single_Drilling_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Drilling_Data(val);
    dispatch({
      type: Get_Single_Drilling_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_Drilling_Data, payload: [] });
  }
};

export const update_Drilling_Data =
  (formData, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.update_Drilling_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Modal_Status, payload: false });
        dispatch(get_Mining_Drilling_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };

export const delete_Mining_Drilling =
  (ID, IsActive, pageStatus, userId) => async (dispatch) => {
    // const values = EncryptedList(  JSON.stringify({ IsActive: IsActive, ID: ID, DeletedByid: userId }) );
    const val = { IsActive: IsActive, ID: ID, DeletedByid: userId };
    try {
      const { data } = await api.delete_Mining_Drilling(val);
      if (data?.success) {
        // return data?.success
        toastifySuccess(data?.Message);
        dispatch(get_Mining_Drilling_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

// -----------------------> Mining ->Blast  <---------------------------
export const get_BenchNo_Drop_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_BenchNo_Drop_Data(val);
    dispatch({
      type: Get_BenchNo_Dropdown,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "Bench"),
    });
  } catch (error) {
    dispatch({ type: Get_BenchNo_Dropdown, payload: [] });
  }
};
export const get_Site_Direction_Drop_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Site_Direction_Drop_Data(val);
    console.log(data, 'data')
    dispatch({
      type: Get_Site_Direction_Dropdown,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "Description"),
    });
  } catch (error) {
    dispatch({ type: Get_Site_Direction_Dropdown, payload: [] });
  }
};

export const save_Blast_Entry_Data =
  (formData, pageStatus) => async (dispatch) => {
    dispatch({ type: Get_Error_Status, payload: false });
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.save_Blast_Entry_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Modal_Status, payload: false });
        dispatch(get_Blast_Entry_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };

export const get_Blast_Entry_Data = (IsActive) => async (dispatch) => {
  dispatch({ type: Page_Loader, payload: false });
  // const values = EncryptedList(JSON.stringify({ IsActive: IsActive }));
  const val = { IsActive: IsActive };
  try {
    const { data } = await api.get_Blast_Entry_Data(val);
    dispatch({
      type: Get_Blast_Entry_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({ type: Page_Loader, payload: true });
    dispatch({
      type: Get_Blast_Filter_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Blast_Entry_Data, payload: [] });
    dispatch({ type: Page_Loader, payload: true });
    dispatch({ type: Get_Blast_Filter_Data, payload: [] });
  }
};

export const get_Single_Blast_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Blast_Data(val);
    dispatch({
      type: Get_Single_Blast__Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_Blast__Data, payload: [] });
  }
};

export const update_Blast_Data = (formData, pageStatus) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_Blast_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Modal_Status, payload: false });
      dispatch(get_Blast_Entry_Data(pageStatus));
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const delete_Blast_Data =
  (ID, IsActive, pageStatus, userId) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify({ ID: ID, IsActive: IsActive, DeletedByid: userId }));
    const val = { ID: ID, IsActive: IsActive, DeletedByid: userId };
    try {
      const { data } = await api.delete_Blast_Data(val);
      if (data?.success) {
        // return data?.success
        toastifySuccess(data?.Message);
        dispatch(get_Blast_Entry_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

// ----------------------> Plant - transport -> Meter Reading ---------------

export const get_Meter_Reading_Data = (IsActive) => async (dispatch) => {
  dispatch({ type: Page_Loader, payload: false });
  // const values = EncryptedList(JSON.stringify({ IsActive: IsActive }));
  const val = { IsActive: IsActive };
  try {
    const { data } = await api.get_Meter_Reading_Data(val);
    dispatch({
      type: Get_MeterReading_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({ type: Page_Loader, payload: true });
    dispatch({
      type: Get_Meter_Filter_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_MeterReading_Data, payload: [] });
    dispatch({ type: Page_Loader, payload: true });
    dispatch({ type: Get_Meter_Filter_Data, payload: [] });
  }
};

export const save_Meter_Reading_Data =
  (formData, pageStatus) => async (dispatch) => {
    dispatch({ type: Get_Error_Status, payload: false });
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.save_Meter_Reading_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Modal_Status, payload: false });
        dispatch(get_Meter_Reading_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };

export const update_Meter_Reading_Data =
  (formData, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.update_Meter_Reading_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Modal_Status, payload: false });
        dispatch(get_Meter_Reading_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };

export const get_Single_Meter_Reading_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Meter_Reading_Data(val);
    dispatch({
      type: Get_Single_MeterReading_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_MeterReading_Data, payload: [] });
  }
};

export const delete_Meter_Reading_Data =
  (ID, IsActive, pageStatus, userId) => async (dispatch) => {
    // const values = EncryptedList( JSON.stringify({ ID: ID, IsActive: IsActive, DeletedByid: userId }));
    const val = { ID: ID, IsActive: IsActive, DeletedByid: userId };
    try {
      const { data } = await api.delete_Meter_Reading_Data(val);
      if (data?.success) {
        // return data?.success
        toastifySuccess("delete Succesfully");
        dispatch(get_Meter_Reading_Data(pageStatus));
      } else {
        toastifyError("delete Succesfully");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

// ----------------------> Plant - transport -> Power COnsumption ---------------

export const get_Consumption_Power_Data = (IsActive) => async (dispatch) => {
  dispatch({ type: Page_Loader, payload: false });
  // const values = EncryptedList(JSON.stringify({ IsActive: IsActive }));
  const val = { IsActive: IsActive };
  try {
    const { data } = await api.get_Consumption_Power_Data(val);
    dispatch({
      type: Get_Consumption_Power_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({ type: Page_Loader, payload: true });
    dispatch({
      type: Get_Consumption_Power_Filter_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Consumption_Power_Data, payload: [] });
    dispatch({ type: Page_Loader, payload: true });
    dispatch({ type: Get_Consumption_Power_Filter_Data, payload: [] });
  }
};
export const save_Consumption_Power_Data =
  (formData, pageStatus) => async (dispatch) => {
    dispatch({ type: Get_Error_Status, payload: false });
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.save_Consumption_Power_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Modal_Status, payload: false });
        dispatch(get_Consumption_Power_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };

export const update_Consumption_Power_Data =
  (formData, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.update_Consumption_Power_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Modal_Status, payload: false });
        dispatch(get_Consumption_Power_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };

export const get_Single_Consumption_Power_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Consumption_Power_Data(val);
    console.log(
      "Decrypt_Fetch_Post_Data_Teble(data)",
      Decrypt_Fetch_Post_Data_Teble(data)
    );
    dispatch({
      type: Get_Single_Consumption_Power_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_Consumption_Power_Data, payload: [] });
  }
};
export const delete_Consumption_Power_Data =
  (ID, IsActive, userId, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify({ ID: ID, IsActive: IsActive, DeletedByid: userId }));
    const val = { ID: ID, IsActive: IsActive, DeletedByid: userId };
    try {
      const { data } = await api.delete_Consumption_Power_Data(val);
      if (data?.success) {
        // return data?.success
        toastifySuccess("delete Succesfully");
        dispatch(get_Consumption_Power_Data(pageStatus));
      } else {
        toastifyError("delete Succesfully");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
export const get_Dashboard_Chart_Data =
  (FromDate, url, type) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify({ FromDate: FromDate }));
    const val = { FromDate: FromDate };
    try {
      const { data } = await api.get_Dashboard_Chart_Data(val, url);
      if (type === "Sale")
        dispatch({
          type: Get_Chart_Data,
          payload: Decrypt_Fetch_Post_Data_Teble(data),
        });
      else
        dispatch({
          type: Get_PurchaseChart_Data,
          payload: Decrypt_Fetch_Post_Data_Teble(data),
        });
    } catch (error) {
      dispatch({ type: Get_Chart_Data, payload: [] });
    }
  };



export const get_Dashboard_Task_Data = (empID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ empID: empID }));
  const val = { empID: empID };
  try {
    const { data } = await api.get_Dashboard_Task_Data(val);
    dispatch({
      type: Get_Task_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Task_Data, payload: [] });
  }
};

// ------------------------Inventory -> Add request ------------->
export const save_Add_Request_Data =
  (formData, pageStatus) => async (dispatch) => {
    dispatch({ type: Get_Error_Status, payload: false });
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.save_Add_Request_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(get_AddRequest_Data(pageStatus));
        dispatch(get_Single_Request_Data(data?.ID));
        dispatch({ type: Get_Req_Id, payload: data?.ID });
        dispatch({ type: Status, payload: true });
      } else {
        toastifyError(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };

export const get_AddRequest_Data = (IsActive) => async (dispatch) => {
  dispatch({ type: Page_Loader, payload: false });
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_AddRequest_Data(val);
    dispatch({
      type: Get_AddRequest_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    // dispatch({ type: Page_Loader, payload: true });
    // dispatch({
    //   type: Get_Consumption_Power_Filter_Data,
    //   payload: Decrypt_Fetch_Post_Data_Teble(data),
    // });
  } catch (error) {
    dispatch({ type: Get_AddRequest_Data, payload: [] });
    // dispatch({ type: Page_Loader, payload: true });
    // dispatch({ type: Get_Consumption_Power_Filter_Data, payload: [] });
  }
};

export const update_Add_Request_Data =
  (formData, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.update_Add_Request_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };
export const get_Single_Request_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Request_Data(val);
    dispatch({
      type: Get_Single_Request_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_Request_Data, payload: [] });
  }
};
export const delete_Add_Request_Data =
  (ID, IsActive, userId, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify({ ID: ID, IsActive: IsActive, DeletedByid: userId }));
    const val = { ID: ID, IsActive: IsActive, DeletedByid: userId };
    try {
      const { data } = await api.delete_Add_Request_Data(val);
      if (data?.success) {
        // return data?.success
        toastifySuccess(data?.Message);
        dispatch(get_AddRequest_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

export const save_Request_List_Data =
  (formData, Id) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.save_Request_List_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Get_Error_Status, payload: true });
        dispatch({ type: Check_Page, payload: false })
        dispatch({ type: Get_Error_Status, payload: false });
        dispatch(get_Request_List_Data(Id));
        dispatch({ type: Modal_Status, payload: false });
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };
export const get_Request_List_Data =
  (RequestID) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify({ IsActive: "1", RequestID: RequestID }));
    const val = { IsActive: "1", RequestID: RequestID };
    try {
      const { data } = await api.get_Request_List_Data(val);
      dispatch({
        type: Get_RequestList_Data,
        payload: Decrypt_Fetch_Post_Data_Teble(data),
      });
      console.log(data);
    } catch (error) {
      dispatch({ type: Get_RequestList_Data, payload: [] });
    }
  };
export const update_Request_List_Data = (formData, Id) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_Request_List_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Modal_Status, payload: false });
      dispatch(get_Request_List_Data(Id));
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const get_Single_RequestList_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_RequestList_Data(val);
    console.log(
      "Decrypt_Fetch_Post_Data_Teble(data)",
      Decrypt_Fetch_Post_Data_Teble(data)
    );
    dispatch({
      type: Get_Single_RequestList_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_RequestList_Data, payload: [] });
  }
};
export const delete_RequestItem_Data =
  (ID, IsActive, userId, pageStatus, ReqId) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify({ ID: ID, IsActive: IsActive, DeletedByid: userId }));
    const val = { ID: ID, IsActive: IsActive, DeletedByid: userId };
    try {
      const { data } = await api.delete_RequestItem_Data(val);
      if (data?.success) {
        toastifySuccess("delete Successfully");
        dispatch(get_Request_List_Data(ReqId, pageStatus));
      } else {
        toastifyError('delete Successfully');
      }
    } catch (error) {
      console.log("error", error);
    }
  };

// -------------------------Request Approve --------------
export const get_Approve_Request_Data = (IsActive) => async (dispatch) => {
  dispatch({ type: Page_Loader, payload: false });
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Approve_Request_Data(val);
    dispatch({
      type: Get_Approve_Request,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({ type: Page_Loader, payload: true });
    console.log(data);
  } catch (error) {
    dispatch({ type: Page_Loader, payload: true });
    dispatch({ type: Get_Approve_Request, payload: [] });
  }
};
export const set_Approve_Request_Data =
  (formData, pageStatus) => async (dispatch) => {
    dispatch({ type: Get_Error_Status, payload: false });
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.set_Approve_Request_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Modal_Status, payload: false });
        dispatch(get_Approve_Request_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };
export const set_UnApprove_Request_Data =
  (formData, pageStatus) => async (dispatch) => {
    dispatch({ type: Get_Error_Status, payload: false });
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.set_UnApprove_Request_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Modal_Status, payload: false });
        dispatch(get_Approve_Request_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };

// -------------------------Reject Request -----------------
export const get_UnApprove_Request_Data = (IsActive) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_UnApprove_Request_Data(val);
    dispatch({
      type: Get_UnApprove_Request,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_UnApprove_Request, payload: [] });
  }
};
export const get_DeApprove_Request_Data = (RequestID) => async (dispatch) => {
  // const values = EncryptedList( JSON.stringify({ IsActive: "1", RequestID: RequestID }));
  const val = { IsActive: "1", RequestID: RequestID };
  try {
    const { data } = await api.get_DeApprove_Request_Data(val);
    dispatch({
      type: Get_DeApprove_Request,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_DeApprove_Request, payload: [] });
  }
};
export const set_Deapprove_Request_Data =
  (formData, pageStatus) => async (dispatch) => {
    dispatch({ type: Get_Error_Status, payload: false });
    // const values = EncryptedList(JSON.stringify(formData));
    // console.log(formData);
    // const val = { Data: values };
    try {
      const { data } = await api.set_Deapprove_Request_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Modal_Status, payload: false });
        dispatch(get_DeApprove_Request_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };
export const set_Reject_Request_Data =
  (formData, pageStatus) => async (dispatch) => {
    dispatch({ type: Get_Error_Status, payload: false });
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.set_Reject_Request_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Modal_Status, payload: false });
        dispatch(get_UnApprove_Request_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };

//  --------------------------Quotes enter------------------
export const get_Receivedby_DropData = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Receivedby_DropData(val);
    dispatch({
      type: Get_Receivedby_DropData,
      payload: Decrypt_DropDown_Data(data, "ID", "Description"),
    });
  } catch (error) {
    dispatch({ type: Get_Receivedby_DropData, payload: [] });
  }
};
export const get_Discount_DropData = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Discount_DropData(val);
    dispatch({
      type: Get_DiscountType_DropData,
      payload: Decrypt_DropDown_Data(data, "ID", "Description"),
    });
  } catch (error) {
    dispatch({ type: Get_DiscountType_DropData, payload: [] });
  }
};
export const get_Gst_DropData = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Gst_DropData(val);
    dispatch({
      type: Get_Gst_DropData,
      payload: Decrypt_DropDown_Data(data, "ID", "Description"),
    });
  } catch (error) {
    dispatch({ type: Get_Gst_DropData, payload: [] });
  }
};
export const get_supplier_DropData = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_supplier_DropData(val);
    dispatch({
      type: Get_Supplier_DropData,
      payload: Decrypt_DropDown_Data(data, "ID", "Name"),
    });
    console.log(data);
  } catch (error) {
    dispatch({ type: Get_Supplier_DropData, payload: [] });
  }
};
export const get_Quotes_Enter_Data = (IsActive) => async (dispatch) => {
  dispatch({ type: Page_Loader, payload: false });
  // const values = EncryptedList(JSON.stringify({ IsActive: IsActive }));
  const val = { IsActive: IsActive };
  try {
    const { data } = await api.get_Quotes_Enter_Data(val);
    dispatch({
      type: Get_Quotes_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({ type: Page_Loader, payload: true });
    // dispatch({
    //   type: Get_Consumption_Power_Filter_Data,
    //   payload: Decrypt_Fetch_Post_Data_Teble(data),
    // });
  } catch (error) {
    dispatch({ type: Get_Quotes_Data, payload: [] });
    dispatch({ type: Page_Loader, payload: true });
    // dispatch({ type: Get_Consumption_Power_Filter_Data, payload: [] });
  }
};
export const save_Quotes_Enter_Data =
  (formData, pageStatus) => async (dispatch) => {
    dispatch({ type: Get_Error_Status, payload: false });
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.save_Quotes_Enter_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Modal_Status, payload: false });
        dispatch(get_Quotes_Enter_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };

export const update_Quotes_Enter_Data = (formData, pageStatus) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_Quotes_Enter_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch(get_Quotes_Enter_Data(pageStatus));
      dispatch({ type: Modal_Status, payload: false });
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const get_Single_Quotes_Enter_Data = (id) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ id: id }));
  const val = { id: id };
  try {
    const { data } = await api.get_Single_Quotes_Enter_Data(val);
    console.log(
      "Decrypt_Fetch_Post_Data_Teble(data)",
      Decrypt_Fetch_Post_Data_Teble(data)
    );
    dispatch({
      type: Get_Single_Quotes_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_Quotes_Data, payload: [] });
  }
};
export const delete_Quotes_Enter_Data =
  (ID, IsActive, userId, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify({ ID: ID, IsActive: IsActive, DeletedByid: userId }));
    const val = { ID: ID, IsActive: IsActive, DeletedByid: userId };
    try {
      const { data } = await api.delete_Quotes_Enter_Data(val);
      if (data?.success) {
        // return data?.success
        toastifySuccess(data?.Message);
        dispatch(get_Quotes_Enter_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
// -----------------------Inventory ----------------
export const get_MaterialGroup_DrpData = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_MaterialGroup_DrpData(val);
    dispatch({
      type: Get_MaterialGroup_DropData,
      payload: Decrypt_DropDown_Data(data, "ID", "GroupName"),
    });
  } catch (error) {
    dispatch({ type: Get_MaterialGroup_DropData, payload: [] });
  }
};
export const get_MaterialType_DrpData = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_MaterialType_DrpData(val);
    dispatch({
      type: Get_MaterialType_DropData,
      payload: Decrypt_DropDown_Data(data, "ID", "Type"),
    });
  } catch (error) {
    dispatch({ type: Get_MaterialType_DropData, payload: [] });
  }
};
export const get_Inventory_Material_Data = (IsActive) => async (dispatch) => {
  dispatch({ type: Page_Loader, payload: false });
  // const values = EncryptedList(JSON.stringify({ IsActive: IsActive }));
  const val = { IsActive: IsActive };
  try {
    const { data } = await api.get_Inventory_Material_Data(val);
    dispatch({
      type: Get_Inventory_Material_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({ type: Page_Loader, payload: true });
    dispatch({
      type: Get_Inventory_Material_Filter_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Inventory_Material_Data, payload: [] });
    dispatch({ type: Page_Loader, payload: true });
    dispatch({ type: Get_Inventory_Material_Filter_Data, payload: [] });
  }
};
export const save_Inventory_Material_Data =
  (formData, pageStatus) => async (dispatch) => {
    dispatch({ type: Get_Error_Status, payload: false });
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.save_Inventory_Material_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: MasterTable_Modal_Status, payload: false });
        dispatch(get_Inventory_Material_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };

export const update_Inventory_Material_Data =
  (formData, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.update_Inventory_Material_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: MasterTable_Modal_Status, payload: false });
        dispatch(get_Inventory_Material_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  };

export const get_Single_Inventory_Material_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Inventory_Material_Data(val);
    dispatch({
      type: Get_Single_Inventory_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_Inventory_Data, payload: [] });
  }
};

export const delete_Inventory_Material_Data =
  (ID, IsActive, userId, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify({ ID: ID, IsActive: IsActive, DeletedByid: userId }));
    const val = { ID: ID, IsActive: IsActive, DeletedByid: userId };
    try {
      const { data } = await api.delete_Inventory_Material_Data(val);
      if (data?.success) {
        // return data?.success
        toastifySuccess(data?.Message);
        dispatch(get_Inventory_Material_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
// -------------------------Quotes Approve --------------
export const get_Approve_Quotes_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Approve_Quotes_Data(val);
    dispatch({
      type: Get_Approve_Quotes,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Approve_Quotes, payload: [] });
  }
};

export const set_Approve_Quotes_Data =
  (formData, pageStatus) => async (dispatch) => {
    dispatch({ type: Get_Error_Status, payload: false });
    // const values = EncryptedList(JSON.stringify(formData));
    // console.log(formData,'ghj');
    // const val = { Data: values };
    try {
      const { data } = await api.set_Approve_Quotes_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Modal_Status, payload: false });
        dispatch(get_Approve_Quotes_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };
//  ----------------------Inventory -> purchanse invoice----------

export const get_InvoiceType_DropData = (IsActive) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_InvoiceType_DropData(val);
    dispatch({
      type: Get_Invoice_Type_DropData,
      payload: Decrypt_Fetch_Post_Data_Teble(data, "ID", "Description"),
    });
  } catch (error) {
    dispatch({ type: Get_Invoice_Type_DropData, payload: [] });
  }
};
export const save_InvoiceType_Data =
  (formData, pageStatus) => async (dispatch) => {
    dispatch({ type: Get_Error_Status, payload: false });
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.save_InvoiceType_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch(get_Purchase_Invoice_Data(pageStatus));
        dispatch(get_Single_Purchase_Invoice_Data(data?.ID));
        dispatch({ type: Get_Invoice_ID, payload: data?.ID });
        dispatch({ type: Page_Status, payload: true });
      } else {
        toastifyError(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };
export const update_InvoiceType_Data = (formData, ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_InvoiceType_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      // dispatch(get_Purchase_Invoice_Data());
      // dispatch(get_Single_Purchase_Invoice_Data(ID));
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const get_Purchase_Invoice_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ IsActive: "1" }));
  const val = { IsActive: "1" };
  try {
    const { data } = await api.get_Purchase_Invoice_Data(val);
    dispatch({
      type: Get_PurchaseInvoice_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({
      type: Get_Invoice_Filter_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_PurchaseInvoice_Data, payload: [] });
    dispatch({ type: Get_Invoice_Filter_Data, payload: [] });
  }
};

export const get_Single_Purchase_Invoice_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Purchase_Invoice_Data(val);
    dispatch({
      type: Get_Single_PurchaseInvoice_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_PurchaseInvoice_Data, payload: [] });
  }
};
export const delete_InvoiceType_Data =
  (ID, IsActive, userId, pageStatus) => async (dispatch) => {
    // const values = EncryptedList(JSON.stringify({ ID: ID, IsActive: IsActive, DeletedByid: userId } );
    const val = { ID: ID, IsActive: IsActive, DeletedByid: userId };
    try {
      const { data } = await api.delete_InvoiceType_Data(val);
      if (data?.success) {
        // return data?.success
        toastifySuccess(data?.Message);
        dispatch(get_Purchase_Invoice_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
// -----------------------------Add Item data---------------

export const save_AddItem_Data = (formData, Id) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.save_AddItem_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Get_Error_Status, payload: true });
      dispatch({ type: Get_Error_Status, payload: false });
      dispatch(get_AddItem_Data(Id));
      dispatch({ type: Modal_Status, payload: false });
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const update_AddItem_Data = (formData, Id) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_AddItem_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Modal_Status, payload: false });
      dispatch(get_AddItem_Data(Id));
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const get_AddItem_Data = (PurchaseInvoiceID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ isActive: "1", PurchaseInvoiceID: PurchaseInvoiceID }));
  const val = { isActive: "1", PurchaseInvoiceID: PurchaseInvoiceID };
  try {
    const { data } = await api.get_AddItem_Data(val);
    dispatch({
      type: Get_AddItem_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_AddItem_Data, payload: [] });
  }
};
export const get_Single_AddItem_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_AddItem_Data(val);
    dispatch({
      type: Get_Single_AddItem_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_AddItem_Data, payload: [] });
  }
};
export const delete_ItemDetails_Data = (ID, IsActive, userId, pageStatus, invoiceID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID, IsActive: IsActive, DeletedByid: userId }));
  const val = { ID: ID, IsActive: IsActive, DeletedByid: userId };
  try {
    const { data } = await api.delete_ItemDetails_Data(val);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch(get_AddItem_Data(invoiceID, pageStatus));
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log("error", error);
  }
};
// ---------------------Admin Setting > PhoneSms------------
export const get_PhoneSms_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ isActive: "1" }));
  const val = { isActive: "1" };
  try {
    const { data } = await api.get_PhoneSms_Data(val);
    dispatch({
      type: Get_PhoneSms_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({
      type: Get_Filter_PhoneSms_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_PhoneSms_Data, payload: [] });
    dispatch({ type: Get_Filter_PhoneSms_Data, payload: [] });
  }
};

export const get_Single_PhoneSms_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_PhoneSms_Data(val);
    dispatch({
      type: Get_Single_PhoneSms_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_PhoneSms_Data, payload: [] });
  }
};
export const update_PhoneSms_Data = (formData, pageStatus) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_PhoneSms_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Modal_Status, payload: false });
      dispatch(get_PhoneSms_Data(pageStatus));
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};
export const delete_PhoneSms_Data = (ID, IsActive, userId, pageStatus) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID, IsActive: IsActive, DeletedByid: userId }));
  const val = { ID: ID, IsActive: IsActive, DeletedByid: userId };
  try {
    const { data } = await api.delete_PhoneSms_Data(val);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch(get_PhoneSms_Data(pageStatus));
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log("error", error);
  }
};
//-------------------------------------Service>ServiceConfiguration----------------------
export const save_Service_Data =
  (formData, pageStatus) => async (dispatch) => {
    dispatch({ type: Get_Error_Status, payload: false });
    // const values = EncryptedList(JSON.stringify(formData));
    // const val = { Data: values };
    try {
      const { data } = await api.save_Service_Data(formData);
      console.log(data, 'data')
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Modal_Status, payload: false });
        dispatch(get_ServiceConfiguration_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };

export const update_Service_Data = (formData, pageStatus) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_Service_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Modal_Status, payload: false });
      dispatch(get_ServiceConfiguration_Data(pageStatus));

    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const get_ServiceConfiguration_Dropdown = (IsActive) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ 'IsActive': IsActive }));
  const val = { 'IsActive': IsActive };
  try {
    const { data } = await api.get_ServiceConfiguration_Dropdown(val);
    let DecData = Decrypt_Fetch_Post_Data_Teble(data)
    dispatch({
      type: Get_ServiceConfiguration_Dropdown,
      payload: changeDropDownArray(DecData, 'ID', 'Group', 'Name'),
    });
  } catch (error) {
    dispatch({ type: Get_ServiceConfiguration_Dropdown, payload: [] });
  }
};

export const get_ServiceConfiguration_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ isActive: "true" }));
  const val = { isActive: "true" };
  try {
    const { data } = await api.get_ServiceConfiguration_Data(val);
    console.log(data, "bgghbgb")
    dispatch({
      type: Get_ServiceConfiguration_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({
      type: Get_Filter_ServiceConfiguration_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_ServiceConfiguration_Data, payload: [] });
    dispatch({ type: Get_Filter_ServiceConfiguration_Data, payload: [] });
  }
};

export const delete_ServiceConfiguration_Data = (ID, IsActive, userId, pageStatus) => async (dispatch) => {
  console.log(ID, "MHIUGH")
  // const values = EncryptedList(JSON.stringify({ ID: ID, IsActive: IsActive, DeletedByid: userId }));
  const val = { ID: ID, IsActive: IsActive, DeletedByid: userId };
  console.log(val, 'val')
  try {
    const { data } = await api.delete_ServiceConfiguration_Data(val);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch(get_ServiceConfiguration_Data(pageStatus));
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};
export const get_Single_ServiceConfiguration_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_ServiceConfiguration_Data(val);
    dispatch({
      type: Get_Single_ServiceConfiguration_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_ServiceConfiguration_Data, payload: [] });
  }
};
// ---------------------------------------Service>Mantinance-----------------------------------------------

export const save_Mantinance_Data =
  (formData, pageStatus) => async (dispatch) => {
    dispatch({ type: Get_Error_Status, payload: false });
    // const values = EncryptedList(JSON.stringify(formData));
    // console.log(formData,"from")
    // const val = { Data: values };
    try {
      const { data } = await api.save_Mantinance_Data(formData);
      if (data?.success) {
        toastifySuccess(data?.Message);
        dispatch({ type: Modal_Status, payload: false });
        dispatch(get_Mantinance_Data(pageStatus));
      } else {
        toastifyError(data?.Message);
        dispatch({ type: Get_Error_Status, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: Get_Error_Status, payload: false });
    }
  };

export const get_Mantinance_Data = () => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ isActive: "true" }));
  const val = { isActive: "true" };
  try {
    const { data } = await api.get_Mantinance_Data(val);
    // console.log(data,"bgghbgb")
    dispatch({
      type: Get_Mantinance_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
    dispatch({
      type: Get_Filter_Mantinance_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: get_Mantinance_Data, payload: [] });
    dispatch({ type: Get_Filter_Mantinance_Data, payload: [] });
  }
};

export const update_Mantinance_Data = (formData, pageStatus) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify(formData));
  // const val = { Data: values };
  try {
    const { data } = await api.update_Mantinance_Data(formData);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch({ type: Modal_Status, payload: false });
      dispatch(get_Mantinance_Data(pageStatus));
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const get_Single_Mantinance_Data = (ID) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ ID: ID }));
  const val = { ID: ID };
  try {
    const { data } = await api.get_Single_Mantinance_Data(val);
    dispatch({
      type: Get_Single_Mantinance_Data,
      payload: Decrypt_Fetch_Post_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_Single_Mantinance_Data, payload: [] });
  }
};

export const get_Mantinance_Dropdown = (IsActive) => async (dispatch) => {
  // const values = EncryptedList(JSON.stringify({ 'IsActive': IsActive }));
  const val = { 'IsActive': IsActive };
  try {
    const { data } = await api.get_Mantinance_Dropdown(val);
    let DecData = Decrypt_Fetch_Post_Data_Teble(data)
    // console.log(DecData, "klj")
    dispatch({
      type: Get_Mantinance_Dropdown,
      payload: changeDropDownArray(DecData, 'ID', 'Name'),
    });
  } catch (error) {
    dispatch({ type: Get_Mantinance_Dropdown, payload: [] });
  }


};

export const delete_Mantinance_Data = (ID, IsActive, userId, pageStatus) => async (dispatch) => {
  console.log(ID, "MHIUGH")
  // const values = EncryptedList(JSON.stringify({ ID: ID, IsActive: IsActive, DeletedByid: userId }));
  const val = { ID: ID, IsActive: IsActive, DeletedByid: userId };
  try {
    const { data } = await api.delete_Mantinance_Data(val);
    if (data?.success) {
      toastifySuccess(data?.Message);
      dispatch(get_Mantinance_Data(pageStatus));
    } else {
      toastifyError(data?.Message);
    }
  } catch (error) {
    console.log(error);
  }
};

//---------------------chart------------------------------
export const get_SalebyProduct_Chart_Data = (FromDate, Todate) => async (dispatch) => {
  const val = { 'FromDate': FromDate, 'Todate': Todate };
  try {
    const { data } = await api.get_SalebyProduct_Chart_Data(val);
    dispatch({
      type: Get_SalebyProduct_Chart_Data,
      payload: Decrypt_Chart_Data_Teble(data)
    });
  } catch (error) {
    dispatch({ type: Get_SalebyProduct_Chart_Data, payload: [] });
  }
};

export const get_SalebyCash_Chart_Data = (FromDate, Todate) => async (dispatch) => {
  const val = { 'FromDate': FromDate, 'Todate': Todate };
  try {
    const { data } = await api.get_SalebyCash_Chart_Data(val);
    dispatch({
      type: Get_SalebybyCash_Chart_Data,
      payload: Decrypt_Chart_Data_Teble(data)
    });
  } catch (error) {
    dispatch({ type: Get_SalebybyCash_Chart_Data, payload: [] });
  }
};

export const get_SalebyUdhar_Chart_Data = (FromDate, Todate) => async (dispatch) => {
  const val = { 'FromDate': FromDate, 'Todate': Todate };
  try {
    const { data } = await api.get_SalebyUdhar_Chart_Data(val);
    dispatch({
      type: Get_SalebyUdhar_Chart_Data,
      payload: Decrypt_Chart_Data_Teble(data),
    });
  } catch (error) {
    dispatch({ type: Get_SalebyUdhar_Chart_Data, payload: [] });
  }
};


















































































//---------------------------------------------------------Banti--------------------------------------------




































































































































































// ------------------------------------------------------------------mitali--------------------------------------------













































































































