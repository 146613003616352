import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import InventryNavBar from './InventryNavBar';
import { HrNavBar } from './HrNavBar';


const SideNavbar = (props) => {
  
    const { InventrySideBar, DashBoardSideBar, hrSideBar } = props

    const useQuery = () => new URLSearchParams(useLocation().search);
    let openPage = useQuery().get('page');

    return (
        <nav className="navbar" >
            <div className="container-fluid">
                {
                    InventrySideBar && <InventryNavBar openPage={openPage} />
                }
                {
                    DashBoardSideBar && <></>
                }
                {
                    hrSideBar && <HrNavBar openPage={openPage} />
                }
                {/* <ul className="navbar-nav flex-column">
                    {['Add Request', 'Approve Request', 'Reject Request', 'Approve Quotes', 'Enter Quotes', 'PO Section', 'Purchase Invoice'].map((page) => (
                        <li className="nav-item" key={page}>
                            <Link
                                to={`/Inventory?page=${page}`}
                                style={{ backgroundColor: openPage === page ? '#189AB4' : '' }}
                            >
                                <a className="nav-link active">{page}</a>
                            </Link>
                        </li>
                    ))}
                </ul> */}
            </div>
        </nav>
    );
};

export default SideNavbar;
