// import axios from "axios";

import axios from "axios";


// const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });
const API = axios.create({ baseURL: sessionStorage.getItem("url"), });

const API2 = axios.create({ baseURL: "https://tiwariapi.arustu.com/api" });

// API.interceptors.request.use((req) => {
//   if (localStorage.getItem("user")) {
//     req.headers.Authorization = `Bearer ${
//       JSON.parse(localStorage.getItem("user")).token
//     }`;
//   }
//   return req;
// });
  
// ----------------------------------- Header ----------------------------

export const get_Menu = async (formData) => { return await API.post("/menu/GetData_Menu", formData); };

// -------------------------------- Master Table --------------------------
export const get_Master_Table = async (url, formData) => { return await API.post(url, formData); };
export const save_Master_Table = async (formData, url) => { return await API.post(url, formData); };
export const update_Master_Table = async (formData, url) => { return await API.post(url, formData); };
export const delete_Master_Table = async (formData, url) => { return await API.post(url, formData); };
export const get_Single_Master_Table = async (formData, url) => { return await API.post(url, formData); };
export const save_Company_Profile_Data = async (formData, url) => { return await API.post(url, formData); };

// ---------------------------------------- Admin Setting ------------------------------

export const get_Single_Company_Data = async (formData) => { return await API.post("/Company/GetSingleData_Company", formData); };
// ------------------------------ Company Profile -------------------------------
export const update_Company_Profile_Data = async (formData) => { return await API.post("/Company/UpdateCompany", formData); };
export const update_Company_Invoice_Data = async (formData) => { return await API.post("/Company/UpdateEInvoice", formData); };
export const update_Company_Gst_Data = async (formData) => { return await API.post("/Company/UpdateGST", formData); };

// ------------------------Search Challan ----------------------
export const Get_challan_Data = async (formData) => { return await API.post("/ChallanSearch/GetData_ChallanSearch", formData); };

// -----------------Party Sale---------------------
export const save_Party_Sale = async (formData) => { return await API.post("/Party/InsertParty", formData); };
export const upadate_Party_Sale = async (formData) => { return await API.post("/Party/UpdateParty", formData); };
export const get_Single_Party_Data = async (formData) => { return await API.post("/Party/GetSingleData_Party", formData); };
export const get_Party_Sale_Data = async (formData) => { return await API.post("/Party/GetData_Party", formData); };
export const get_State = async (formData) => { return await API.post("/State/GetData_State", formData); };
export const get_City = async (formData) => { return await API.post("/District/GetDataDropDown_District", formData); };
export const delete_Sale_Data = async (formData) => { return await API.post("/Party/DeleteParty", formData); };

// -------------------------product data------------------
export const get_Product_Dropdown = async (formData) => { return await API.post("/ProductGroup/GetDataDropDown_ProductGroup", formData); };
export const get_Unit_Dropdown = async (formData) => { return await API.post("/UnitType/GetDataDropDown_UnitType", formData); };
export const get_Product_Data = async (formData) => { return await API.post("/Product/GetData_Product", formData); };
export const save_Product_Data = async (formData) => { return await API.post("/Product/InsertProduct", formData); };
export const update_Product_Data = async (formData) => { return await API.post("/Product/UpdateProduct", formData); };
export const get_Single_Product_Data = async (formData) => { return await API.post("/Product/GetSingleData_Product", formData); };
export const delete_Product_Data = async (formData) => { return await API.post("/Product/DeleteProduct", formData); };

// -------------------------vechicle details------------------
export const get_EquipmentVehicle_Data = async (formData) => { return await API.post("/EquipmentVehicle/GetData_EquipmentVehicle", formData); };
export const save_Equipment_Vechicle_Data = async (formData) => { return await API.post("/EquipmentVehicle/InsertEquipmentVehicle", formData); };
export const get_Single_EquipmentVehicle_Data = async (formData) => { return await API.post("/EquipmentVehicle/GetSingleData_EquipmentVehicle",formData);};
export const update_Equipment_Vechicle_Data = async (formData) => { return await API.post("/EquipmentVehicle/UpdateEquipmentVehicle", formData); };
export const delete_Equipment_Vechicle_Data = async (formData) => { return await API.post("/EquipmentVehicle/DeleteEquipmentVehicle", formData); };
// -------------------------Insurance details------------------
export const get_EquipmentInsurance_Data = async (formData) => { return await API.post("/EquipmentVehInsurance/GetData_EquipmentVehInsurance", formData); };
export const save_Equipment_Insurance_Data = async (formData) => { return await API.post("/EquipmentVehInsurance/InsertEquipmentVehInsurance", formData); };
export const get_EquipmentVehicle_Dropdown = async (formData) => { return await API.post("/VehicleType/GetDataDropDown_VehicleType", formData); };
export const get_Single_Insurance_Data = async (formData) => { return await API.post("/EquipmentVehInsurance/GetSingleData_EquipmentVehInsurance", formData); };
export const update_Equipment_Insurance_Data = async (formData) => { return await API.post("/EquipmentVehInsurance/UpdateEquipmentVehInsurance", formData); };
export const delete_Equipment_Insurance_Data = async (formData) => { return await API.post("/EquipmentVehInsurance/DeleteEquipmentVehInsurance", formData); };
// -------------------------RTO details------------------
export const get_EquipmentRto_Data = async (formData) => { return await API.post("/EquipmentVehRoadTax/GetData_EquipmentVehRoadTax", formData); };
export const save_Equipment_Rto_Data = async (formData) => { return await API.post("/EquipmentVehRoadTax/InsertEquipmentVehRoadTax", formData); };
export const get_Single_Rto_Data = async (formData) => { return await API.post("/EquipmentVehRoadTax/GetSingleData_EquipmentVehRoadTax", formData); };
export const update_Equipment_Rto_Data = async (formData) => { return await API.post("/EquipmentVehRoadTax/UpdateEquipmentVehRoadTax", formData); };
export const delete_Equipment_Rto_Data = async (formData) => { return await API.post("/EquipmentVehRoadTax/DeleteEquipmentVehRoadTax", formData); };
// -------------------------Fitness details------------------
export const get_EquipmentFitness_Data = async (formData) => { return await API.post("/EquipmentVehFitness/GetData_EquipmentVehFitness", formData); };
export const save_Equipment_Fitness_Data = async (formData) => { return await API.post("/EquipmentVehFitness/InsertEquipmentVehFitness", formData); };
export const get_Single_Fitness_Data = async (formData) => { return await API.post("/EquipmentVehFitness/GetSingleData_EquipmentVehFitness", formData); };
export const update_Equipment_Fitness_Data = async (formData) => { return await API.post("/EquipmentVehFitness/UpdateEquipmentVehFitness", formData); };
export const delete_Equipment_Fitness_Data = async (formData) => { return await API.post("/EquipmentVehFitness/DeleteEquipmentVehFitness", formData); };
// -------------------------Puc details------------------
export const get_EquipmentPuc_Data = async (formData) => { return await API.post("/EquipmentVehPUC/GetData_EquipmentVehPUC", formData); };
export const save_Equipment_Puc_Data = async (formData) => { return await API.post("/EquipmentVehPUC/InsertEquipmentVehPUC", formData); };
export const get_Single_Puc_Data = async (formData) => { return await API.post("/EquipmentVehPUC/GetSingleData_EquipmentVehPUC", formData); };
export const update_Equipment_Puc_Data = async (formData) => { return await API.post("/EquipmentVehPUC/UpdateEquipmentVehPUC", formData); };
export const delete_Equipment_Puc_Data = async (formData) => { return await API.post("/EquipmentVehPUC/DeleteEquipmentVehPUC", formData); };
// -----------------------Plant Data-----------------
export const get_Service_Dropdown = async (formData) => { return await API.post("/PlantServiceType/GetDataDropDown_PlantServiceType", formData); };
export const get_PlantService_Data = async (formData) => { return await API.post("/PlantServiceProcess/GetData_PlantServiceProcess", formData); };
export const save_PlantService_Data = async (formData) => { return await API.post("/PlantServiceProcess/InsertPlantServiceProcess", formData); };
export const update_PlantService_Data = async (formData) => { return await API.post("/PlantServiceProcess/UpdatePlantServiceProcess", formData); };
export const get_Single_PlantService_Data = async (formData) => { return await API.post("/PlantServiceProcess/GetSingleData_PlantServiceProcess", formData); };
export const delete_PlantService_Data = async (formData) => { return await API.post("/PlantServiceProcess/DeletePlantServiceProcess", formData); };
// ------------------------Cost Head Data---------------------
export const get_Nature_Dropdown = async (formData) => { return await API.post("/MaterialNature/GetDataDropDown_MaterialNature", formData); };
export const get_CostHead_Dropdown = async (formData) => { return await API.post("/MaterialCostHead/GetDataDropDown_MaterialCostHead", formData); };
export const get_CostSubhead_Dropdown = async (formData) => { return await API.post("/MaterialCostSubHead/GetDataDropDown_MaterialCostSubHead", formData); };
export const get_Center_Dropdown = async (formData) => { return await API.post("/MaterialCostCenter/GetDataDropDown_MaterialCostCenter", formData); };
export const save_MaterialCost_Data = async (formData) => { return await API.post("/MaterialCostLink/InsertMaterialCostLink", formData); };
export const update_MaterialCost_Data = async (formData) => { return await API.post("/MaterialCostLink/UpdateMaterialCostLink", formData); };
export const get_CostHead_Data = async (formData) => { return await API.post("/MaterialCostLink/GetData_MaterialCostLink", formData); };
export const get_Single_Costhead_Data = async (formData) => { return await API.post("/MaterialCostLink/GetSingleData_MaterialCostLink", formData); };

export const get_Consumption_Drop_Data = async (formData) => { return await API.post("/MaterialConsumptionType/GetDataDropDown_MaterialConsumptionType", formData); };
export const get_Group_Drop_Data = async (formData) => { return await API.post("/MaterialConsumptionGroup/GetDataDropDown_MaterialConsumptionGroup", formData); };
export const get_Material_Group_Data = async (formData) => { return await API.post("/MaterialConsumptionGroupTypeLink/GetData_MaterialConsumptionGroupTypeLink", formData); };
export const save_Assign_Group_Data = async (formData) => { return await API.post("/MaterialConsumptionGroupTypeLink/InsertMaterialConsumptionGroupTypeLink", formData); };
export const delete_Assign_Group_Data = async (formData) => { return await API.post("/MaterialConsumptionGroupTypeLink/DeleteMaterialConsumptionGroupTypeLink", formData); };

// ---------------------------HR ? EmpStatus-----------------------
export const get_Status_Drop_Data = async (formData) => { return await API.post("/EmpStatus/GetDataDropDown_EmpStatus", formData); };

export const save_EmpStatus = async (formData) => { return await API.post("/EmpStatusHistory/InsertEmpStatusHistory", formData); };
export const get_Emp_Status_Data = async (formData) => { return await API.post("/EmpStatusHistory/GetData_EmpStatusHistory", formData); };
export const get_Single_EmpStatus_Data = async (formData) => { return await API.post("/EmpStatusHistory/GetSingleData_EmpStatusHistory", formData); };
export const update_EmpStatus = async (formData) => { return await API.post("/EmpStatusHistory/UpdateEmpStatusHistory", formData); };
export const delete_EmpStatus = async (formData) => { return await API.post("/EmpStatusHistory/DeleteEmpStatusHistory", formData); };

//  -------------------Emp Leave----------------------------
export const get_Emp_Leave_Data = async (formData) => { return await API.post("/EmpLeave/GetData_EmpLeave", formData); };
export const get_Leave_Status_Drop = async (formData) => { return await API.post("/EmpLeaveStatus/GetDataDropDown_EmpLeaveStatus", formData); };
export const get_Leave_Type_Drop = async (formData) => { return await API.post("/EmpLeaveType/GetDataDropDown_EmpLeaveType", formData); };
export const save_EmpLeave = async (formData) => { return await API.post("/EmpLeave/InsertEmpLeave", formData); };
export const update_EmpLeave = async (formData) => { return await API.post("/EmpLeave/UpdateEmpLeave", formData); };
export const get_Single_EmpLeave_Data = async (formData) => { return await API.post("/EmpLeave/GetSingleData_EmpLeave", formData); };
export const delete_EmpLeave = async (formData) => {
  return await API.post("/EmpLeave/DeleteEmpLeave", formData);
};
// ------------------------------------> | AdminSetting -> Opertor/Employee | <-------------------------------------
export const get_Department_Drop_Data = async (formData) => {
  return await API.post("/Department/GetDataDropDown_Department", formData);
};
export const get_Unit_Drop_Data = async (formData) => {
  return await API.post("/Unit/GetDataDropDown_Unit", formData);
};
export const get_Designation_Drop_Data = async (formData) => {
  return await API.post("/Designation/GetDataDropDown_Designation", formData);
};
export const get_BloodGroup_Drop_Data = async (formData) => {
  return await API.post("/BloodGroup/GetDataDropDown_BloodGroup", formData);
};
export const get_Martial_Drop_Data = async (formData) => {
  return await API.post("/Martial/GetDataDropDown_Martial", formData);
};
export const get_Religion_Drop_Data = async (formData) => {
  return await API.post("/Religion/GetDataDropDown_Religion", formData);
};
export const get_Gender_Drop_Data = async (formData) => {
  return await API.post("/Gender/GetDataDropDown_Gender", formData);
};
export const get_Relation_Drop_Data = async (formData) => {
  return await API.post("/Relation/GetDataDropDown_Relation", formData);
};
export const save_Employee = async (formData) => {
  return await API.post("/Employee/InsertEmployee", formData);
};
export const update_Employee = async (formData) => {
  return await API.post("/Employee/UpdateEmployee", formData);
};
export const update_Employee_Personal = async (formData) => {
  return await API.post("/Employee/UpdateEmployeePersonal", formData);
};
export const delete_Employee_Personal = async (formData) => {
  return await API.post("/Employee/DeleteEmployeePersonal", formData);
};
export const update_Employee_Address = async (formData) => {
  return await API.post("/Employee/UpdateEmployeeAddress", formData);
};
export const update_Employee_Bank = async (formData) => {
  return await API.post("/Employee/UpdateEmployeeBank", formData);
};
export const update_Employee_Family = async (formData) => {
  return await API.post("/Employee/UpdateEmployeeFamily", formData);
};
export const update_Employee_Education = async (formData) => {
  return await API.post("/Employee/UpdateEmployeeEducation", formData);
};
export const update_Employee_Eorganization = async (formData) => {
  return await API.post("/Employee/UpdateEmployeeorganization", formData);
};
export const get_Employee_Data = async (formData) => {
  return await API.post("/Employee/GetData_Employee", formData);
};
export const get_Single_Employee_Data = async (formData) => {
  return await API.post("/Employee/GetSingleData_Employee", formData);
};
export const delete_Employee = async (formData) => {
  return await API.post("/Employee/DeleteEmployee", formData);
};

// ------------------------------------> | AdminSetting -> Permission | <-------------------------------------
export const get_MenuPermission_Data = async (formData) => {
  return await API.post("/menu/GetData_menuPermission", formData);
};
export const update_MenuPermission_Data = async (formData) => {
  return await API.post("/menu/Update_menuPermission", formData);
};

// ------------------------------------> | Emp -> Task | <-------------------------------------

export const get_Task_Drop_Data = async (formData) => {
  return await API.post("/TaskType/GetData_TaskType", formData);
};
export const get_Single_Employee_Task_Data = async (formData) => {
  return await API.post("/EmpTask/GetSingleData_EmpTask", formData);
};
export const save_Employee_Task_Data = async (formData) => {
  return await API.post("/EmpTask/InsertEmpTask", formData);
};
export const update_Employee_Task_Data = async (formData) => {
  return await API.post("/EmpTask/UpdateEmpTask", formData);
};
export const get_Employee_Task_Data = async (formData) => {
  return await API.post("/EmpTask/GetData_EmpTask", formData);
};
export const get_Employee_Task_Detail_Data = async (formData) => {
  return await API.post("/EmpTaskDetail/GetDetailTaskDetail", formData);
};
export const save_Employee_Task_Detail_Data = async (formData) => {
  return await API.post("/EmpTaskDetail/InsertEmpTaskDetail", formData);
};
export const get_EmpCount_Task_Data = async (formData) => {
  return await API.post("/EmpTask/Count_EmpTask", formData);
};
export const get_Single_Count_Task_Data = async (formData) => {
  return await API.post("/EmpTask/SingleCount_EmpTask", formData);
};
export const get_EmpStatus_TaskData = async (formData) => {
  return await API.post("/EmpStatus/GetDataDropDown_TaskStatus", formData);
};
export const delete_EmpTask_Data = async (formData) => {
  return await API.post("/EmpTask/DeleteEmpTask", formData);
};

// ------------------------------------> | Emp -> Attendance Report | <-------------------------------------
export const get_Employee_Attendance_Data = async (formData) => {
  return await API.post("/EmpAttendance/Get_EmpAttendance", formData);
};

export const get_Attendance_Data = async (formData) => {
  return await API.post("/EmpAttendance/GetData_EmpAttendanceDaily", formData);
};

// -----------------------> Mining -> Pit Bank <------------------------------
export const get_Pit_Drop_Data = async (formData) => {
  return await API.post("/MiningPit/GetDataDropDown_MiningPit", formData);
};
export const get_Pit_Block_Data = async (formData) => {
  return await API.post("/MiningPitBench/GetData_MiningPitBench", formData);
};
export const save_Pit_Block_Data = async (formData) => {
  return await API.post("/MiningPitBench/InsertMiningPitBench", formData);
};
export const update_Pit_Block_Data = async (formData) => {
  return await API.post("/MiningPitBench/UpdateMiningPitBench", formData);
};
export const get_Single_Pit_Block_Data = async (formData) => {
  return await API.post(
    "/MiningPitBench/GetSingleData_MiningPitBench",
    formData
  );
};
export const delete_PitBlock_Data = async (formData) => {
  return await API.post("/MiningPitBench/DeleteMiningPitBench", formData);
};
export const get_ExlosiveType_Drop_Data = async (formData) => {
  return await API.post(
    "/MiningExplosiveType/GetDataDropDown_MiningExplosiveType",
    formData
  );
};
export const save_Explosive_Data = async (formData) => {
  return await API.post(
    "/MiningExplosiveRate/InsertMiningExplosiveRate",
    formData
  );
};
export const get_Explosive_Data = async (formData) => {
  return await API.post(
    "/MiningExplosiveRate/GetData_MiningExplosiveRate",
    formData
  );
};
export const get_Single_Explosive_Data = async (formData) => {
  return await API.post(
    "/MiningExplosiveRate/GetSingleData_MiningExplosiveRate",
    formData
  );
};
export const update_Explosive_Data = async (formData) => {
  return await API.post(
    "/MiningExplosiveRate/UpdateMiningExplosiveRate",
    formData
  );
};
export const delete_Explosive_Data = async (formData) => {
  return await API.post(
    "/MiningExplosiveRate/DeleteMiningExplosiveRate",
    formData
  );
};

// -----------------------> Mining ->Explosive  <------------------------------
export const get_MachineName_Drop_Data = async (formData) => {
  return await API.post(
    "/MiningMachineName/GetDataDropDown_MiningMachineName",
    formData
  );
};
export const save_Mining_Drilling_Data = async (formData) => {
  return await API.post("/MiningDrilling/InsertMiningDrilling", formData);
};
export const get_Mining_Drilling_Data = async (formData) => {
  return await API.post("/MiningDrilling/GetData_MiningDrilling", formData);
};
export const get_Single_Drilling_Data = async (formData) => {
  return await API.post(
    "/MiningDrilling/GetSingleData_MiningDrilling",
    formData
  );
};
export const update_Drilling_Data = async (formData) => {
  return await API.post("/MiningDrilling/UpdateMiningDrilling", formData);
};
export const delete_Mining_Drilling = async (formData) => {
  return await API.post("/MiningDrilling/DeleteMiningDrilling", formData);
};
export const get_Master_Table_Drop = async (formData, url) => {
  return await API.post(`/${url}/GetDataDropDown_${url}`, formData);
};

// -----------------------> Mining ->Blast  <---------------------------
export const get_BenchNo_Drop_Data = async (formData) => {
  return await API.post(
    "/MiningPitBench/GetDataDropDown_MiningPitBench",
    formData
  );
};
export const get_Site_Direction_Drop_Data = async (formData) => {
  return await API.post(
    "/MiningSiteDirection/GetDataDropDown_MiningSiteDirection",
    formData
  );
};
export const get_Blast_Entry_Data = async (formData) => {
  return await API.post("/MiningBlast/GetData_MiningBlast", formData);
};
export const save_Blast_Entry_Data = async (formData) => {
  return await API.post("/MiningBlast/InsertMiningBlast", formData);
};
export const get_Single_Blast_Data = async (formData) => {
  return await API.post("/MiningBlast/GetSingleData_MiningBlast", formData);
};
export const update_Blast_Data = async (formData) => {
  return await API.post("/MiningBlast/UpdateMiningBlast", formData);
};
export const delete_Blast_Data = async (formData) => {
  return await API.post("/MiningBlast/DeleteMiningBlast", formData);
};

// ----------------------> Plant - transport -> Meter Reading ---------------
export const get_Meter_Reading_Data = async (formData) => {
  return await API.post(
    "/PlantMeterReading/GetData_PlantMeterReading",
    formData
  );
};
export const save_Meter_Reading_Data = async (formData) => {
  return await API.post("/PlantMeterReading/InsertPlantMeterReading", formData);
};
export const update_Meter_Reading_Data = async (formData) => {
  return await API.post("/PlantMeterReading/UpdatePlantMeterReading", formData);
};
export const get_Single_Meter_Reading_Data = async (formData) => {
  return await API.post(
    "/PlantMeterReading/GetSingleData_PlantMeterReading",
    formData
  );
};
export const delete_Meter_Reading_Data = async (formData) => {
  return await API.post("/PlantMeterReading/DeletePlantMeterReading", formData);
};

// ----------------------> Plant - transport -> Meter Reading ---------------
export const get_Consumption_Power_Data = async (formData) => {
  return await API.post("/PlantPowerConsumption/GetData_PlantPowerConsumption", formData);
};
export const save_Consumption_Power_Data = async (formData) => {
  return await API.post(
    "/PlantPowerConsumption/InsertPlantPowerConsumption",
    formData
  );
};
export const update_Consumption_Power_Data = async (formData) => {
  return await API.post(
    "/PlantPowerConsumption/UpdatePlantPowerConsumption",
    formData
  );
};
export const get_Single_Consumption_Power_Data = async (formData) => {
  return await API.post(
    "/PlantPowerConsumption/GetSingleData_PlantPowerConsumption",
    formData
  );
};
export const delete_Consumption_Power_Data = async (formData) => {
  return await API.post(
    "/PlantPowerConsumption/DeletePlantPowerConsumption",
    formData
  );
};

// ----------------------> Inventory -> Add request ------------>
export const save_Add_Request_Data = async (formData) => {
  return await API.post("/Request/InsertRequest", formData);
};
export const get_AddRequest_Data = async (formData) => {
  return await API.post("/Request/GetData_Request", formData);
};
export const get_Single_Request_Data = async (formData) => {
  return await API.post("/Request/GetSingleData_Request", formData);
};
export const update_Add_Request_Data = async (formData) => {
  return await API.post("/Request/UpdateRequest", formData);
};
// -------------------->Request ----------
export const save_Request_List_Data = async (formData) => {
  return await API.post("/RequestDetail/InsertRequestDetail", formData);
};
export const get_Request_List_Data = async (formData) => {
  return await API.post("/RequestDetail/GetData_RequestDetail", formData);
};
export const update_Request_List_Data = async (formData) => {
  return await API.post("/RequestDetail/UpdateRequestDetail", formData);
};

export const get_Single_RequestList_Data = async (formData) => {
  return await API.post("/RequestDetail/GetSingleData_RequestDetail", formData);
};
export const delete_RequestItem_Data = async (formData) => {
  return await API.post("/RequestDetail/DeleteRequestDetail", formData);
};
export const delete_Add_Request_Data = async (formData) => {
  return await API.post("/Request/DeleteRequest", formData);
};
// ------------------------Request Approve ----------
export const set_Approve_Request_Data = async (formData) => {
  return await API.post("/Request/SetApprove_Request", formData);
};
export const set_UnApprove_Request_Data = async (formData) => {
  return await API.post("/Request/SetdeApprove_Request", formData);
};
// ----------------------Quotes Enter ---------------
export const save_Quotes_Enter_Data = async (formData) => {
  return await API.post("/quotes/Insertquotes", formData);
};
export const get_Quotes_Enter_Data = async (formData) => {
  return await API.post("/quotes/GetData_quotes", formData);
};
export const update_Quotes_Enter_Data = async (formData) => {
  return await API.post("/quotes/Updatequotes", formData);
};
export const get_Single_Quotes_Enter_Data = async (formData) => {
  return await API.post("/quotes/GetSingleData_quotes", formData);
};
export const get_Receivedby_DropData = async (formData) => {
  return await API.post("/ReceivedBy/GetDataDropDown_ReceivedBy", formData);
};
export const get_supplier_DropData = async (formData) => {
  return await API.post("/Party/GetDataDropDown_Party", formData);
};
export const get_Discount_DropData = async (formData) => {
  return await API.post("/DiscountOn/GetDataDropDown_DiscountOn", formData);
};
export const get_Gst_DropData = async (formData) => {
  return await API.post("/GSTSlabs/GetDataDropDown_GSTSlabs", formData);
};
export const get_Approve_Request_Data = async (formData) => {
  return await API.post("/Request/GetUnApprove_Request", formData);
};
export const get_UnApprove_Request_Data = async (formData) => {
  return await API.post("/Request/GetApprove_Request", formData);
};
export const get_DeApprove_Request_Data = async (formData) => {
  return await API.post("/Request/GetDeApprove_Request", formData);
};

export const set_Deapprove_Request_Data = async (formData) => {
  return await API.post("/Request/SetdeApprove_Request", formData);
};
export const set_Reject_Request_Data = async (formData) => { return await API.post("/Request/SetReject_Request", formData); };
export const delete_Quotes_Enter_Data = async (formData) => { return await API.post("/quotes/Deletequotes", formData); };
// --------------------Approve Quotes--------------
export const get_Approve_Quotes_Data = async (formData) => { return await API.post("/quotes/GetData_quotes", formData); };
export const set_Approve_Quotes_Data = async (formData) => { return await API.post("/quotes/SetApprove_quotes", formData); };
// -------------------Inventory---------------
export const get_MaterialGroup_DrpData = async (formData) => { return await API.post("/MaterialGroup/GetDataDropDown_MaterialGroup", formData); };
export const get_MaterialType_DrpData = async (formData) => { return await API.post("/MaterialType/GetDataDropDown_MaterialType", formData); };
export const save_Inventory_Material_Data = async (formData) => { return await API.post("/InventoryMaterial/InsertInventoryMaterial", formData); };
export const get_Inventory_Material_Data = async (formData) => { return await API.post("/InventoryMaterial/GetData_InventoryMaterial", formData); };
export const update_Inventory_Material_Data = async (formData) => { return await API.post("/InventoryMaterial/UpdateInventoryMaterial", formData); };
export const get_Single_Inventory_Material_Data = async (formData) => { return await API.post("/InventoryMaterial/GetSingleData_InventoryMaterial", formData); };
export const delete_Inventory_Material_Data = async (formData) => { return await API.post("/InventoryMaterial/DeleteInventoryMaterial", formData); };
export const get_Dashboard_Chart_Data = async (formData, url) => { return await API.post(url, formData); };
export const get_Dashboard_Task_Data = async (formData) => { return await API.post("/Dashboard/GetData_DashboardMyTask", formData); };

// ------------------------Inventory -> purchanse invoice-----------
export const get_InvoiceType_DropData = async (formData) => { return await API.post("/InvoiceType/GetDataDropDown_InvoiceType", formData); };
export const save_InvoiceType_Data = async (formData) => { return await API.post("/InvPurchaseInvoice/InsertInvPurchaseInvoice", formData); };
export const update_InvoiceType_Data = async (formData) => { return await API.post("/InvPurchaseInvoice/UpdateInvPurchaseInvoice", formData); };
export const get_Purchase_Invoice_Data = async (formData) => { return await API.post("/InvPurchaseInvoice/GetData_InvPurchaseInvoice", formData); };
export const get_Single_Purchase_Invoice_Data = async (formData) => { return await API.post("/InvPurchaseInvoice/GetSingleData_InvPurchaseInvoice", formData); };
export const delete_InvoiceType_Data = async (formData) => { return await API.post("/InvPurchaseInvoice/DeleteInvPurchaseInvoice", formData); };

// ---------------Inventory -> purchase invoice -> Add Item ---------
export const save_AddItem_Data = async (formData) => { return await API.post("/InvPurchaseInvoiceDetail/InsertPurchaseInvoiceDetail", formData); };
export const update_AddItem_Data = async (formData) => { return await API.post("/InvPurchaseInvoiceDetail/UpdatePurchaseInvoiceDetail", formData); };
export const get_AddItem_Data = async (formData) => { return await API.post("/InvPurchaseInvoiceDetail/GetData_PurchaseInvoiceDetail", formData); };
export const get_Single_AddItem_Data = async (formData) => { return await API.post("/InvPurchaseInvoiceDetail/GetSingleData_PurchaseInvoiceDetail", formData); };
export const delete_ItemDetails_Data = async (formData) => { return await API.post("/InvPurchaseInvoiceDetail/DeletePurchaseInvoiceDetail", formData); };

// -----------------Admin Setting > Phone Sms -----
export const get_PhoneSms_Data = async (formData) => { return await API.post("/PhoneSMS/GetData_PhoneSMS", formData); };
export const get_Single_PhoneSms_Data = async (formData) => { return await API.post("/PhoneSMS/GetSingleData_PhoneSMS", formData); };
export const update_PhoneSms_Data = async (formData) => { return await API.post("/PhoneSMS/UpdatePhoneSMS", formData); };
export const delete_PhoneSms_Data = async (formData) => { return await API.post("/PhoneSMS/DeletePhoneSMS", formData); };
export const save_Menu_Data = async (formData) => { return await API.post("/menu/InsertMenu", formData); };
export const get_SubMenu_Data = async (formData) => { return await API.post("/menu/GetSubMenubyID", formData); };
export const update_Menu_Data = async (formData) => { return await API.post("/menu/UpdateMenu", formData); };
export const delete_Menu_Data = async (formData) => { return await API.post("/menu/deleteMenu", formData); };

//----------------------------Service >SerivceConfiguration------------------------------
export const save_Service_Data = async (formData) => { return await API.post("/Serviceconfiguration/InsertServiceconfiguration", formData); };
export const update_Service_Data = async (formData) => { return await API.post("/Serviceconfiguration/UpdateServiceconfiguration", formData); };
export const get_ServiceConfiguration_Dropdown = async (formData) => { return await API.post("/Party/GetDataDropDown_PartyLedger", formData); };
export const get_ServiceConfiguration_Data = async (formData) => { return await API.post("/Serviceconfiguration/GetData_Serviceconfiguration", formData); };
export const delete_ServiceConfiguration_Data = async (formData) => { return await API.post("/Serviceconfiguration/DeleteServiceconfiguration", formData); };
export const get_Single_ServiceConfiguration_Data = async (formData) => { return await API.post("/Serviceconfiguration/GetSingleDataServiceconfiguration", formData); };

//-----------------------------Service>Mantinance----------------------------
export const save_Mantinance_Data = async (formData) => { return await API.post("/DieselReading/InsertDieselReading", formData); };
export const get_Mantinance_Data = async (formData) => { return await API.post("/DieselReading/GetData_DieselReading", formData); };
export const update_Mantinance_Data = async (formData) => { return await API.post("/DieselReading/UpdateDieselReading", formData); };
export const get_Single_Mantinance_Data = async (formData) => { return await API.post("/DieselReading/GetSingleDataDieselReading", formData); };
export const get_Mantinance_Dropdown = async (formData) => { return await API.post("/Party/GetDataDropDown_PartyLedger", formData); };
export const delete_Mantinance_Data = async (formData) => { return await API.post("/DieselReading/DeleteDieselReading", formData); };

//-------------------------------------Dashboard_Chart---------------------------------------------

export const get_SalebyProduct_Chart_Data = async (formData) => { return await API2.post("/Dashboard/GetSalebyProduct", formData); };
export const get_SalebyCash_Chart_Data = async (formData) => { return await API2.post("/Dashboard/GetCashSalebyDate", formData); };
export const get_SalebyUdhar_Chart_Data = async (formData) => { return await API2.post("/Dashboard/GetUdharSalebyDate", formData); };




































































































