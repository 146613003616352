import React, { useState } from 'react'
import Header from './Component/Include/Headers/Header'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import SideNavbar from './Component/Include/Headers/Sidebar/SideNavbar'
import { Col, Container, Row } from 'react-bootstrap'

const Auth = (props) => {

    const { cmp, path, InventrySideBar, DashBoardSideBar, hrSideBar } = props

    const Cmp = cmp
    // const navigate = useNavigate()

    useEffect(() => {
        // if (!sessionStorage.getItem('userId')) navigate('/')
    }, [])

    return (
        <>
            {/* <div className='row'>
                <Header />
                <div className='col-md-2' style={{ height: '100vh', width: '10%', borderRight: "2px solid rgb(56, 146, 146)", }}>
                    <SideNavbar {...{ InventrySideBar, DashBoardSideBar, hrSideBar }} />
                </div>
                <div className='col-md-10' style={{ width: '90%',  }}>
                    <Cmp />
                </div>
            </div> */}


            <Header />
            <Container fluid>
            {/* p-0 m-0 */}
                <Row className="g-1">
                    <Col md={2} className=" vh-100 " style={{width: '13%', paddingLeft: '11px', paddingRight: '0px', }}>
                    <SideNavbar {...{ InventrySideBar, DashBoardSideBar, hrSideBar }} />
                    </Col>
                    <Col md={10} className="px-3 main_content" style={{width: '87%', }}>
                    <div className="bg-light_shadow  m-auto">
                    <Cmp />
                    </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Auth