// -------------------Praveen Tailor ------------------

import { Decrypt_Id_Name } from "../../Component/Include/Common/Utility";
import {
  Get_Menu,
  Get_Url,
  Get_Master_Table_Data,
  MasterTable_Page_Loader,
  Get_Single_Master_Table_Data,
  Edit_Update_Count,
  MasterTable_Modal_Status,
  Get_Master_Table_Filter_Data,
  Get_Error_Status,
  Party_City,
  Party_State,
  Get_Single_Company_Data,
  Search_challan_Data,
  Get_Single_Party_Data,
  Get_Party_List_Data,
  Contact_city,
  Get_Product_Dropdown,
  Get_Product_Data,
  Get_Single_Product_Data,
  Get_EquipmentVehicle_Data,
  Get_Single_Vechicle_Data,
  Update_Status,
  Get_EquipmentInsurance_Data,
  Get_Single_Insurance_Data,
  Get_EquipmentRto_Data,
  Get_Single_Rto_Data,
  Get_EquipmentPuc_Data,
  Get_Single_Puc_Data,
  Get_EquipmentFitness_Data,
  Get_Single_Fitness_Data,
  Get_Service_Dropdown,
  Get_Single_PlantService_Data,
  Get_PlantService_Data,
  Get_EquipmentVehicle_DropDown,
  Get_Nature_Dropdown,
  Get_CostHead_Dropdown,
  Get_CostSubHead_Dropdown,
  Get_Center_Dropdown,
  Get_CostHead_Data,
  Page_Status,
  Get_Single_CostHead_Data,
  Get_Vechicle_ID,
  Get_EmpLeave_Data,
  Get_StatusLeave_Drop,
  Get_TypeLeave_Drop,
  Get_Single_EmpLeave_Data,

  // ------------------------------------> | AdminSetting -> Opertor/Employee | <-------------------------------------
  Get_Department_Dropdown,
  Unit_Dropdown,
  Get_Designation_Dropdown,
  Get_Employee_Data,
  Employee_ID,
  Get_Unit_DropDown,
  Get_Single_Employee_Data,
  Get_BloodGroup_Dropdown,
  Get_Martial_Dropdown,
  Get_Religion_Dropdown,
  Get_Gender_Dropdown,
  Get_PermanentDistrict_Data,
  Get_State_Data,
  Get_LocalDistrict_Data,
  Get_Relation_Dropdown,
  Get_AssignGroup_Data,
  Get_Group_Dropdown,
  Get_Consumption_Dropdown,
  Sidebar_Status,
  Status,
  // ------------------------------------> | AdminSetting -> Permission | <-------------------------------------
  Get_Menu_Permission_Data,
  Permission_UserId,
  Get_Status_DropDown,
  Get_EmpStatus_Data,
  Get_EmpId,
  Get_Single_EmpStatus_Data,
  Get_Employee_Filter_Data,
  Get_TaskType_Dropdown,
  Get_Emp_Task_Data,
  Edit_Id,
  Get_Emp_Task_Detail_Data,
  Get_Single_Emp_Task_Data,
  Replay_Status,
  Get_Emp_Attendance,
  Get_Attendance,
  // -----------------------> Mining -> Pit  <------------------------------
  Get_PitBlock_Dropdown,
  Get_PitBlock_Data,
  Get_Single_PitBlock_Data,
  // -----------------------> Mining ->Explosive  <------------------------------
  Get_Machine_Name_Dropdown,
  Get_Drilling_Data,
  Get_Single_Drilling_Data,
  Sub_Tab_Name,
  Modal_Status,
  Page_Loader,
  Get_Drilling_Filter_Data,

  // -----------------------> Mining ->Blast  <---------------------------
  Get_BenchNo_Dropdown,
  Get_Site_Direction_Dropdown,
  Get_Blast_Entry_Data,
  Get_ExplosiveType_Dropdown,
  Get_Explosive_Data,
  Get_Single_Explosive_Data,
  Get_Single_Blast__Data,
  Get_PitBlock_Filter_Data,
  Get_Explosive_Filter_Data,
  Get_Blast_Filter_Data,
  Get_Year,
  Get_Month,
  Get_MeterReading_Data,
  Get_Single_MeterReading_Data,
  Get_Meter_Filter_Data,
  Get_Consumption_Power_Data,
  Get_Consumption_Power_Filter_Data,
  Get_Single_Consumption_Power_Data,
  Get_EmpLeave_Filter_Data,
  Get_EmpStatus_Filter_Data,
  Get_Chart_Data,
  Get_Task_Data,
  Get_RequestBy_DropDown,
  Get_AddRequest_Data,
  Get_RequestList_Data,
  Get_PurchaseChart_Data,
  Get_Single_Request_Data,
  Get_Single_RequestList_Data,
  Get_Approve_Request,
  Get_Quotes_Data,
  Get_Single_Quotes_Data,
  Get_Receivedby_DropData,
  Get_Supplier_DropData,
  Get_Req_Id,
  Get_MaterialGroup_DropData,
  Get_MaterialType_DropData,
  Get_Inventory_Material_Data,
  Get_Single_Inventory_Data,
  Get_Inventory_Material_Filter_Data,
  Get_Approve_Quotes,
  Get_DiscountType_DropData,
  Get_UnApprove_Request,
  Get_DeApprove_Request,
  Get_Gst_DropData,
  Get_SubMenu_Data,
  Get_ChildMenu_Data,
  Get_Invoice_Type_DropData,
  Get_PurchaseInvoice_Data,
  Get_Single_PurchaseInvoice_Data,
  Get_AddItem_Data,
  Get_Single_AddItem_Data,
  Get_Invoice_ID,
  Get_Invoice_Filter_Data,
  Get_Quotes_Id,
  Check_Page,
  Get_PhoneSms_Data,
  Get_Single_PhoneSms_Data,
  Get_EmpCount_Task_Data,
  Get_SingleCount_Data,
  Get_Emp_Task_ID,
  Get_Emp_ID,
  Get_Status_ID,
  Get_Filter_PhoneSms_Data,
  Get_EmpStatus_TaskType_Dropdown,
  Get_ServiceConfiguration_Dropdown,
  Get_ServiceConfiguration_Data,
  Get_Filter_ServiceConfiguration_Data,
  Get_Single_ServiceConfiguration_Data,
  Get_Mantinance_Data,
  Get_Mantinance_Dropdown,
  Get_Filter_Mantinance_Data,
  Get_Single_Mantinance_Data,
  Get_SalebyProduct_Chart_Data,
  Get_SalebybyCash_Chart_Data,
  Get_SalebyUdhar_Chart_Data,


































  //---------------------------------------------------------Banti--------------------------------------------





















































  //---------------------------------------------------------Banti--------------------------------------------


















} from "../actionTypes";

const initialState = {
  // Header
  menuData: [],
  apiUrl: "",
  userId: sessionStorage.getItem("userId") && Decrypt_Id_Name(sessionStorage.getItem("userId"), "UForUserID"),
  companyId: sessionStorage.getItem("CompanyId") && sessionStorage.getItem("userId") && Decrypt_Id_Name(sessionStorage.getItem("CompanyId"), "CForCompanyID"),
  ID: sessionStorage.getItem("ID") && Decrypt_Id_Name(sessionStorage.getItem("ID")),
  // ----------------------- Master Table -----------------
  singleMasterTableData: [],
  masterTableData: [],
  masterTableFilterData: [],
  editUpdateCount: 0,
  masterTableLoader: Boolean,
  updateStatus: Boolean,
  masterTableModalStatus: Boolean,
  errorStatus: Boolean,

  // Admin Setting
  companySingelData: [],
  searchChallanData: [],
  partySingleData: [],
  partySaleData: [],
  State: [],
  City: [],
  contactCity: [],
  productDropdown: [],
  unitDropDown: [],
  productData: [],
  singleproductData: [],
  singlevehicleData: [],
  singleInsuranceData: [],
  equipmentRtoData: [],
  equipmentPucData: [],
  singleRtoData: [],
  singleFitnessData: [],
  singlePucData: [],
  serviceDropdown: [],
  plantServiceData: [],
  equipmentFitnessData: [],
  singleServiceData: [],
  equipmentVehicleDropDown: [],
  centerDropdown: [],
  natureDropdown: [],
  subheadDropdown: [],
  costheadDropdown: [],
  costheadData: [],
  pageStatus: false,
  singlecostheadData: [],
  vechicleID: "",
  ConsumptionID: "",
  GroupID: "",
  statusDropDown: [],
  empStatusData: [],
  empId: "",
  singleEmpStatusData: [],
  empLeaveData: [],
  leaveStatusDropDown: [],
  singleEmpLeaveData: [],
  empLeaveFilterData: [],
  empStatusFilterData: [],
  serviceConfigurationDropdown: [],
  mantinanceDropdown: [],

  // ------------------------------------> | AdminSetting -> Opertor/Employee | <-------------------------------------
  Status: false,
  sidebarStatus: false,
  departmentDropData: [],
  unitDropData: [],
  designationDropData: [],
  employeeData: [],
  employeeSingleData: [],
  bloodGroupDropData: [],
  martialDropData: [],
  religionDropData: [],
  genderDropData: [],
  permanentCityData: [],
  State: [],
  localCityData: [],
  relationDropData: [],
  employeeFilterData: [],

  // ------------------------------------> | AdminSetting -> Permission | <-------------------------------------
  menuPermissionData: [],
  permissionUserId: "",
  // ------------------------------------> | Emp -> Task | <-------------------------------------
  taskTypeDropData: [],
  empTaskData: [],
  editId: "",
  empTaskDetailData: [],
  singleEmpTaskData: [],
  empCountTaskData: [],
  singleTaskCountData: [],
  empTaskDropDown: [],
  replayStatus: false,
  taskID: "",
  // ------------------------------------> | Emp -> Attendance Report | <-------------------------------------
  empAttendanceData: [],
  attendanceData: [],
  attendanceMonth: "",
  attendanceYear: "",
  // ---------------------------------> Mining | pitBlock | <------------------------------
  pitblockDropdata: [],
  pitblockData: [],
  singlePitBlockData: [],
  exlosiveTypeDropData: [],
  explosiveData: [],
  singleExplosiveData: [],
  pitblockFilterData: [],
  explosiveFilterData: [],

  // -----------------------> Mining ->Explosive  <------------------------------
  machineNameData: [],
  drillingData: [],
  singleDrillingData: [],
  subTabName: "",
  modalStatus: false,
  pageLoader: false,
  drillingFilterData: [],

  // -----------------------> Mining ->Blast  <---------------------------
  benchNoData: [],
  siteDirectionDrpData: [],
  blastEntryData: [],
  singleBlastData: [],
  blastFilterData: [],
  meterReadingData: [],
  singleMeterReadingData: [],
  meterFilterData: [],
  consumptionData: [],
  consumptionFilterData: [],
  singleConsumptionData: [],
  chartData: [],
  taskData: [],

  // ----------------------> Inventory -> Add request <-----------------
  requestbyData: [],
  requestData: [],
  requestListData: [],
  purchaseChartData: [],
  singleRequestData: [],
  singleRequestListData: [],
  approveRequest: [],
  quotesData: [],
  singlequotesData: [],
  receivedbyDropData: [],
  supplierDropData: [],
  reqID: "",
  materialgroupDropDown: [],
  materialtypeDropDown: [],
  inventoryFilterData: [],
  approveQuotes: [],
  discountDropData: [],
  unapproveRequest: [],
  rejectRequestData: [],
  gstSlabsDropData: [],
  subMenuData: [],
  childMenuData: [],
  invoiceDropData: [],
  purchaseInvoiceData: [],
  singleInvoiceData: [],
  addItemData: [],
  singleAddItemData: [],
  invoiceID: "",
  invoiceFilterData: [],
  quoteId: "",
  phoneSmsData: [],
  singlePhoneSmsData: [],
  checkPage: false,
  empID: "",
  statusID: "",
  filterPhoneSmsData: "",
  //---------------------chartData-----------------
  salabyProduct: [], salabyCash: [], salabyUdhar: []
};

const AllActionReducer = (state = initialState, action) => {
  switch (action.type) {
    //chart
    case Get_SalebyProduct_Chart_Data:
      return { ...state, salabyProduct: action.payload };
    case Get_SalebybyCash_Chart_Data:
      return { ...state, salabyCash: action.payload };
    case Get_SalebyUdhar_Chart_Data:
      return { ...state, salabyUdhar: action.payload };
    // All
    case Check_Page:
      return { ...state, checkPage: action.payload };
    case Edit_Update_Count:
      return { ...state, editUpdateCount: action.payload };
    case Edit_Id:
      return { ...state, editId: action.payload };
    case Page_Status:
      return { ...state, pageStatus: action.payload };
    case Get_Error_Status:
      return { ...state, errorStatus: action.payload };
    // Header
    case Get_Menu:
      return { ...state, menuData: action.payload };
    case Get_SubMenu_Data:
      return { ...state, subMenuData: action.payload };
    case Get_ChildMenu_Data:
      return { ...state, childMenuData: action.payload };
    case Get_Url:
      return { ...state, apiUrl: action.payload };
    // ----------------------- Master Table -----------------
    case Get_Master_Table_Data:
      return { ...state, masterTableData: action.payload };
    case Get_Master_Table_Filter_Data:
      return { ...state, masterTableFilterData: action.payload };
    case MasterTable_Modal_Status:
      return { ...state, masterTableModalStatus: action.payload };
    case Get_Single_Master_Table_Data:
      return { ...state, singleMasterTableData: action.payload };
    case MasterTable_Page_Loader:
      return { ...state, masterTableLoader: action.payload };
    // ----------------------- Admin Setting -----------------
    case Get_Single_Company_Data:
      return { ...state, companySingelData: action.payload };

    // -----------search Challan -------
    case Search_challan_Data:
      return { ...state, searchChallanData: action.payload };
    // ----------------------- Admin Setting -----------------
    case Get_Single_Party_Data:
      return { ...state, partySingleData: action.payload };
    case Get_Party_List_Data:
      return { ...state, partySaleData: action.payload };
    case Party_State:
      return { ...state, State: action.payload };
    case Party_City:
      return { ...state, City: action.payload };

    case Contact_city:
      return { ...state, contactCity: action.payload };
    // product daata
    case Get_Product_Dropdown:
      return { ...state, productDropdown: action.payload };
    case Get_Unit_DropDown:
      return { ...state, unitDropdown: action.payload };
    case Get_Product_Data:
      return { ...state, productData: action.payload };
    case Get_Single_Product_Data:
      return { ...state, singleproductData: action.payload };
    // Vechicle details
    case Get_Vechicle_ID:
      return { ...state, vechicleID: action.payload };
    case Get_EquipmentVehicle_Data:
      return { ...state, equipmentVehicleData: action.payload };
    case Get_Single_Vechicle_Data:
      return { ...state, singlevehicleData: action.payload };
    case Update_Status:
      return { ...state, updateStatus: action.payload };
    // Insurance Details
    case Get_EquipmentInsurance_Data:
      return { ...state, insuranceData: action.payload };
    case Get_Single_Insurance_Data:
      return { ...state, singleInsuranceData: action.payload };
    case Get_EquipmentVehicle_DropDown:
      return { ...state, equipmentVehicleDropDown: action.payload };
    // Rto Details
    case Get_EquipmentRto_Data:
      return { ...state, equipmentRtoData: action.payload };
    case Get_Single_Rto_Data:
      return { ...state, singleRtoData: action.payload };
    // Fitness Details
    case Get_EquipmentFitness_Data:
      return { ...state, equipmentFitnessData: action.payload };
    case Get_Single_Fitness_Data:
      return { ...state, singleFitnessData: action.payload };
    // PUC Details
    case Get_EquipmentPuc_Data:
      return { ...state, equipmentPucData: action.payload };
    case Get_Single_Puc_Data:
      return { ...state, singlePucData: action.payload };
    // Plant Data
    case Get_Service_Dropdown:
      return { ...state, serviceDropdown: action.payload };
    case Get_PlantService_Data:
      return { ...state, plantServiceData: action.payload };
    case Get_Single_PlantService_Data:
      return { ...state, singleServiceData: action.payload };
    // Cost Head Data
    case Get_Nature_Dropdown:
      return { ...state, natureDropdown: action.payload };
    case Get_CostHead_Dropdown:
      return { ...state, costheadDropdown: action.payload };
    case Get_CostSubHead_Dropdown:
      return { ...state, subheadDropdown: action.payload };
    case Get_Center_Dropdown:
      return { ...state, centerDropdown: action.payload };
    case Get_CostHead_Data:
      return { ...state, costheadData: action.payload };
    case Get_Single_CostHead_Data:
      return { ...state, singlecostheadData: action.payload };
    case Get_Consumption_Dropdown:
      return { ...state, consumptionDropDown: action.payload };
    case Get_Group_Dropdown:
      return { ...state, groupDropDown: action.payload };
    case Get_AssignGroup_Data:
      return { ...state, assignGroupData: action.payload };

    // -----------------hr ?> empStatus----------------
    case Get_Status_DropDown:
      return { ...state, statusDropDown: action.payload };
    case Get_EmpStatus_Data:
      return { ...state, empStatusData: action.payload };
    case Get_EmpId:
      return { ...state, empId: action.payload };
    case Get_Single_EmpStatus_Data:
      return { ...state, singleEmpStatusData: action.payload };
    case Get_EmpStatus_Filter_Data:
      return { ...state, empStatusFilterData: action.payload };
    //  ----------------------emp leave ----------------
    case Get_EmpLeave_Data:
      return { ...state, empLeaveData: action.payload };
    case Get_StatusLeave_Drop:
      return { ...state, leaveStatusDropDown: action.payload };
    case Get_TypeLeave_Drop:
      return { ...state, leaveTypeDropDown: action.payload };
    case Get_Single_EmpLeave_Data:
      return { ...state, singleEmpLeaveData: action.payload };
    case Get_EmpLeave_Filter_Data:
      return { ...state, empLeaveFilterData: action.payload };

    // ------------------------------------> | AdminSetting -> Opertor/Employee | <-------------------------------------

    case Status:
      return { ...state, Status: action.payload };
    case Sidebar_Status:
      return { ...state, sidebarStatus: action.payload };
    case Get_Department_Dropdown:
      return { ...state, departmentDropData: action.payload };
    case Unit_Dropdown:
      return { ...state, unitDropData: action.payload };
    case Get_Designation_Dropdown:
      return { ...state, designationDropData: action.payload };
    case Get_Employee_Data:
      return { ...state, employeeData: action.payload };
    case Get_Employee_Filter_Data:
      return { ...state, employeeFilterData: action.payload };
    case Employee_ID:
      return { ...state, employeeId: action.payload };
    case Get_Single_Employee_Data:
      return { ...state, employeeSingleData: action.payload };
    case Get_BloodGroup_Dropdown:
      return { ...state, bloodGroupDropData: action.payload };
    case Get_Martial_Dropdown:
      return { ...state, martialDropData: action.payload };
    case Get_Religion_Dropdown:
      return { ...state, religionDropData: action.payload };
    case Get_Gender_Dropdown:
      return { ...state, genderDropData: action.payload };
    case Get_LocalDistrict_Data:
      return { ...state, localCityData: action.payload };
    case Get_PermanentDistrict_Data:
      return { ...state, permanentCityData: action.payload };
    case Get_State_Data:
      return { ...state, State: action.payload };
    case Get_Relation_Dropdown:
      return { ...state, relationDropData: action.payload };
    // ------------------------------------> | AdminSetting -> Permission | <-------------------------------------
    case Get_Menu_Permission_Data:
      return { ...state, menuPermissionData: action.payload };
    case Permission_UserId:
      return { ...state, permissionUserId: action.payload };
    // ------------------------------------> | Emp -> Task | <-------------------------------------
    case Get_TaskType_Dropdown:
      return { ...state, taskTypeDropData: action.payload };
    case Get_Emp_Task_Data:
      return { ...state, empTaskData: action.payload };
    case Get_Emp_Task_Detail_Data:
      return { ...state, empTaskDetailData: action.payload };
    case Get_Single_Emp_Task_Data:
      return { ...state, singleEmpTaskData: action.payload };
    case Get_EmpCount_Task_Data:
      return { ...state, empCountTaskData: action.payload };
    case Get_SingleCount_Data:
      return { ...state, singleTaskCountData: action.payload };
    case Replay_Status:
      return { ...state, replayStatus: action.payload };
    case Get_Emp_Task_ID:
      return { ...state, taskID: action.payload };
    case Get_Emp_ID:
      return { ...state, empID: action.payload };
    case Get_Status_ID:
      return { ...state, statusID: action.payload };
    case Get_EmpStatus_TaskType_Dropdown:
      return { ...state, empTaskDropDown: action.payload };
    // ------------------------------------> | Emp -> Attendance Report | <-------------------------------------
    case Get_Emp_Attendance:
      return { ...state, empAttendanceData: action.payload };
    case Get_Attendance:
      return { ...state, attendanceData: action.payload };
    case Get_Month:
      return { ...state, attendanceMonth: action.payload };
    case Get_Year:
      return { ...state, attendanceYear: action.payload };
    // --------------------------------------> | Mining -> pit block | <----------------------------------
    case Get_PitBlock_Dropdown:
      return { ...state, pitblockDropdata: action.payload };
    case Get_PitBlock_Data:
      return { ...state, pitblockData: action.payload };
    case Get_Single_PitBlock_Data:
      return { ...state, singlePitBlockData: action.payload };
    case Get_ExplosiveType_Dropdown:
      return { ...state, exlosiveTypeDropData: action.payload };
    case Get_Explosive_Data:
      return { ...state, explosiveData: action.payload };
    case Get_PitBlock_Filter_Data:
      return { ...state, pitblockFilterData: action.payload };
    // -----------------------> Mining ->Explosive  <------------------------------
    case Get_Machine_Name_Dropdown:
      return { ...state, machineNameData: action.payload };
    case Get_Drilling_Data:
      return { ...state, drillingData: action.payload };
    case Get_Single_Drilling_Data:
      return { ...state, singleDrillingData: action.payload };
    case Sub_Tab_Name:
      return { ...state, subTabName: action.payload };
    case Modal_Status:
      return { ...state, modalStatus: action.payload };
    case Page_Loader:
      return { ...state, pageLoader: action.payload };
    case Get_Drilling_Filter_Data:
      return { ...state, drillingFilterData: action.payload };

    // -----------------------> Mining ->Blast  <---------------------------
    case Get_BenchNo_Dropdown:
      return { ...state, benchNoData: action.payload };
    case Get_Site_Direction_Dropdown:
      return { ...state, siteDirectionDrpData: action.payload };
    case Get_Blast_Entry_Data:
      return { ...state, blastEntryData: action.payload };
    case Get_Single_Blast__Data:
      return { ...state, singleBlastData: action.payload };
    case Get_Single_Explosive_Data:
      return { ...state, singleExplosiveData: action.payload };
    case Get_Explosive_Filter_Data:
      return { ...state, explosiveFilterData: action.payload };
    case Get_Blast_Filter_Data:
      return { ...state, blastFilterData: action.payload };
    // ----------------------> Plant - transport -> Meter Reading ---------------
    case Get_MeterReading_Data:
      return { ...state, meterReadingData: action.payload };
    case Get_Single_MeterReading_Data:
      return { ...state, singleMeterReadingData: action.payload };

    case Get_Meter_Filter_Data:
      return { ...state, meterFilterData: action.payload };

    // ----------------------> Plant - transport -> Consuption Power ---------------
    case Get_Consumption_Power_Data:
      return { ...state, consumptionData: action.payload };
    case Get_Consumption_Power_Filter_Data:
      return { ...state, consumptionFilterData: action.payload };

    case Get_Single_Consumption_Power_Data:
      return { ...state, singleConsumptionData: action.payload };

    case Get_Chart_Data:
      return { ...state, chartData: action.payload };
    case Get_PurchaseChart_Data:
      return { ...state, purchaseChartData: action.payload };
    case Get_Task_Data:
      return { ...state, taskData: action.payload };

    // --------------------------------Inventory -> Request -------------
    case Get_RequestBy_DropDown:
      return { ...state, requestbyData: action.payload };
    case Get_AddRequest_Data:
      return { ...state, requestData: action.payload };
    case Get_Single_Request_Data:
      return { ...state, singleRequestData: action.payload };

    // -----------------Request -------------->
    case Get_RequestList_Data:
      return { ...state, requestListData: action.payload };
    case Get_Single_RequestList_Data:
      return { ...state, singleRequestListData: action.payload };
    // -----------------Approve Request -----------------
    case Get_Approve_Request:
      return { ...state, approveRequest: action.payload };
    //----------------Rejct Request -----------------
    case Get_UnApprove_Request:
      return { ...state, unapproveRequest: action.payload };
    case Get_DeApprove_Request:
      return { ...state, rejectRequestData: action.payload };
    // -----------------Approve Quotes -----------------
    case Get_Approve_Quotes:
      return { ...state, approveQuotes: action.payload };
    case Get_Quotes_Id:
      return { ...state, quoteId: action.payload };
    // ---------------Enter Quotes --------------
    case Get_Quotes_Data:
      return { ...state, quotesData: action.payload };
    case Get_Receivedby_DropData:
      return { ...state, receivedbyDropData: action.payload };
    case Get_Supplier_DropData:
      return { ...state, supplierDropData: action.payload };
    case Get_DiscountType_DropData:
      return { ...state, discountDropData: action.payload };
    case Get_Gst_DropData:
      return { ...state, gstSlabsDropData: action.payload };

    case Get_Single_Quotes_Data:
      return { ...state, singlequotesData: action.payload };
    case Get_Req_Id:
      return { ...state, reqID: action.payload };

    // ----------------Inventory ---------
    case Get_MaterialGroup_DropData:
      return { ...state, materialgroupDropDown: action.payload };
    case Get_MaterialType_DropData:
      return { ...state, materialtypeDropDown: action.payload };
    case Get_Inventory_Material_Data:
      return { ...state, inventoryData: action.payload };
    case Get_Single_Inventory_Data:
      return { ...state, singleinventoryData: action.payload };
    case Get_Inventory_Material_Filter_Data:
      return { ...state, inventoryFilterData: action.payload };

    // --------------------------- Inventory -> Purchanse Invoice -------------
    case Get_Invoice_Type_DropData:
      return { ...state, invoiceDropData: action.payload };
    case Get_PurchaseInvoice_Data:
      return { ...state, purchaseInvoiceData: action.payload };
    case Get_Single_PurchaseInvoice_Data:
      return { ...state, singleInvoiceData: action.payload };
    // -----------------------Inventory -> Purchanse Invoice > Add Item-----------------
    case Get_AddItem_Data:
      return { ...state, addItemData: action.payload };
    case Get_Single_AddItem_Data:
      return { ...state, singleAddItemData: action.payload };
    case Get_Invoice_ID:
      return { ...state, invoiceID: action.payload };
    case Get_Invoice_Filter_Data:
      return { ...state, invoiceFilterData: action.payload };
    // --------------Admin Setting > PhoneSMS---------
    case Get_PhoneSms_Data:
      return { ...state, phoneSmsData: action.payload };
    case Get_Single_PhoneSms_Data:
      return { ...state, singlePhoneSmsData: action.payload };
    case Get_Filter_PhoneSms_Data:
      return { ...state, filterPhoneSmsData: action.payload };

    // ------------------------------------Service>ServiceConfiguration------------------
    case Get_ServiceConfiguration_Dropdown:
      return { ...state, serviceConfigurationDropdown: action.payload };
    case Get_ServiceConfiguration_Data:
      return { ...state, serviceConfigurationData: action.payload };
    case Get_Filter_ServiceConfiguration_Data:
      return { ...state, filterServiceConfigurationData: action.payload };
    case Get_Single_ServiceConfiguration_Data:
      return { ...state, singleServiceConfigurationData: action.payload };
    // ----------------------------Service>Mantinance--------------------------------------
    case Get_Mantinance_Data:
      return { ...state, MantinanceData: action.payload };
    case Get_Mantinance_Dropdown:
      return { ...state, mantinanceDropdown: action.payload };
    case Get_Filter_Mantinance_Data:
      return { ...state, filterMantinanceData: action.payload };
    case Get_Single_Mantinance_Data:
      return { ...state, singleMantinanceData: action.payload };

    default:
      return state;
  }
};
export default AllActionReducer;
