import React from 'react'
import { Link } from 'react-router-dom'

export const HrNavBar = ({ openPage }) => {


    return (  
        <ul className="navbar-nav flex-column p-0">
            {['Employee', 'Emp-Status', 'Emp-Leave', 'Emp-Attendance', 'Assign Shift', 'Emp-Task', 'Attendance Report', 'Salary'].map((page) => (
                <li style={{ backgroundColor: openPage === page ? '#189AB4' : '', }} className="nav-item" key={page}>
                    <Link
                        to={`/HR?page=${page}`}
                    >
                        <a className="nav-link active">{page}</a>
                    </Link>
                </li>
            ))}
        </ul>
    )
}
