import React from 'react'
import { Link } from 'react-router-dom'

const InventryNavBar = ({ openPage }) => {

    return (
        <ul className="navbar-nav flex-column">
            {['Add Request', 'Approve Request', 'Reject Request', 'Approve Quotes', 'Enter Quotes', 'PO Section', 'Purchase Invoice'].map((page) => (
                <li style={{ backgroundColor: openPage === page ? '#189AB4' : '' }} className="nav-item" key={page}>
                    <Link
                        to={`/Inventory?page=${page}`}
                    >
                        <a className="nav-link active">{page}</a>
                    </Link>
                </li>
            ))}
        </ul>
    )
}

export default InventryNavBar