import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import ConfirmModal from '../../../Include/Common/ConfirmModal';
import AddRequest from './AddRequest'
import { delete_Add_Request_Data, get_AddRequest_Data, get_Single_Request_Data } from '../../../../redux/actions/AllAction'
import { Check_Page, Edit_Update_Count, Get_Req_Id, Get_RequestList_Data, Get_Single_RequestList_Data, Get_Single_Request_Data, Modal_Status, Page_Status, Status } from '../../../../redux/actionTypes'

const MainRequest = () => {

  const dispatch = useDispatch()
  const requestData = useSelector((state) => state.Data.requestData)
  const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
  const userId = useSelector((state) => state.Data.userId)
  const [pageStatus, setPageStatus] = useState("1")
  const [confirmType, setConfirmType] = useState('Delete')
  const [IsActive, setIsActive] = useState()
  const [id, setId] = useState('')

  useEffect(() => {
    dispatch(get_AddRequest_Data(pageStatus))
  }, [pageStatus]);

  const columns = [
    {
      name: 'Department',
      selector: (row) => row.department,
      sortable: true
    },
    {
      name: 'JobNo',
      selector: (row) => row.JobNo,
      sortable: true
    },
    {
      name: 'Request Date',
      selector: (row) => row.RequestDate,
      sortable: true
    },

    {
      name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 55, fontWeight: '700' }}>Action</p>,
      cell: row => <>
        <div style={{ position: 'absolute', top: 4, right: 40 }}>
          {pageStatus === "1" ?
            <Link to='/addRequest' className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn" onClick={() => setEditStatus(row)} data-toggle="modal" data-target="#MeterReadingModal"><i className="fa fa-edit"></i>
            </Link> : ''}
          <Link to={`/Inventory?page=Add%20Request`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive('0'); setConfirmType("Delete"); setId(row.ID) }}
            className="btn btn-sm bg-green text-white px-1 py-0 mr-1" >
            <i class="fa fa-trash" aria-hidden="true"></i>
          </Link>
        </div>
      </>
    }
  ]
  
  const setEditStatus = (row) => {
    dispatch(get_Single_Request_Data(row.ID)); setId(row.ID)
    dispatch({ type: Get_Req_Id, payload: row.ID })
    dispatch({ type: Get_Single_Request_Data, payload: row });
    dispatch({ type: Status, payload: true });
    dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
  }

  const newStatus = () => {
    dispatch({ type: Get_Single_Request_Data, payload: [] });
    dispatch({ type: Get_RequestList_Data, payload: [] })
    dispatch({ type: Get_Req_Id, payload: '' });
    dispatch({ type: Check_Page, payload: true })
    dispatch({ type: Status, payload: false });
  }

  const UpdActiveDeactive = () => {
    dispatch(delete_Add_Request_Data(id, IsActive, userId, pageStatus))
  }

  return (
    <>
      <div className="col-12 col-md-12 col-lg-12  px-3">
        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">Add Request</p>
          <Link to={`/addRequest`} onClick={newStatus} className="text-white px-2 py-0 "
            data-toggle="modal" data-target="#"  >
            <i className="fa fa-plus"></i>
          </Link>
        </div>
      </div>
      <div className="col-12 mt-1">
        <DataTable
          dense
          columns={columns}
          data={requestData}
          pagination
          selectableRowsHighlight
          highlightOnHover
        />
      </div>
      <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType} />
      {/* <AddRequest /> */}
    </>
  )
}

export default MainRequest