import React from 'react'
import MasterTable from './MasterTable'
import { useLocation } from 'react-router-dom';
import Product from './Product/Product';
import SaleList from '../Sale/SaleParty/SaleList';
import PlantServiceProcess from './PlantServiceProcess/PlantServiceProcess';
import AssignGroupType from './AssignGroupType/AssignGroupType';
import Main from './Main';
import InventoryMaterial from './InventoryMaterial/InventoryMaterial';

const MasterTableTabs = () => {

    const useQuery = () => new URLSearchParams(useLocation().search);
    // let openPage = useQuery().get('page');
    let openPage = 'Account GroupHead';
    let openSubPage = useQuery().get('sub');

    return (
        <>
            {
                openPage === 'Account GroupHead' ?
                    <MasterTable
                        {...{ openPage }}
                        showCol1='Name' showCol2='' idName='ID'
                        getUrl='/AccountGroupHead/GetData_AccountGroupHead'
                        addUrl='/AccountGroupHead/InsertAccountGroupHead'
                        singleDataUrl='/AccountGroupHead/GetSingleData_AccountGroupHead'
                        upUrl='/AccountGroupHead/UpdateAccountGroupHead'
                        delUrl='/AccountGroupHead/DeleteAccountGroupHead'
                    />
                    :
                    openPage === 'Account Head' ?
                        <MasterTable
                            {...{ openPage }}
                            showCol1='Name' showCol2='HeadCode' idName='ID'
                            getUrl='/AccountHead/GetData_AccountHead'
                            addUrl='/AccountHead/InsertAccountHead'
                            singleDataUrl='/AccountHead/GetSingleData_AccountHead'
                            upUrl='/AccountHead/UpdateAccountHead'
                            delUrl='/AccountHead/DeleteAccountHead'
                        />
                        :
                        openPage === 'Account Head Lvl1' ?
                            <MasterTable
                                {...{ openPage }}
                                showCol1='Name' showCol2='' idName='ID'
                                getUrl='/AccountHeadLvl1/GetData_AccountHeadLvl1'
                                addUrl='/AccountHeadLvl1/InsertAccountHeadLvl1'
                                singleDataUrl='/AccountHeadLvl1/GetSingleData_AccountHeadLvl1'
                                upUrl='/AccountHeadLvl1/UpdateAccountHeadLvl1'
                                delUrl='/AccountHeadLvl1/DeleteAccountHeadLvl1'
                            />
                            :
                            openPage === 'Account Head Lvl2' ?
                                <MasterTable
                                    {...{ openPage }}
                                    showCol1='Name' showCol2='' idName='ID'
                                    getUrl='/AccountHeadLvl2/GetData_AccountHeadLvl2'
                                    addUrl='/AccountHeadLvl2/InsertAccountHeadLvl2'
                                    singleDataUrl='/AccountHeadLvl2/GetSingleData_AccountHeadLvl2'
                                    upUrl='/AccountHeadLvl2/UpdateAccountHeadLvl2'
                                    delUrl='/AccountHeadLvl2/DeleteAccountHeadLvl2'
                                />
                                :
                                openPage === 'Account Master Head' ?
                                    <MasterTable
                                        {...{ openPage }}
                                        showCol1='Name' showCol2='' idName='ID'
                                        getUrl='/AccountMasterHead/GetData_AccountMasterHead'
                                        addUrl='/AccountMasterHead/InsertAccountMasterHead'
                                        singleDataUrl='/AccountMasterHead/GetSingleData_AccountMasterHead'
                                        upUrl='/AccountMasterHead/UpdateAccountMasterHead'
                                        delUrl='/AccountMasterHead/DeleteAccountMasterHead'
                                    />
                                    :
                                    openPage === 'Account Sub GroupHead' ?
                                        <MasterTable
                                            {...{ openPage }}
                                            showCol1='Name' showCol2='' idName='ID'
                                            getUrl='/AccountSubGroupHead/GetData_AccountSubGroupHead'
                                            addUrl='/AccountSubGroupHead/InsertAccountSubGroupHead'
                                            singleDataUrl='/AccountSubGroupHead/GetSingleData_AccountSubGroupHead'
                                            upUrl='/AccountSubGroupHead/UpdateAccountSubGroupHead'
                                            delUrl='/AccountSubGroupHead/DeleteAccountSubGroupHead'
                                        />
                                        :
                                        openPage === 'Account Sub Head' ?
                                            <MasterTable
                                                {...{ openPage }}
                                                showCol1='Name' showCol2='' idName='ID'
                                                getUrl='/AccountSubHead/GetData_AccountSubHead'
                                                addUrl='/AccountSubHead/InsertAccountSubHead'
                                                singleDataUrl='/AccountSubHead/GetSingleData_AccountSubHead'
                                                upUrl='/AccountSubHead/UpdateAccountSubHead'
                                                delUrl='/AccountSubHead/DeleteAccountSubHead'
                                            />
                                            :
                                            openPage === 'Document For' ?
                                                <MasterTable
                                                    {...{ openPage }}
                                                    showCol1='DocumentFor' showCol2='' idName='Id'
                                                    getUrl='/DocumentFor/GetData_DocumentFor'
                                                    addUrl='/DocumentFor/InsertDocumentFor'
                                                    singleDataUrl='/DocumentFor/GetSingleData_DocumentFor'
                                                    upUrl='/DocumentFor/UpdateDocumentFor'
                                                    delUrl='/DocumentFor/DeleteDocumentFor'
                                                />
                                                :
                                                openPage === 'Document Type' ?
                                                    <MasterTable
                                                        {...{ openPage }}
                                                        showCol1='DocumentType' showCol2='' idName='id'
                                                        getUrl='/DocumentType/GetData_DocumentType'
                                                        addUrl='/DocumentType/InsertDocumentType'
                                                        singleDataUrl='/DocumentType/GetSingleData_DocumentType'
                                                        upUrl='/DocumentType/UpdateDocumentType'
                                                        delUrl='/DocumentType/DeleteDocumentType'
                                                    />
                                                    :
                                                    openPage === 'Blood Group' ?
                                                        <MasterTable
                                                            {...{ openPage }}
                                                            showCol1='BloodGroup' showCol2='' idName='Id'
                                                            getUrl='/BloodGroup/GetData_BloodGroup'
                                                            addUrl='/BloodGroup/InsertBloodGroup'
                                                            singleDataUrl='/BloodGroup/GetSingleData_BloodGroup'
                                                            upUrl='/BloodGroup/UpdateBloodGroup'
                                                            delUrl='/BloodGroup/DeleteBloodGroup'
                                                        />
                                                        :
                                                        openPage === 'Department' ?
                                                            <MasterTable
                                                                {...{ openPage }}
                                                                showCol1='Department' showCol2='' idName='ID'
                                                                getUrl='/Department/GetData_Department'
                                                                addUrl='/Department/InsertDepartment'
                                                                singleDataUrl='/Department/GetSingleData_Department'
                                                                upUrl='/Department/UpdateDepartment'
                                                                delUrl='/Department/DeleteDepartment'
                                                            />
                                                            :
                                                            openPage === 'Designation' ?
                                                                <MasterTable
                                                                    {...{ openPage }}
                                                                    showCol1='Designation' showCol2='' idName='ID'
                                                                    getUrl='/Designation/GetData_Designation'
                                                                    addUrl='/Designation/InsertDesignation'
                                                                    singleDataUrl='/Designation/GetSingleData_Designation'
                                                                    upUrl='/Designation/UpdateDesignation'
                                                                    delUrl='/Designation/DeleteDesignation'
                                                                />
                                                                :
                                                                openPage === 'Relation' ?
                                                                    <MasterTable
                                                                        {...{ openPage }}
                                                                        showCol1='Description' showCol2='' idName='ID'
                                                                        getUrl='/Relation/GetData_Relation'
                                                                        addUrl='/Relation/InsertRelation'
                                                                        singleDataUrl='/Relation/GetSingleData_Relation'
                                                                        upUrl='/Relation/UpdateRelation'
                                                                        delUrl='/Relation/DeleteRelation'
                                                                    />
                                                                    :
                                                                    openPage === 'Gender' ?
                                                                        <MasterTable
                                                                            {...{ openPage }}
                                                                            showCol1='Description' showCol2='' idName='id'
                                                                            getUrl='/Gender/GetData_Gender'
                                                                            addUrl='/Gender/InsertGender'
                                                                            singleDataUrl='/Gender/GetSingleData_Gender'
                                                                            upUrl='/Gender/UpdateGender'
                                                                            delUrl='/Gender/DeleteGender'
                                                                        />
                                                                        :
                                                                        openPage === 'Religion' ?
                                                                            <MasterTable
                                                                                {...{ openPage }}
                                                                                showCol1='Description' showCol2='' idName='id'
                                                                                getUrl='/Religion/GetData_Religion'
                                                                                addUrl='/Religion/InsertReligion'
                                                                                singleDataUrl='/Religion/GetSingleData_Religion'
                                                                                upUrl='/Religion/UpdateReligion'
                                                                                delUrl='/Religion/DeleteReligion'
                                                                            />
                                                                            :
                                                                            openPage === 'Martial' ?
                                                                                <MasterTable
                                                                                    {...{ openPage }}
                                                                                    showCol1='Description' showCol2='' idName='id'
                                                                                    getUrl='/Martial/GetData_Martial'
                                                                                    addUrl='/Martial/InsertMartial'
                                                                                    singleDataUrl='/Martial/GetSingleData_Martial'
                                                                                    upUrl='/Martial/UpdateMartial'
                                                                                    delUrl='/Martial/DeleteMartial'
                                                                                />
                                                                                :
                                                                                openPage === 'Shift' ?
                                                                                    <MasterTable
                                                                                        {...{ openPage }}
                                                                                        showCol1='Name' showCol2='Time' idName='Id'
                                                                                        getUrl='/Shift/GetData_Shift'
                                                                                        addUrl='/Shift/InsertShift'
                                                                                        singleDataUrl='/Shift/GetSingleData_Shift'
                                                                                        upUrl='/Shift/UpdateShift'
                                                                                        delUrl='/Shift/DeleteShift'
                                                                                    />
                                                                                    :
                                                                                    openPage === 'Unit' ?
                                                                                        <MasterTable
                                                                                            {...{ openPage }}
                                                                                            showCol1='Unit' showCol2='' idName='ID'
                                                                                            getUrl='/Unit/GetData_Unit'
                                                                                            addUrl='/Unit/InsertUnit'
                                                                                            singleDataUrl='/Unit/GetSingleData_Unit'
                                                                                            upUrl='/Unit/UpdateUnit'
                                                                                            delUrl='/Unit/DeleteUnit'
                                                                                        />
                                                                                        :
                                                                                        openPage === 'Consumption Group' ?
                                                                                            <MasterTable
                                                                                                {...{ openPage }}
                                                                                                showCol1='GroupName' showCol2='' idName='ID'
                                                                                                getUrl='/MaterialConsumptionGroup/GetData_MaterialConsumptionGroup'
                                                                                                addUrl='/MaterialConsumptionGroup/InsertMaterialConsumptionGroup'
                                                                                                singleDataUrl='/MaterialConsumptionGroup/GetSingleData_MaterialConsumptionGroup'
                                                                                                upUrl='/MaterialConsumptionGroup/UpdateMaterialConsumptionGroup'
                                                                                                delUrl='/MaterialConsumptionGroup/DeleteMaterialConsumptionGroup'
                                                                                            />
                                                                                            :
                                                                                            openPage === 'Consumption Type' ?
                                                                                                <MasterTable
                                                                                                    {...{ openPage }}
                                                                                                    showCol1='Type' showCol2='' idName='ID'
                                                                                                    getUrl='/MaterialConsumptionType/GetData_MaterialConsumptionType'
                                                                                                    addUrl='/MaterialConsumptionType/InsertMaterialConsumptionType'
                                                                                                    singleDataUrl='/MaterialConsumptionType/GetSingleData_MaterialConsumptionType'
                                                                                                    upUrl='/MaterialConsumptionType/UpdateMaterialConsumptionType'
                                                                                                    delUrl='/MaterialConsumptionType/DeleteMaterialConsumptionType'
                                                                                                />
                                                                                                :
                                                                                                openPage === 'Cost Center' ?
                                                                                                    <MasterTable
                                                                                                        {...{ openPage }}
                                                                                                        showCol1='CostCenter' showCol2='' idName='ID'
                                                                                                        getUrl='/MaterialCostCenter/GetData_MaterialCostCenter'
                                                                                                        addUrl='/MaterialCostCenter/InsertMaterialCostCenter'
                                                                                                        singleDataUrl='/MaterialCostCenter/GetSingleData_MaterialCostCenter'
                                                                                                        upUrl='/MaterialCostCenter/UpdateMaterialCostCenter'
                                                                                                        delUrl='/MaterialCostCenter/DeleteMaterialCostCenter'
                                                                                                    />
                                                                                                    :
                                                                                                    openPage === 'Cost Head' ?
                                                                                                        <MasterTable
                                                                                                            {...{ openPage }}
                                                                                                            showCol1='Head' showCol2='' idName='Id'
                                                                                                            getUrl='/MaterialCostHead/GetData_MaterialCostHead'
                                                                                                            addUrl='/MaterialCostHead/InsertMaterialCostHead'
                                                                                                            singleDataUrl='/MaterialCostHead/GetSingleData_MaterialCostHead'
                                                                                                            upUrl='/MaterialCostHead/UpdateMaterialCostHead'
                                                                                                            delUrl='/MaterialCostHead/DeleteMaterialCostHead'
                                                                                                        />
                                                                                                        :
                                                                                                        openPage === 'Cost SubHead' ?
                                                                                                            <MasterTable
                                                                                                                {...{ openPage }}
                                                                                                                showCol1='SubHead' showCol2='' idName='ID'
                                                                                                                getUrl='/MaterialCostSubHead/GetData_MaterialCostSubHead'
                                                                                                                addUrl='/MaterialCostSubHead/InsertMaterialCostSubHead'
                                                                                                                singleDataUrl='/MaterialCostSubHead/GetSingleData_MaterialCostSubHead'
                                                                                                                upUrl='/MaterialCostSubHead/UpdateMaterialCostSubHead'
                                                                                                                delUrl='/MaterialCostSubHead/DeleteMaterialCostSubHead'
                                                                                                            />
                                                                                                            :
                                                                                                            openPage === 'Machine Type' ?
                                                                                                                <MasterTable
                                                                                                                    {...{ openPage }}
                                                                                                                    showCol1='MachineType' showCol2='' idName='ID'
                                                                                                                    getUrl='/MachineType/GetData_MachineType'
                                                                                                                    addUrl='/MachineType/InsertMachineType'
                                                                                                                    singleDataUrl='/MachineType/GetSingleData_MachineType'
                                                                                                                    upUrl='/MachineType/UpdateMachineType'
                                                                                                                    delUrl='/MachineType/DeleteMachineType'
                                                                                                                />
                                                                                                                :
                                                                                                                openPage === 'Material Group' ?
                                                                                                                    <MasterTable
                                                                                                                        {...{ openPage }}
                                                                                                                        showCol1='GroupName' showCol2='' idName='ID'
                                                                                                                        getUrl='/MaterialGroup/GetData_MaterialGroup'
                                                                                                                        addUrl='/MaterialGroup/InsertMaterialGroup'
                                                                                                                        singleDataUrl='/MaterialGroup/GetSingleData_MaterialGroup'
                                                                                                                        upUrl='/MaterialGroup/UpdateMaterialGroup'
                                                                                                                        delUrl='/MaterialGroup/DeleteMaterialGroup'
                                                                                                                    />
                                                                                                                    :
                                                                                                                    openPage === 'Material Nature' ?
                                                                                                                        <MasterTable
                                                                                                                            {...{ openPage }}
                                                                                                                            showCol1='Nature' showCol2='' idName='ID'
                                                                                                                            getUrl='/MaterialNature/GetData_MaterialNature'
                                                                                                                            addUrl='/MaterialNature/InsertMaterialNature'
                                                                                                                            singleDataUrl='/MaterialNature/GetSingleData_MaterialNature'
                                                                                                                            upUrl='/MaterialNature/UpdateMaterialNature'
                                                                                                                            delUrl='/MaterialNature/DeleteMaterialNature'
                                                                                                                        />
                                                                                                                        :
                                                                                                                        openPage === 'Material Type' ?
                                                                                                                            <MasterTable
                                                                                                                                {...{ openPage }}
                                                                                                                                showCol1='Type' showCol2='' idName='ID'
                                                                                                                                getUrl='/MaterialType/GetData_MaterialType'
                                                                                                                                addUrl='/MaterialType/InsertMaterialType'
                                                                                                                                singleDataUrl='/MaterialType/GetSingleData_MaterialType'
                                                                                                                                upUrl='/MaterialType/UpdateMaterialType'
                                                                                                                                delUrl='/MaterialType/DeleteMaterialType'
                                                                                                                            />
                                                                                                                            :
                                                                                                                            openPage === 'Loadingcharge' ?
                                                                                                                                <MasterTable
                                                                                                                                    {...{ openPage }}
                                                                                                                                    showCol1='LoadingAmt' showCol2='' idName='id'
                                                                                                                                    getUrl='/Loadingcharge/GetData_Loadingcharge'
                                                                                                                                    addUrl='/Loadingcharge/InsertLoadingcharge'
                                                                                                                                    singleDataUrl='/Loadingcharge/GetSingleData_Loadingcharge'
                                                                                                                                    upUrl='/Loadingcharge/UpdateLoadingcharge'
                                                                                                                                    delUrl='/Loadingcharge/DeleteLoadingcharge'
                                                                                                                                />
                                                                                                                                :
                                                                                                                                openPage === 'Tp Amount' ?
                                                                                                                                    <MasterTable
                                                                                                                                        {...{ openPage }}
                                                                                                                                        showCol1='TpAmount' showCol2='' idName='Id'
                                                                                                                                        getUrl='/TpAmount/GetData_TpAmount'
                                                                                                                                        addUrl='/TpAmount/InsertTpAmount'
                                                                                                                                        singleDataUrl='/TpAmount/GetSingleData_TpAmount'
                                                                                                                                        upUrl='/TpAmount/UpdateTpAmount'
                                                                                                                                        delUrl='/TpAmount/DeleteTpAmount'
                                                                                                                                    />
                                                                                                                                    :
                                                                                                                                    openPage === 'Unit Type' ?
                                                                                                                                        <MasterTable
                                                                                                                                            {...{ openPage }}
                                                                                                                                            showCol1='UnitType' showCol2='' idName='id'
                                                                                                                                            getUrl='/UnitType/GetData_UnitType'
                                                                                                                                            addUrl='/UnitType/InsertUnitType'
                                                                                                                                            singleDataUrl='/UnitType/GetSingleData_UnitType'
                                                                                                                                            upUrl='/UnitType/UpdateUnitType'
                                                                                                                                            delUrl='/UnitType/DeleteUnitType'
                                                                                                                                        />
                                                                                                                                        :
                                                                                                                                        openPage === 'Vehicle Type' ?
                                                                                                                                            <MasterTable
                                                                                                                                                {...{ openPage }}
                                                                                                                                                showCol1='VehicleType' showCol2='' idName='id'
                                                                                                                                                getUrl='/VehicleType/GetData_VehicleType'
                                                                                                                                                addUrl='/VehicleType/InsertVehicleType'
                                                                                                                                                singleDataUrl='/VehicleType/GetSingleData_VehicleType'
                                                                                                                                                upUrl='/VehicleType/UpdateVehicleType'
                                                                                                                                                delUrl='/VehicleType/DeleteVehicleType'
                                                                                                                                            />
                                                                                                                                            :
                                                                                                                                            openPage === 'Product' ?
                                                                                                                                                <Product
                                                                                                                                                    {...{ openPage }}
                                                                                                                                                // showCol1 = 'VehicleType' showCol2='' idName='id'
                                                                                                                                                // getUrl='/VehicleType/GetData_VehicleType'
                                                                                                                                                // addUrl='/VehicleType/InsertVehicleType'
                                                                                                                                                // singleDataUrl='/VehicleType/GetSingleData_VehicleType'
                                                                                                                                                // upUrl='/VehicleType/UpdateVehicleType'
                                                                                                                                                // delUrl='/VehicleType/DeleteVehicleType'
                                                                                                                                                />
                                                                                                                                                :
                                                                                                                                                openPage === 'Plant Service Type' ?
                                                                                                                                                    <MasterTable
                                                                                                                                                        {...{ openPage }}
                                                                                                                                                        showCol1='ServiceType' showCol2='' idName='Id'
                                                                                                                                                        getUrl='/PlantServiceType/GetData_PlantServiceType'
                                                                                                                                                        addUrl='/PlantServiceType/InsertPlantServiceType'
                                                                                                                                                        singleDataUrl='/PlantServiceType/GetSingleData_PlantServiceType'
                                                                                                                                                        upUrl='/PlantServiceType/UpdatePlantServiceType'
                                                                                                                                                        delUrl='/PlantServiceType/DeletePlantServiceType'
                                                                                                                                                    />
                                                                                                                                                    :
                                                                                                                                                    openPage === 'Plant Task' ?
                                                                                                                                                        <MasterTable
                                                                                                                                                            {...{ openPage }}
                                                                                                                                                            showCol1='Description' showCol2='' idName='ID'
                                                                                                                                                            getUrl='/PlantTask/GetData_PlantTask'
                                                                                                                                                            addUrl='/PlantTask/InsertPlantTask'
                                                                                                                                                            singleDataUrl='/PlantTask/GetSingleData_PlantTask'
                                                                                                                                                            upUrl='/PlantTask/UpdatePlantTask'
                                                                                                                                                            delUrl='/PlantTask/DeletePlantTask'
                                                                                                                                                        />
                                                                                                                                                        :
                                                                                                                                                        openPage === 'IDProof' ?
                                                                                                                                                            <MasterTable
                                                                                                                                                                {...{ openPage }}
                                                                                                                                                                showCol1='IDProof' showCol2='' idName='ID'
                                                                                                                                                                getUrl='/IDProof/GetData_IDProof'
                                                                                                                                                                addUrl='/IDProof/InsertIDProof'
                                                                                                                                                                singleDataUrl='/IDProof/GetSingleData_IDProof'
                                                                                                                                                                upUrl='/IDProof/UpdateIDProof'
                                                                                                                                                                delUrl='/IDProof/DeleteIDProof'
                                                                                                                                                            />
                                                                                                                                                            :
                                                                                                                                                            openPage === 'Status' ?
                                                                                                                                                                <MasterTable
                                                                                                                                                                    {...{ openPage }}
                                                                                                                                                                    showCol1='EmpStatus' showCol2='' idName='ID'
                                                                                                                                                                    getUrl='/EmpStatus/GetData_EmpStatus'
                                                                                                                                                                    addUrl='/EmpStatus/InsertEmpStatus'
                                                                                                                                                                    singleDataUrl='/EmpStatus/GetSingleData_EmpStatus'
                                                                                                                                                                    upUrl='/EmpStatus/UpdateEmpStatus'
                                                                                                                                                                    delUrl='/EmpStatus/DeleteEmpStatus'
                                                                                                                                                                />
                                                                                                                                                                :
                                                                                                                                                                openPage === 'Task Type' ?
                                                                                                                                                                    <MasterTable
                                                                                                                                                                        {...{ openPage }}
                                                                                                                                                                        showCol1='Description' showCol2='' idName='ID'
                                                                                                                                                                        getUrl='/TaskType/GetData_TaskType'
                                                                                                                                                                        addUrl='/TaskType/InsertTaskType'
                                                                                                                                                                        singleDataUrl='/TaskType/GetSingleData_TaskType'
                                                                                                                                                                        upUrl='/TaskType/UpdateTaskType'
                                                                                                                                                                        delUrl='/TaskType/DeleteTaskType'
                                                                                                                                                                    />
                                                                                                                                                                    :
                                                                                                                                                                    openPage === 'Legal Case Location' ?
                                                                                                                                                                        <MasterTable
                                                                                                                                                                            {...{ openPage }}
                                                                                                                                                                            showCol1='Description' showCol2='' idName='id'
                                                                                                                                                                            getUrl='/LegalCaseLocation/GetData_LegalCaseLocation'
                                                                                                                                                                            addUrl='/LegalCaseLocation/InsertLegalCaseLocation'
                                                                                                                                                                            singleDataUrl='/LegalCaseLocation/GetSingleData_LegalCaseLocation'
                                                                                                                                                                            upUrl='/LegalCaseLocation/UpdateLegalCaseLocation'
                                                                                                                                                                            delUrl='/LegalCaseLocation/DeleteLegalCaseLocation'
                                                                                                                                                                        />
                                                                                                                                                                        :
                                                                                                                                                                        openPage === 'Legal Case Thana' ?
                                                                                                                                                                            <MasterTable
                                                                                                                                                                                {...{ openPage }}
                                                                                                                                                                                showCol1='Description' showCol2='' idName='id'
                                                                                                                                                                                getUrl='/LegalCaseThana/GetData_LegalCaseThana'
                                                                                                                                                                                addUrl='/LegalCaseThana/InsertLegalCaseThana'
                                                                                                                                                                                singleDataUrl='/LegalCaseThana/GetSingleData_LegalCaseThana'
                                                                                                                                                                                upUrl='/LegalCaseThana/UpdateLegalCaseThana'
                                                                                                                                                                                delUrl='/LegalCaseThana/DeleteLegalCaseThana'
                                                                                                                                                                            />
                                                                                                                                                                            :
                                                                                                                                                                            openPage === 'Legal Case Type' ?
                                                                                                                                                                                <MasterTable
                                                                                                                                                                                    {...{ openPage }}
                                                                                                                                                                                    showCol1='Description' showCol2='' idName='id'
                                                                                                                                                                                    getUrl='/LegalCaseType/GetData_LegalCaseType'
                                                                                                                                                                                    addUrl='/LegalCaseType/InsertLegalCaseType'
                                                                                                                                                                                    singleDataUrl='/LegalCaseType/GetSingleData_LegalCaseType'
                                                                                                                                                                                    upUrl='/LegalCaseType/UpdateLegalCaseType'
                                                                                                                                                                                    delUrl='/LegalCaseType/DeleteLegalCaseType'
                                                                                                                                                                                />
                                                                                                                                                                                :
                                                                                                                                                                                openPage === 'Leave Type' ?
                                                                                                                                                                                    <MasterTable
                                                                                                                                                                                        {...{ openPage }}
                                                                                                                                                                                        showCol1='Code' showCol2='Description' idName='ID'
                                                                                                                                                                                        getUrl='/EmpLeaveType/GetData_EmpLeaveType'
                                                                                                                                                                                        addUrl='/EmpLeaveType/InsertEmpLeaveType'
                                                                                                                                                                                        singleDataUrl='/EmpLeaveType/GetSingleData_EmpLeaveType'
                                                                                                                                                                                        upUrl='/EmpLeaveType/UpdateEmpLeaveType'
                                                                                                                                                                                        delUrl='/EmpLeaveType/DeleteEmpLeaveType'
                                                                                                                                                                                    />
                                                                                                                                                                                    :
                                                                                                                                                                                    openPage === 'Leave Status' ?
                                                                                                                                                                                        <MasterTable
                                                                                                                                                                                            {...{ openPage }}
                                                                                                                                                                                            showCol1='Code' showCol2='Description' idName='ID'
                                                                                                                                                                                            getUrl='/EmpLeaveStatus/GetData_EmpLeaveStatus'
                                                                                                                                                                                            addUrl='/EmpLeaveStatus/InsertEmpLeaveStatus'
                                                                                                                                                                                            singleDataUrl='/EmpLeaveStatus/GetSingleData_EmpLeaveStatus'
                                                                                                                                                                                            upUrl='/EmpLeaveStatus/UpdateEmpLeaveStatus'
                                                                                                                                                                                            delUrl='/EmpLeaveStatus/DeleteEmpLeaveStatus'
                                                                                                                                                                                        />
                                                                                                                                                                                        :
                                                                                                                                                                                        openPage === 'Pit' ?
                                                                                                                                                                                            <MasterTable
                                                                                                                                                                                                {...{ openPage }}
                                                                                                                                                                                                showCol1='Description' showCol2='' idName='ID'
                                                                                                                                                                                                getUrl='/MiningPit/GetData_MiningPit'
                                                                                                                                                                                                addUrl='/MiningPit/InsertMiningPit'
                                                                                                                                                                                                singleDataUrl='/MiningPit/GetSingleData_MiningPit'
                                                                                                                                                                                                upUrl='/MiningPit/UpdateMiningPit'
                                                                                                                                                                                                delUrl='/MiningPit/DeleteMiningPit'
                                                                                                                                                                                            />
                                                                                                                                                                                            :
                                                                                                                                                                                            openPage === 'Site Direction' ?
                                                                                                                                                                                                <MasterTable
                                                                                                                                                                                                    {...{ openPage }}
                                                                                                                                                                                                    showCol1='Description' showCol2='' idName='ID'
                                                                                                                                                                                                    getUrl='/MiningSiteDirection/GetData_MiningSiteDirection'
                                                                                                                                                                                                    addUrl='/MiningSiteDirection/InsertMiningSiteDirection'
                                                                                                                                                                                                    singleDataUrl='/MiningSiteDirection/GetSingleData_MiningSiteDirection'
                                                                                                                                                                                                    upUrl='/MiningSiteDirection/UpdateMiningSiteDirection'
                                                                                                                                                                                                    delUrl='/MiningSiteDirection/DeleteMiningSiteDirection'
                                                                                                                                                                                                />
                                                                                                                                                                                                :
                                                                                                                                                                                                openPage === 'Machine Name' ?
                                                                                                                                                                                                    <MasterTable
                                                                                                                                                                                                        {...{ openPage }}
                                                                                                                                                                                                        showCol1='Description' showCol2='' idName='ID'
                                                                                                                                                                                                        getUrl='/MiningMachineName/GetData_MiningMachineName'
                                                                                                                                                                                                        addUrl='/MiningMachineName/InsertMiningMachineName'
                                                                                                                                                                                                        singleDataUrl='/MiningMachineName/GetSingleData_MiningMachineName'
                                                                                                                                                                                                        upUrl='/MiningMachineName/UpdateMiningMachineName'
                                                                                                                                                                                                        delUrl='/MiningMachineName/DeleteMiningMachineName'
                                                                                                                                                                                                    />
                                                                                                                                                                                                    :
                                                                                                                                                                                                    openPage === 'ExplosiveType' ?
                                                                                                                                                                                                        <MasterTable
                                                                                                                                                                                                            {...{ openPage }}
                                                                                                                                                                                                            showCol1='Description' showCol2='' idName='ID'
                                                                                                                                                                                                            getUrl='/MiningExplosiveType/GetData_MiningExplosiveType'
                                                                                                                                                                                                            addUrl='/MiningExplosiveType/InsertMiningExplosiveType'
                                                                                                                                                                                                            singleDataUrl='/MiningExplosiveType/GetSingleData_MiningExplosiveType'
                                                                                                                                                                                                            upUrl='/MiningExplosiveType/UpdateMiningExplosiveType'
                                                                                                                                                                                                            delUrl='/MiningExplosiveType/DeleteMiningExplosiveType'
                                                                                                                                                                                                        />
                                                                                                                                                                                                        :
                                                                                                                                                                                                        openPage === 'Received By' ?
                                                                                                                                                                                                            <MasterTable
                                                                                                                                                                                                                {...{ openPage }}
                                                                                                                                                                                                                showCol1='Description' showCol2='' idName='Id'
                                                                                                                                                                                                                getUrl='/ReceivedBy/GetData_ReceivedBy'
                                                                                                                                                                                                                addUrl='/ReceivedBy/InsertReceivedBy'
                                                                                                                                                                                                                singleDataUrl='/ReceivedBy/GetSingleData_ReceivedBy'
                                                                                                                                                                                                                upUrl='/ReceivedBy/UpdateReceivedBy'
                                                                                                                                                                                                                delUrl='/ReceivedBy/DeleteReceivedBy'
                                                                                                                                                                                                            />
                                                                                                                                                                                                            :
                                                                                                                                                                                                            openPage === 'DiscountOn' ?
                                                                                                                                                                                                                <MasterTable
                                                                                                                                                                                                                    {...{ openPage }}
                                                                                                                                                                                                                    showCol1='Description' showCol2='' idName='id'
                                                                                                                                                                                                                    getUrl='/DiscountOn/GetData_DiscountOn'
                                                                                                                                                                                                                    addUrl='/DiscountOn/InsertDiscountOn'
                                                                                                                                                                                                                    singleDataUrl='/DiscountOn/GetSingleData_DiscountOn'
                                                                                                                                                                                                                    upUrl='/DiscountOn/UpdateDiscountOn'
                                                                                                                                                                                                                    delUrl='/DiscountOn/DeleteDiscountOn'
                                                                                                                                                                                                                />
                                                                                                                                                                                                                :
                                                                                                                                                                                                                openPage === 'GSTSlabs' ?
                                                                                                                                                                                                                    <MasterTable
                                                                                                                                                                                                                        {...{ openPage }}
                                                                                                                                                                                                                        showCol1='Description' showCol2='' idName='id'
                                                                                                                                                                                                                        getUrl='/GSTSlabs/GetData_GSTSlabs'
                                                                                                                                                                                                                        addUrl='/GSTSlabs/InsertGSTSlabs'
                                                                                                                                                                                                                        singleDataUrl='/GSTSlabs/GetSingleData_GSTSlabs'
                                                                                                                                                                                                                        upUrl='/GSTSlabs/UpdateGSTSlabs'
                                                                                                                                                                                                                        delUrl='/GSTSlabs/DeleteGSTSlabs'
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                    :
                                                                                                                                                                                                                    openPage === 'Invoice Type' ?
                                                                                                                                                                                                                        <MasterTable
                                                                                                                                                                                                                            {...{ openPage }}
                                                                                                                                                                                                                            showCol1='Description' showCol2='' idName='ID'
                                                                                                                                                                                                                            getUrl='/InvoiceType/GetData_InvoiceType'
                                                                                                                                                                                                                            addUrl='/InvoiceType/InsertInvoiceType'
                                                                                                                                                                                                                            singleDataUrl='/InvoiceType/GetSingleData_InvoiceType'
                                                                                                                                                                                                                            upUrl='/InvoiceType/UpdateInvoiceType'
                                                                                                                                                                                                                            delUrl='/InvoiceType/DeleteInvoiceType'
                                                                                                                                                                                                                        />
                                                                                                                                                                                                                        :
                                                                                                                                                                                                                        // openPage === openPage ?
                                                                                                                                                                                                                        // <MasterTable
                                                                                                                                                                                                                        //     {...{ openPage }}
                                                                                                                                                                                                                        //     showCol1 = 'Description' showCol2='' idName='id'
                                                                                                                                                                                                                        //     getUrl={`/${openPage.split(' ').join('')}/GetData_${openPage.split(' ').join('')}`}
                                                                                                                                                                                                                        //     addUrl={`/${openPage.split(' ').join('')}/Insert${openPage.split(' ').join('')}`}
                                                                                                                                                                                                                        //     singleDataUrl={`/${openPage.split(' ').join('')}/GetSingleData_${openPage.split(' ').join('')}`}
                                                                                                                                                                                                                        //     upUrl={`/${openPage.split(' ').join('')}/Update${openPage.split(' ').join('')}`}
                                                                                                                                                                                                                        //     delUrl={`/${openPage.split(' ').join('')}/Delete${openPage.split(' ').join('')}`}
                                                                                                                                                                                                                        // />
                                                                                                                                                                                                                        // :
                                                                                                                                                                                                                        openPage === 'Plant Service Process' ?
                                                                                                                                                                                                                            <PlantServiceProcess {...{ openPage }} />
                                                                                                                                                                                                                            :
                                                                                                                                                                                                                            openPage === 'Inventory Material' ?
                                                                                                                                                                                                                                <InventoryMaterial  {...{ openPage }} />
                                                                                                                                                                                                                                :
                                                                                                                                                                                                                                openSubPage === 'Ledger' ?
                                                                                                                                                                                                                                    <SaleList />
                                                                                                                                                                                                                                    :
                                                                                                                                                                                                                                    openPage === 'Assign Group-Type' ?
                                                                                                                                                                                                                                        <AssignGroupType />
                                                                                                                                                                                                                                        :
                                                                                                                                                                                                                                        <Main />
            }
        </>
    )
}

export default MasterTableTabs