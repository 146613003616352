import React, { useEffect } from 'react'

const ChallanPending = () => {

  useEffect(() => {
    // alert('sdfsdf')
  }, []);

  return (
    <>
      <div>Challan Pending</div>
      <div>Challan Pending</div>
      <div>Challan Pending</div>
      <div>Challan Pending</div>
      <div>Challan Pending</div>
      <div>Challan Pending</div>
      <div>Challan Pending</div>

    </>
  )
}

export default ChallanPending