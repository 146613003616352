import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Home from './DashBoardTab/Home';
import { Decrypt_Id_Name } from '../../Include/Common/Utility';
import AttendanceList from './DashBoardTab/AttendanceList/AttendanceList';
import LeaveList from './DashBoardTab/LeaveList/LeaveList';
import EmpLeave from '../Hr/EmpLeave/EmpLeave';
import Testing from '../Testing';
import EmpTask from './DashBoardTab/EmpTask/EmpTask';

const DashBoard = () => {

    const useQuery = () => new URLSearchParams(useLocation().search);
    
    let openPage = useQuery().get('sub');
    let openSubPage = useQuery().get('sub');

    return (
        <>
            {/* <Testing /> */}
            {
                openSubPage === 'Home' && <Home />
            }
            {
                openSubPage === 'Attendance' && <AttendanceList />
            }
            {
                openSubPage === 'Leave' && <LeaveList />
            }
            {
                openSubPage === 'My Task' && <EmpTask />
            }
        </>
    )
}

export default DashBoard