import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { save_Master_Table, update_Master_Table } from '../../../redux/actions/AllAction';
import { SpaceNotAllow } from '../../Validation/Validation';
import { Get_Error_Status } from '../../../redux/actionTypes';
import { useLocation } from 'react-router-dom';

const MasterTable_Add_Up = (props) => {


    const { status, showCol1, showCol2, idName, upUrl, addUrl, pageStatus, getUrl, masterTableData } = props
    const dispatch = useDispatch();
    const masterTableModalStatus = useSelector((state) => state?.Data?.masterTableModalStatus);
    const singleMasterTableData = useSelector((state) => state?.Data?.singleMasterTableData);
    const errorStatus = useSelector((state) => state.Data.errorStatus)
    const editUpdateCount = useSelector((state) => state.Data.editUpdateCount)
    const userId = useSelector((state) => state.Data.userId);

    const [value, setValue] = useState({
        [showCol1]: '',
        [showCol2]: '',
        ID: '',
        Createdbyid: userId
    })

    const [errors, setErrors] = useState({
        'nameError': '', 'codeError': ''
    })

    useEffect(() => {
        if (pageStatus) reset()
    }, [pageStatus])

    useEffect(() => {
        if (singleMasterTableData) {
            setValue({
                ...value,
                [showCol1]: singleMasterTableData[showCol1],
                [showCol2]: singleMasterTableData[showCol2],
                ID: singleMasterTableData[idName],
                Updatedbyid: userId
            })
        } else {
            setValue({
                ...value,
                [showCol1]: '',
                [showCol2]: '',
                ID: '',
            })
        }
    }, [singleMasterTableData, editUpdateCount])

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            reset()
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    const handlChange = (e) => {
        if (e.target.name === 'LoadingAmt') {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setValue({
                ...value,
                [e.target.name]: checkNumber
            })
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    // Check validation on Field
    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value[showCol1])) {
            setErrors(prevValues => { return { ...prevValues, ['nameError']: SpaceNotAllow(value[showCol1]) } })
            dispatch({ type: Get_Error_Status, payload: true })
        }
        if (SpaceNotAllow(value[showCol2])) {
            setErrors(prevValues => { return { ...prevValues, ['codeError']: SpaceNotAllow(value[showCol2]) } })
        }
    }

    // Check All Field Format is True Then Submit 
    const { nameError, codeError } = errors

    useEffect(() => {
        if (value[showCol2]) {
            if (nameError === 'true' && codeError === 'true' && errorStatus) {
                if (status) dispatch(update_Master_Table(value, upUrl, pageStatus, getUrl, showCol1, showCol2, idName, masterTableData));
                else dispatch(save_Master_Table(value, addUrl, pageStatus, getUrl, showCol1, showCol2, idName, masterTableData));
            }
        } else {
            if (nameError === 'true' && errorStatus) {
                if (status) dispatch(update_Master_Table(value, upUrl, pageStatus, getUrl, showCol1, showCol2, idName, masterTableData))
                else dispatch(save_Master_Table(value, addUrl, pageStatus, getUrl, showCol1, showCol2, idName, masterTableData));
            }
        }
    }, [nameError, codeError, errorStatus])

    const reset = () => {
        setValue({
            [showCol1]: '',
            [showCol2]: '',
            ID: '',
        });
        setErrors({
            'nameError': '', 'codeError': ''
        })

    }
    const useQuery = () => new URLSearchParams(useLocation().search);
    let openPage = useQuery().get('page');

    return (
        <>
            {
                masterTableModalStatus &&
                <div class="modal fade" style={{ background: "rgba(0,0,0, 0.5)" }} id="MasterTableModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document" >
                        <div class="modal-content">
                            <div class="modal-body">
                                <div className="m-1 ">
                                    <fieldset style={{ border: '1px solid gray' }}>
                                        <legend >{openPage}</legend>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-row">
                                                    <div className="col-6 col-md-6 col-lg-6 ">
                                                        <div class="text-field">
                                                            <input
                                                                type="text"
                                                                name={showCol1}
                                                                value={value[showCol1]}
                                                                className=""
                                                                id='name'
                                                                required
                                                                onChange={handlChange}
                                                            />
                                                            <label >{showCol1}:</label>
                                                            {errors.nameError !== 'true' ? (
                                                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.nameError}</span>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    {
                                                        showCol2 ?
                                                            <div className="col-6 col-md-6 col-lg-6 ">
                                                                <div class="text-field">
                                                                    <input
                                                                        type="text"
                                                                        name={showCol2}
                                                                        value={value[showCol2]}
                                                                        id='Designation'
                                                                        required
                                                                        onChange={handlChange}
                                                                    />
                                                                    <label >{showCol2}:</label>
                                                                    {errors.codeError !== 'true' ? (
                                                                        <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.codeError}</span>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            : <></>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>

                                </div>
                            </div>
                            <div className="btn-box text-right  mr-1 mb-2">
                                {
                                    status ?
                                        <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button>
                                        :
                                        <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Save</button>
                                }
                                <button type="button" data-dismiss="modal" class="btn btn-sm btn-success mr-1" onClick={reset} >Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default MasterTable_Add_Up