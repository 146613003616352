import React, { useState } from 'react'
import Documents from './EmplyeeTab/Documents/Documents';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import BankDetails from './EmplyeeTab/BankDetails/BankDetails';
import Home from './EmplyeeTab/Home/Home';
import PersonalDetails from './EmplyeeTab/PersonalDetails/PersonalDetails';
import ContactAddress from './EmplyeeTab/ContactAddress/ContactAddress';
import FamilyDetails from './EmplyeeTab/FamilyDetails/FamilyDetails';
import Educational from './EmplyeeTab/Educational/Educational';
import PreviousEmployement from './EmplyeeTab/PreviousEmployement/PreviousEmployement';
import Status from './EmplyeeTab/Status/Status';

const EmplyeeTab = () => {

    const [showPage, setShowPage] = useState('home');
    const pageStatus = useSelector((state) => state.Data.Status)
    const useQuery = () => new URLSearchParams(useLocation().search);
    let openPage = useQuery().get('sub');

    return (
        <>
            <div className="col-12 col-sm-12">
                <div className="card Agency">
                    <div className="card-body">
                        <div className="row ">
                            <div className={`col-12 col-md-12`}>
                                <div className="row">
                                    <div className="col-12 pl-3 ">
                                        <nav className="navbar navbar-expand-sm navbar-light bg-light">
                                            <div className="container-fluid">
                                                <div className=" " id="navbarNav">
                                                    <ul className="navbar-nav">
                                                        <li className="nav-item">
                                                            <Link
                                                                to={'/employeetab?sub=Employee'}
                                                                style={{ backgroundColor: showPage === 'home' ? '#189AB4' : '' }}
                                                                onClick={() => { setShowPage('home') }}
                                                            >
                                                                <a className="nav-link active" aria-current="page" >Employee</a>
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link
                                                                to={'/employeetab?sub=personaldetails'}
                                                                style={{ backgroundColor: showPage === 'personaldetails' ? '#189AB4' : '' }}
                                                                onClick={() => { setShowPage('personaldetails') }}
                                                            >
                                                                <a className="nav-link active" aria-current="page" >Personal Details</a>
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link
                                                                to={'/employeetab?sub=contactaddress'}
                                                                style={{ backgroundColor: showPage === 'contactaddress' ? '#189AB4' : '' }}
                                                                onClick={() => { setShowPage('contactaddress') }}
                                                            >
                                                                <a className="nav-link active" aria-current="page" >Contact Address</a>
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link
                                                                to={'/employeetab?sub=familydetails'}
                                                                style={{ backgroundColor: showPage === 'familydetails' ? '#189AB4' : '' }}
                                                                onClick={() => { setShowPage('familydetails') }}
                                                            >
                                                                <a className="nav-link active" aria-current="page" >Family Details</a>
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link
                                                                to={'/employeetab?sub=educational'}
                                                                style={{ backgroundColor: showPage === 'educational' ? '#189AB4' : '' }}
                                                                onClick={() => { setShowPage('educational') }}
                                                            >
                                                                <a className="nav-link active" aria-current="page" >Educational</a>
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link
                                                                to={'/employeetab?sub=previousemployement'}
                                                                style={{ backgroundColor: showPage === 'previousemployement' ? '#189AB4' : '' }}
                                                                onClick={() => { setShowPage('previousemployement') }}
                                                            >
                                                                <a className="nav-link active" aria-current="page" >Previous Employement</a>
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link
                                                                to={'/employeetab?sub=bankdetails'}
                                                                style={{ backgroundColor: showPage === 'bankdetails' ? '#189AB4' : '' }}
                                                                onClick={() => { setShowPage('bankdetails') }}
                                                            >
                                                                <a className="nav-link active" aria-current="page" >Bank Details</a>
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link
                                                                to={'/employeetab?sub=documents'}
                                                                style={{ backgroundColor: showPage === 'documents' ? '#189AB4' : '' }}
                                                                onClick={() => { setShowPage('documents') }}
                                                            >
                                                                <a className="nav-link active" aria-current="page" >Documents</a>
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link
                                                                to={'/employeetab?sub=status'}
                                                                style={{ backgroundColor: showPage === 'status' ? '#189AB4' : '' }}
                                                                onClick={() => { setShowPage('status') }}
                                                            >
                                                                <a className="nav-link active" aria-current="page" >Status</a>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            {
                                showPage === 'home' ? <Home {...{ openPage }} />
                                    :
                                    showPage === 'personaldetails' ? <PersonalDetails {...{ openPage }} />
                                        :
                                        showPage === 'contactaddress' ? <ContactAddress {...{ openPage }} />
                                            :
                                            showPage === 'familydetails' ? <FamilyDetails {...{ openPage }} />
                                                :
                                                showPage === 'educational' ? <Educational {...{ openPage }} />
                                                    :
                                                    showPage === 'previousemployement' ? <PreviousEmployement {...{ openPage }} />
                                                        :
                                                        showPage === 'bankdetails' ? <BankDetails {...{ openPage }} />
                                                            :
                                                            // showPage === 'salary' ? <Salary />
                                                            //     :
                                                            showPage === 'documents' ? <Documents {...{ openPage }} />
                                                                :
                                                                showPage === 'status' ? <Status {...{ openPage }} />
                                                                    :
                                                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmplyeeTab