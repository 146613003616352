import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { get_Single_Company_Data } from '../../../redux/actions/AllAction';
import ChallanApprove from './SaleNewTab/ChallanApprove/ChallanApprove';
import ChallanDeleted from './SaleNewTab/ChallanDeleted/ChallanDeleted';
import ChallanPending from './SaleNewTab/ChallanPending/ChallanPending';
import ChallanSearch from './SaleNewTab/ChallanSearch/ChallanSearch';
import CreateChallan from './SaleNewTab/Challan/CreateChallan';
import TabsMap from '../../Include/Headers/TabsMap';

const SaleNew = () => {

    const dispatch = useDispatch();
    const useQuery = () => new URLSearchParams(useLocation().search);

    let openPage = useQuery().get('page');
    let openSubPage = useQuery().get('sub');

    const companySingelData = useSelector((state) => state.Data.companySingelData)
    const companyId = useSelector((state) => state.Data.companyId)

    useEffect(() => {
        if (companySingelData.length === 0) dispatch(get_Single_Company_Data(companyId))
    }, [])

    return (
        <>
            <nav className="navbar navbar-expand-sm navbar-light bg-light">
                <div className="container-fluid">
                    <div className=" " id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link
                                    to={'/Sale?page=ChallanApprove'}
                                    style={{ backgroundColor: openPage === 'ChallanApprove' ? '#189AB4' : '' }}
                                >
                                    <a className="nav-link active" aria-current="page" > Challan Approve</a>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to={'/Sale?page=ChallanDeleted'}
                                    style={{ backgroundColor: openPage === 'ChallanDeleted' ? '#189AB4' : '' }}
                                >
                                    <a className="nav-link " aria-current="page" > Challan Deleted</a>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to={'/Sale?page=ChallanPending'}
                                    style={{ backgroundColor: openPage === 'ChallanPending' ? '#189AB4' : '' }}
                                >
                                    <a className="nav-link " aria-current="page" > Challan Pending</a>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to={'/Sale?page=ChallanSearch'}
                                    style={{ backgroundColor: openPage === 'ChallanSearch' ? '#189AB4' : '' }}
                                >
                                    <a className="nav-link " aria-current="page" > Challan Search</a>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            {/* <ul className="ipsNavBar_secondary ipsHide" data-role="secondaryNavBar"> */}
            {
                openPage === 'ChallanApprove' && <ChallanApprove />
            }
            {
                openPage === 'ChallanDeleted' && <ChallanDeleted />
            }
            {
                openPage === 'ChallanPending' && <ChallanPending />
            }
            {
                openPage === 'ChallanSearch' && <ChallanSearch />
            }
            {
                openPage === 'CreateChallan' && <CreateChallan />
            }
            {/* </ul> */}

        </>

    )
}

export default SaleNew