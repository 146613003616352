import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Select from "react-select";
import Status_Add_Up from './Status_Add_Up'
import ConfirmModal from '../../../../../Include/Common/ConfirmModal'
import { delete_EmpStatus, get_Emp_Status_Data, get_Single_EmpStatus_Data } from '../../../../../../redux/actions/AllAction'
import { Edit_Update_Count, MasterTable_Modal_Status } from '../../../../../../redux/actionTypes'



const EmpStatus = () => {

    const dispatch = useDispatch()
    // const employeeData = changeArrayFormatDrop(useSelector((state) => state.Data.employeeData), 'EmpName', 'ID');
    const empStatusData = (useSelector((state) => state.Data.empStatusData))
    const [status, setStatus] = useState(false)
    const editUpdateCount = (useSelector((state) => state.Data.editUpdateCount))
    const employeeId = useSelector((state) => state.Data.employeeId)
    const userId = useSelector((state) => state.Data.userId)
    const [pageStatus, setPageStatus] = useState("1")
    const [IsActive, setIsActive] = useState()
    const [id, setId] = useState('')
    const [confirmType, setConfirmType] = useState('')
    const customStylesWithOutColor = {
        control: (base) => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    };
    // useEffect(() => {
    //     if (employeeData.length === 0) dispatch(get_Employee_Data())
    // }, []);

    useEffect(() => {
        if (employeeId) dispatch(get_Emp_Status_Data(employeeId, pageStatus))
    }, [pageStatus])


    const columns = [
        {
            name: 'Status',
            selector: (row) => row.CurrentStatus,
            sortable: true
        },
        {
            name: 'Reason',
            selector: (row) => row.Reason,
            sortable: true
        },
        {
            name: 'Status Date',
            selector: (row) => row.StatusDate,
            sortable: true
        },

        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 55, fontWeight: '700' }}>Action</p>,
            cell: row => <>
                <div style={{ position: 'absolute', top: 4, right: 40 }}>
                    {
                        pageStatus === "1" ?
                            <Link to='/employeetab' onClick={() => setEditStatus(row)}
                                className="btn btn-sm bg-green text-white px-1 py-0 mr-2 master-btn" data-toggle="modal" data-target="#EmpStatusModal"><i className="fa fa-edit"></i>
                            </Link> : ''
                    }

                    {
                        pageStatus === "1" ?
                            < Link to={`/employeetab`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive('0'); setConfirmType("InActive"); setId(row.ID) }}
                                className="btn btn-sm  text-white px-1 py-0 mr-1" style={{ background: "#ddd" }}>
                                <i class="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true"></i>
                            </Link>
                            :
                            <Link to={`/employeetab`} data-toggle="modal" data-target="#ConfirmModal" onClick={(e) => { setIsActive('1'); setConfirmType("Active"); setId(row.ID) }}
                                className="btn btn-sm  text-white px-1 py-0 mr-4" style={{ background: "#ddd" }}>
                                <i class="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true"></i>
                            </Link>
                    }

                </div>
            </>
        }
    ]
    const setEditStatus = (row) => {
        dispatch(get_Single_EmpStatus_Data(row.ID));
        setStatus(true)
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: MasterTable_Modal_Status, payload: true })
    }
    const newStatus = () => {
        dispatch(get_Single_EmpStatus_Data(''));
        setStatus(false)
        dispatch({ type: Edit_Update_Count, payload: editUpdateCount + 1 });
        dispatch({ type: MasterTable_Modal_Status, payload: true })
    }
    const UpdActiveDeactive = () => {
        dispatch(delete_EmpStatus(id, IsActive, userId, pageStatus, employeeId))
    }



    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                {/* <div className="col-12 col-md-12 col-lg-6 pt-2 px-3 dropdown__box">
                    <Select
                        name="EmployeeName"
                        styles={customStylesWithOutColor}
                        isClearable
                        onChange={changeDropdown}
                        value={employeeData?.filter((obj) => obj.value === EmpId)}
                        options={employeeData}
                        placeholder="Select.."
                    />
                    <label htmlFor="">Employee Name</label>
                </div> */}
                <div className="col-12 col-md-6 col-lg-12 ">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class={`nav-link ${pageStatus === '1' ? 'active' : ''}`} onClick={() => setPageStatus("1")} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true">Active</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class={`nav-link ${pageStatus === '0' ? 'active' : ''}`} onClick={() => setPageStatus("0")} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true">InActive</a>
                        </li>
                    </ul>
                </div>
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Employee Status</p>
                    {pageStatus === "1" ?
                        <div style={{ marginLeft: 'auto' }}>
                            <Link to={'/employeetab'} onClick={newStatus} className="btn btn-sm  text-white px-2 py-0" data-toggle="modal" data-target="#EmpStatusModal" >
                                <i className="fa fa-plus"></i>
                            </Link>
                        </div> : ''}

                </div>
            </div>

            <div className="col-12 pt-2 px-3">
                <DataTable
                    columns={columns}
                    data={empStatusData}
                    dense
                    paginationPerPage={'10'}
                    paginationRowsPerPageOptions={[5, 10, 15]}
                    highlightOnHover
                    noContextMenu
                    pagination
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    noDataComponent={"There are no data to display"}
                />
            </div>
            <Status_Add_Up {...{ status, setStatus, pageStatus }} />
            <ConfirmModal func={UpdActiveDeactive} confirmType={confirmType} />
        </>
    )
}

export default EmpStatus