import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import AddRequest from './AddRequest/AddRequest';
import POSection from './POSection/POSection';
import MainRequest from './AddRequest/MainRequest';
import ApproveRequest from './ApproveRequest/ApproveRequest';
import ApproveQuotes from './ApproveQuotes/ApproveQuotes'
import Quotes from './Quotes/Quotes';
import RejectRequest from './RejectRequest/RejectRequest';
import PurchaseInvoice from './PurchaseInvoice/PurchaseInvoice';
import { useDispatch, useSelector } from 'react-redux';
import { get_Single_Company_Data } from '../../../redux/actions/AllAction';

const Inventory = () => {

    const dispatch = useDispatch();

    const useQuery = () => new URLSearchParams(useLocation().search);

    let openPage = useQuery().get('page');

    const companySingelData = useSelector((state) => state.Data.companySingelData)
    const companyId = useSelector((state) => state.Data.companyId)

    useEffect(() => {
        if (companySingelData.length === 0) dispatch(get_Single_Company_Data(companyId))
    }, [])

    return (
        <>
            {/* <nav className="navbar navbar-expand-sm navbar-light bg-light">
                <div className="container-fluid">
                    <div className=" " id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link
                                    to={'/Inventory?page=Add Request'}
                                    style={{ backgroundColor: openPage === 'Add Request' ? '#189AB4' : '' }}
                                >
                                    <a className="nav-link active" aria-current="page" >Add Request</a>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to={'/Inventory?page=Approve Request'}
                                    style={{ backgroundColor: openPage === 'Approve Request' ? '#189AB4' : '' }}
                                >
                                    <a className="nav-link active" aria-current="page" >Approve Request</a>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to={'/Inventory?page=Reject Request'}
                                    style={{ backgroundColor: openPage === 'Reject Request' ? '#189AB4' : '' }}
                                >
                                    <a className="nav-link active" aria-current="page" >Reject Request</a>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to={'/Inventory?page=Approve Quotes'}
                                    style={{ backgroundColor: openPage === 'Approve Quotes' ? '#189AB4' : '' }}
                                >
                                    <a className="nav-link active" aria-current="page" >Approve Quotes</a>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to={'/Inventory?page=Enter Quotes'}
                                    style={{ backgroundColor: openPage === 'Enter Quotes' ? '#189AB4' : '' }}
                                >
                                    <a className="nav-link active" aria-current="page" >Enter Quotes</a>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to={'/Inventory?page=PO Section'}
                                    style={{ backgroundColor: openPage === 'PO Section' ? '#189AB4' : '' }}
                                >
                                    <a className="nav-link active" aria-current="page" >PO Section</a>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to={'/Inventory?page=Purchase Invoice'}
                                    style={{ backgroundColor: openPage === 'Purchase Invoice' ? '#189AB4' : '' }}
                                >
                                    <a className="nav-link active" aria-current="page" >Purchase Invoice</a>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav> */}
            {
                openPage === 'Add Request' && <MainRequest />
            }
            {
                openPage === 'Approve Request' && <ApproveRequest />
            }
            {
                openPage === 'Reject Request' && <RejectRequest />
            }
            {
                openPage === 'Approve Quotes' && <ApproveQuotes />
            }
            {
                openPage === 'Enter Quotes' && <Quotes />
            }
            {
                openPage === 'PO Section' && <POSection />
            }
            {
                openPage === 'Purchase Invoice' && <PurchaseInvoice />
            }
            {/* {
                openSubPage === 'PO Section' && <POSection />
            }
            {
                openSubPage === 'Purchase Invoice' && <PurchaseInvoice />
            } */}
        </>
    )
}

export default Inventory