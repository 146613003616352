import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
// import { get_City, get_State, update_Employee_Address } from '../../../../../../../redux/actions/AllAction';
// import { Get_Error_Status } from '../../../../../../../redux/actionTypes';
// import { toastifyError } from '../../../../../../Include/Common/AlertMsg';
// import { RequiredFieldSelectBox, SpaceNotAllow, SpaceNotAllowWithoutReq } from '../../../../../../Validation/Validation';
// import { changeArrayFormatDrop } from '../../../../../../Include/Common/ArrayFormat';
import { get_City, get_State, update_Employee_Address } from '../../../../../../redux/actions/AllAction';
import { Get_Error_Status } from '../../../../../../redux/actionTypes';
import { RequiredFieldSelectBox, SpaceNotAllow, SpaceNotAllowWithoutReq } from '../../../../../Validation/Validation';
import { changeArrayFormatDrop } from '../../../../../Include/Common/ArrayFormat';



const ContactAddress = ({ openPage }) => {

    const dispatch = useDispatch()
    const errorStatus = useSelector((state) => state.Data.errorStatus)
    const pageStatus = useSelector((state) => state.Data.Status)
    const employeeId = useSelector((state) => state.Data.employeeId)
    const State = changeArrayFormatDrop(useSelector((state) => state.Data.State),'StateName','id')
    const permanentCityData = changeArrayFormatDrop( useSelector((state) => state.Data.permanentCityData),'DistrictName','ID')
    const localCityData = changeArrayFormatDrop(useSelector((state) => state.Data.localCityData),'DistrictName','ID')
    const employeeSingleData = useSelector((state) => state.Data.employeeSingleData[0])
    const userId = useSelector((state) => state.Data.userId)

    const [value, setValue] = useState({
        PermanentAddress: '', PermanentState: '', PermanentStateID: '', PermanentDistrict: '', PermanentDistrictID: '', LocalAddress: '', LocalState: '', LocalStateID: '', LocalDistrict: '', LocalDistrictID: '', ID: '', Updatedbyid: userId
    })

    const [errors, setErrors] = useState({
        PermanentAddressErrors: '', LocalAddressErrors: '', PStateError :'', LStateError: ''
    })

    useEffect(() => {
        if (State?.length === 0) dispatch(get_State())
    }, [])

    useEffect(() => {
        if (employeeSingleData) {
            setValue(pre => { return { ...pre, PermanentAddress: employeeSingleData.PermanentAddress, PermanentState: employeeSingleData.PermanentState, PermanentStateID: employeeSingleData.PermanentStateID, PermanentDistrict: employeeSingleData.PermanentDistrict, PermanentDistrictID: employeeSingleData.PermanentDistrictID, LocalAddress: employeeSingleData.LocalAddress, LocalState: employeeSingleData.LocalState, LocalStateID: employeeSingleData.LocalStateID, LocalDistrict: employeeSingleData.LocalDistrict, LocalDistrictID: employeeSingleData.LocalDistrictID, ID: employeeSingleData.ID } });
            dispatch(get_City(employeeSingleData.LocalStateID, 'Local')); dispatch(get_City(employeeSingleData.PermanentStateID, 'Permanent'))
        }
    }, [employeeSingleData])

    const handleChange = (e) => {
        setValue(pre => { return { ...pre, [e.target.name]: e.target.value } })
    }

    const handelcheckbox = (e) => {
        const { PermanentAddress, PermanentState, PermanentStateID, PermanentDistrict, PermanentDistrictID } = value
        const { checked } = e.target
        if (checked) {
            setValue(pre => { return { ...pre, ['LocalAddress']: PermanentAddress, ['LocalState']: PermanentState, ['LocalStateID']: PermanentStateID, ['LocalDistrict']: PermanentDistrict, ['LocalDistrictID']: PermanentDistrictID, } });
            dispatch(get_City(PermanentStateID, 'Local'))
        } else {
            setValue(pre => { return { ...pre, ['LocalAddress']: '', ['LocalState']: '', ['LocalStateID']: '', ['LocalDistrict']: '', ['LocalDistrictID']: '', } })
        }
    }

    const handleDropdown = (e, name) => {
        if (name === 'PermanentStateID') {
            setValue(pre => { return { ...pre, [name]: e?.value, ['PermanentState']: e?.label } })
            dispatch(get_City(e?.value, 'Permanent'))
        } else if (name === 'LocalStateID') {
            setValue(pre => { return { ...pre, [name]: e?.value, ['LocalState']: e?.label } })
            dispatch(get_City(e?.value, 'Local'))
        } else if (name === 'PermanentDistrictID') {
            setValue(pre => { return { ...pre, [name]: e?.value, ['PermanentDistrict']: e?.label } })
        } else if (name === 'LocalDistrictID') {
            setValue(pre => { return { ...pre, [name]: e?.value, ['LocalDistrict']: e?.label } })
        }
    }

    // Check validation on Field
    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (SpaceNotAllow(value.PermanentAddress)) {
            setErrors(prevValues => { return { ...prevValues, ['PermanentAddressErrors']: SpaceNotAllow(value.PermanentAddress) } })
            dispatch({ type: Get_Error_Status, payload: true })
        }
        if (SpaceNotAllow(value.LocalAddress)) {
            setErrors(prevValues => { return { ...prevValues, ['LocalAddressErrors']: SpaceNotAllow(value.LocalAddress) } })
        } 
        if (RequiredFieldSelectBox(value.PermanentState)) {
            setErrors(prevValues => { return { ...prevValues, ['PStateError']: RequiredFieldSelectBox(value.PermanentState) } })
        }
        if (RequiredFieldSelectBox(value.LocalState)) {
            setErrors(prevValues => { return { ...prevValues, ['LStateError']: RequiredFieldSelectBox(value.LocalState) } })
        }
              
    }

    // Check All Field Format is True Then Submit 
    const { PermanentAddressErrors, LocalAddressErrors , PStateError , LStateError, } = errors
          
    useEffect(() => {
        if (PermanentAddressErrors === 'true' && LocalAddressErrors === 'true' && PStateError === 'true' && LStateError === 'true') {
            if (pageStatus) dispatch(update_Employee_Address(value, employeeId))
        }
    }, [PermanentAddressErrors, LocalAddressErrors, PStateError , LStateError])

    // custuom style withoutColor
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 31,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
            fontWeight: 500,
        }),
    }
    const colourStyles = {
        control: (styles) => ({
          ...styles, backgroundColor: "#fce9bf",
          height: 20,
          minHeight: 30,
          fontSize: 14,
          margintop: 2,
          boxShadow: 0,
        }),
      }

    return (
        <>
            <div className="col-12 col-md-12 col-lg-12 pt-2 px-3">
                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">Contact Address</p>
                </div>
                <div className="form-row ">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                        <div className="col-12 col-md-7 col-lg-6 mt-1">
                            <div class="form-check ">
                                <input class="form-check-input" name='Common' type="checkbox" onChange={handelcheckbox} id="flexCheckDefault6" />
                                <label class="form-check-label" for="flexCheckDefault6">
                                    Same As Permanent
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-row ">
                    <div className="col-6 col-md-6">
                        <div className="form-row  px-1">
                            <div className="col-3 col-md-3 col-lg-3 mt-2 pt-2">
                                <label className='label-field'>Permanent Address:</label>
                            </div>

                            <div className="col-9  col-md-9 col-lg-7" >
                                <div className="dropdown__box">
                                    <textarea name='PermanentAddress' id="PermanentAddress" value={value.PermanentAddress} cols="30" rows='1' className="form-control pt-2  requiredColor" onChange={handleChange} ></textarea>
                                </div>
                                {errors.PermanentAddressErrors !== 'true' ? (
                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.PermanentAddressErrors}</span>
                                ) : null}
                            </div>
                            <div className="col-3 col-md-3 col-lg-3 mt-2 pt-2">
                                <label className='label-field'>state:</label>
                            </div>
                            <div className="col-9 col-md-9 col-lg-6 ">
                                <div className="dropdown__box">
                                    <Select
                                        name='PermanentStateID'
                                        styles={colourStyles}
                                        options={State}
                                        value={State?.filter((obj) => obj?.value === value?.PermanentStateID)}
                                        isClearable
                                        onChange={(e) => handleDropdown(e, 'PermanentStateID')}
                                        placeholder="Select.."
                                    />
                                </div>
                                {errors.PStateError !== 'true' ? (
                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.PStateError}</span>
                                ) : null}
                            </div>
                            <div className=" col-lg-3 ">
                            </div>
                            <div className="col-3 col-md-3 col-lg-3 mt-2 pt-2">
                                <label className='label-field'>district:</label>
                            </div>
                            <div className="col-9 col-md-9 col-lg-6 ">
                                <div className="dropdown__box">
                                    <Select
                                        name='PermanentDistrictID'
                                        styles={customStylesWithOutColor}
                                        options={permanentCityData}
                                        value={permanentCityData?.filter((obj) => obj?.value === value?.PermanentDistrictID)}
                                        isClearable
                                        onChange={(e) => handleDropdown(e, 'PermanentDistrictID')}
                                        placeholder="Select.."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-row px-1">
                            <div className="col-3 col-md-3 col-lg-3 mt-2 pt-2">

                                <label className='label-field'>Local Address:</label>
                            </div>

                            <div className="col-9  col-md-9 col-lg-7" >
                                <div className="dropdown__box">
                                    <textarea name='LocalAddress' id="LocalAddress" value={value.LocalAddress} cols="30" rows='1' className="form-control pt-2 requiredColor" onChange={handleChange} ></textarea>
                                </div>
                                {errors.LocalAddressErrors !== 'true' ? (
                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.LocalAddressErrors}</span>
                                ) : null}
                            </div>
                            <div className="col-3 col-md-3 col-lg-3 mt-2 pt-2">
                                <label className='label-field'>state:</label>
                            </div>
                            <div className="col-9 col-md-9 col-lg-6 ">
                                <div className="dropdown__box">
                                    <Select
                                        name='LocalStateID'
                                        styles={colourStyles}
                                        isClearable
                                        options={State}
                                        value={State?.filter((obj) => obj?.value === value?.LocalStateID)}
                                        onChange={(e) => handleDropdown(e, 'LocalStateID')}
                                        placeholder="Select.."
                                    />
                                </div>
                                {errors.LStateError !== 'true' ? (
                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.LStateError}</span>
                                ) : null}
                            </div>
                            <div className=" col-lg-3">
                            </div>
                            <div className="col-3 col-md-3 col-lg-3 mt-2 pt-2">
                                <label className='label-field'>district:</label>
                            </div>
                            <div className="col-9 col-md-9 col-lg-6 ">
                                <div className="dropdown__box">
                                    <Select
                                        name='LocalDistrictID'
                                        styles={customStylesWithOutColor}
                                        options={localCityData}
                                        value={localCityData?.filter((obj) => obj?.value === value?.LocalDistrictID)}
                                        onChange={(e) => handleDropdown(e, 'LocalDistrictID')}
                                        isClearable
                                        placeholder="Select.."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="btn-box text-end col-12 col-md-12 col-lg-12 mt-2 ">
                <button type="button" class="btn btn-sm btn-success mr-1" onClick={check_Validation_Error}>Update</button>
                <Link to={openPage === 'Attendance' ? '/HR?sub=Attendance%20Report' : '/AdminSetting?sub=Operator'}>
                    <button type="button" class="btn btn-sm btn-success mr-1">Close</button>
                </Link>
            </div>
        </>
    )
}

export default ContactAddress